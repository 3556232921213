import Datastore from '~/db/Datastore'

export default class AnalyticsRepository {
  
  constructor() {
    this.db = new Datastore()
  }
  
  getLastActiveDateForUser(userId) {
    const path = `analytics/users/${userId}/lastOpened`
    return this.db.getValueAtPath(path, false).then((lastOpened) => {
      return lastOpened || null
    })
  }
}
