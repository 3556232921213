import { Overlay } from '@karla/karla-react-components'
import * as React from 'react'
import { connect } from 'react-redux'
import { spinnerAction } from '../../redux/actions/actionTypes/spinnerActions'

const mapStateToProperties = (state) => {
  return {
    ...state,
    loading: state.spinner.loading
  }
}
const mapDispatchToProperties = (dispatch) => {
  return {
    forceHideSpinner: () => {
      dispatch(spinnerAction(false, true))
    }
  }
}

type Props = {
  children: React.ReactNode
  loading: boolean
  forceHideSpinner: () => void
  countDown?: number
}
type State = {
  tasks: number
}

export class Spinner extends React.Component<Props, State> {
  componentDidUpdate({ loading: previousLoading }) {
    const { loading, forceHideSpinner } = this.props
    if (loading === true && previousLoading === false) {
      // In case an exception happens and the end action does not happen
      setTimeout(() => {
        forceHideSpinner()
      }, this.props.countDown || 10000)
    }
  }

  render() {
    const { loading, children } = this.props
    if (loading) {
      return (
        <div>
          {children}
          <Overlay show={loading} placement="right" container={this}>
            <TemplateComponent> </TemplateComponent>
          </Overlay>
        </div>
      )
    }
    return <div>{children}</div>
  }
}

export function TemplateComponent({
  children,
  className,
  style
}: {
  children?: React.ReactNode
  className?: string
  style?: { [prop: string]: unknown }
}) {
  return (
    <div className="spinner-parent">
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube" />
        <div className="sk-cube2 sk-cube" />
        <div className="sk-cube4 sk-cube" />
        <div className="sk-cube3 sk-cube" />
      </div>
    </div>
  )
}

export default connect(mapStateToProperties, mapDispatchToProperties)(Spinner)
