import * as React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { getSpecialistClientIds } from '../../db/Telehealth'
import { Role } from '../../db/Roles'
import { logout } from '../../redux/actions/login'

type Props = {
  component: any
  params: {
    clientId: string
  }
  userId: string
  tenantId: string
  roleCheck: Role
  role: string
  logout: () => void
}

type State = {
  isValidRoute?: boolean
  userId?: string
  tenantId?: string
  role?: string
}

function mapStateToProperties(state): State {
  return {
    userId: state.auth.uid,
    tenantId: state.auth.selectedRole.tenantId,
    role: state.auth.selectedRole.role
  }
}

const mapDispatchToProperties = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout())
    }
  }
}

// @ts-ignore
@connect(mapStateToProperties, mapDispatchToProperties)
class ProtectedRoute extends React.Component<Props, State> {
  constructor(properties: Props) {
    super(properties)
    this.state = {
      isValidRoute: false
    }
  }

  componentDidMount() {
    this.recheckRoute(this.props)
  }

  componentWillReceiveProps(properties) {
    this.setState({
      isValidRoute: false
    })
    this.recheckRoute(properties)
  }

  async recheckRoute(properties: Props) {
    const isClientsSpecialist =
      properties.role === Role.specialist
        ? await isUsersSpecialist(
            properties.userId,
            properties.params.clientId,
            properties.tenantId
          )
        : true
    const existingRole = this.props.roleCheck || Role.specialist
    if (
      (Role[properties.role] === existingRole && isClientsSpecialist) ||
      properties.role === Role.demo
    ) {
      this.setState({
        isValidRoute: true
      })
    } else {
      console.warn('No permissions cached, Using default route')
      let clientIds
      switch (properties.role) {
        case Role.admin:
          browserHistory.push('/organizations')
          break
        case Role.demo:
          browserHistory.push(`users/${properties.userId}`)
          break
        case Role.specialist:
          clientIds = await getSpecialistClientIds(
            properties.tenantId,
            properties.userId
          )
          if (clientIds.length > 0) {
            const id = clientIds[0]
            browserHistory.push(`/clients/${id}`)
          } else {
            browserHistory.push('/whendo')
          }
          break
        case Role.consumer:
        default:
          this.props.logout()
          browserHistory.push('/authenticate')
          break
      }
    }
  }

  render() {
    const { isValidRoute } = this.state
    if (!isValidRoute) {
      return <span />
    }
    return <this.props.component {...this.props} />
  }
}

async function isUsersSpecialist(
  specialistId,
  clientId,
  tenantId
): Promise<boolean> {
  try {
    const clients = await getSpecialistClientIds(tenantId, specialistId)
    return clients.includes(clientId)
  } catch (error) {
    console.error('Error checking is specialist', error)
    return false
  }
}

const ProtectedRouteWrapper = (Component, roleCheck) => {
  const ProtectedRouteW = (properties) => {
    if (!roleCheck) {
      throw new Error('No role was provided')
    }
    return (
      <ProtectedRoute
        component={Component}
        roleCheck={roleCheck}
        {...properties}
      />
    )
  }
  return ProtectedRouteW
}
export default ProtectedRouteWrapper
