import Datastore from './Datastore'
import UserRepository from './UserRepository'
import { URN, ROLES } from '@karla/karla-core'
import { getUser } from './Users';

const specialistsClientsPath = 'specialistClients'
const clientSpecialistPath = 'clientSpecialist'
const specialistSessionsPath = 'specialistSessions'

export default class SpecialistRepository extends Datastore {

  // Client / Specialist assignment

  getClientsForSpecialist(specialistId) {
    const clientsRef = this.getClientsRef(specialistId)
    return this.getValueAtRef(clientsRef).then(clients => {
      if (!clients || clients.length === 0) {
        return []
      }
      const clientIds = Object.keys(clients)
      const getUsers = clientIds.map(clientId => {
        const userPath = `users/${clientId}`
        return this.getValueAtPath(userPath)
      })
      return Promise.all(getUsers)
    })
  }

  bindToClientsForSpecialist(specialistId, options) {
    const clientsPath = specialistsClientsPath + '/' + specialistId
    return this.bindToPath(clientsPath, clients => {
      if (!clients || Object.keys(clients).length === 0) {
        return []
      }
      const clientIds = Object.keys(clients)
      const getUsers = clientIds.map(clientId => {
        const userPath = `users/${clientId}`
        return this.getValueAtPath(userPath).then(user => {
          user = user || {}
          user.id = clientId
          return user
        })
      })
      Promise.all(getUsers).then(users => {
        this.handleValueUpdate(users, options)
      })
    })
  }


  getSpecialistForUser(userId) {
    return this.getSpecialistIdForUser(userId).then(specialistId => {
      if (!specialistId) {
        return null
      }
      return getUser(specialistId)
    })
  }


  assignUserToSpecialist(userId, specialistId) {
    if (!specialistId) {
      specialistId = null
    }
    return this.getSpecialistIdForUser(userId).then(existingSpecialistId => {
      // no changes to make
      if (existingSpecialistId == specialistId) {
        return
      }
      let updates = []
      if (existingSpecialistId) {
        const removeOldSpecialist = this._updateSpecialistAssignment(userId, existingSpecialistId, false)
        updates.push(removeOldSpecialist)
      }
      if (specialistId) {
        const addNewSpecialist = this._updateSpecialistAssignment(userId, specialistId, true)
        updates.push(addNewSpecialist)
      }
      return Promise.all(updates)
    })
  }

  getSpecialistIdForUser(userId) {
    const ref = this.getSpecialistRef(userId)
    return this.getValueAtRef(ref).then(specialistIds => {
      if (!specialistIds) {
        return null
      }
      const ids = Object.keys(specialistIds)
      return ids.length > 0 ? ids[0] : null
    })
  }

  // Scheduling

  scheduleSessionForClient(specialistId, clientId, startDate, endDate) {
    const now = new Date()
    if (startDate < (now.getTime() / 1000)) {
      return Promise.reject(`Sessions must be scheduled in the future. Current time is ${now.toLocaleString()}, attempted to schedule at ${new Date(startDate * 1000).toLocaleString()}`)
    }
    const session = {
      startDate,
      endDate,
      specialist: specialistId,
    }
    const sessionsRef = this.getSessionsRef(clientId)
    return this.pushChildAtRef(sessionsRef, session)
  }

  deleteSessionForClient(clientId, sessionId) {
    const sessionRef = this.getSessionsRef(clientId).child(sessionId)
    return this.deleteRef(sessionRef)
  }

  bindToClientSessions(clientId, options, startAt) {
    if (!startAt) {
      startAt = Math.floor(Date.now() / 1000);
    }
    const sessionsRef = this.getSessionsRef(clientId)
      .orderByChild('startDate')
      .startAt(startAt)
    return this.bindStateToRef(sessionsRef, options)
  }

  _updateSpecialistAssignment(userId, specialistId, isAssigned) {

    const assignValue = isAssigned ? true : null
    const clientValue = {
      [userId]: assignValue
    }
    const specialistValue = {
      [specialistId]: assignValue
    }

    const clientRef = this.getClientsRef(specialistId)
    const specialistRef = this.getSpecialistRef(userId)

    const updateClient = this.updateRef(clientRef, clientValue)
    const updateSpecialist = this.updateRef(specialistRef, specialistValue)

    return Promise.all([updateClient, updateSpecialist])
  }

  getClientsRef(userId) {
    return this.getRef(specialistsClientsPath).child(userId)
  }

  getSpecialistRef(userId) {
    return this.getRef(clientSpecialistPath).child(userId)
  }

  getSessionsRef(clientId) {
    return this.getRef(specialistSessionsPath).child(clientId)
  }

  getAvailableSpecialists() {
    const userRepo = new UserRepository()
    return userRepo.getUserWithRole(ROLES.SPECIALIST)
  }

  logNote(note, specialistId, clientId) {
    const notePath = `telehealth/${specialistId}/clients/${clientId}`
    note.date = new Date().getTime() / 1000;
    return this.getRef(notePath).child('notes').push(note, (error) => { console.log("saved!"); console.log(error); })
  }

  bindToNotes(specialistId, clientId, options) {
    const notesPath = this.getNotesPath(specialistId, clientId)
    return this.bindStateToPath(notesPath, options)
  }

  getNotesPath(specialistId, clientId) {
    return `telehealth/${specialistId}/clients/${clientId}/notes`
  }
}
