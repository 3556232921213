import { incrementChar } from '~/common/lib/string_extensions'
import { URN } from '@karla/karla-core'
import Datastore from './Datastore'

const userFields = [
  URN.CONSUMER.FIRSTNAME,
  URN.CONSUMER.LASTNAME
]

const orgFields = [
  "name"
]

export default class SearchClient {

  constructor() {
    this.datastore = new Datastore()
  }

  searchUsers(searchTerm, options) {
    return this.bindSearchPath('users', userFields, searchTerm, options)
  }

  searchOrgsAndUsers(searchTerm) {
    const userSearch = this.searchPath('users', userFields, searchTerm)
    const orgsSearch = this.searchPath('orgs', orgFields, searchTerm)
    return Promise.all([userSearch, orgsSearch]).then(results => {
      return {
        users: results[0],
        orgs: results[1]
      }
    })
  }

  updateUserSearchTerms(uid) {
    return this.updateSearchTerms(`users/${uid}`, userFields)
  }

  updateOrgSearchTerms(orgId) {
    return this.updateSearchTerms(`orgs/${orgId}`, orgFields)
  }

  updateSearchTerms(path, fields) {
    const ref = this.datastore.getRef(path)
    return this.datastore.getValueAtRef(ref).then(searchable => {
      const searchTerms = fields.reduce((previous, field) => {
        const value = searchable[field]
        previous[field] = value ? value.toLowerCase() : null
        return previous
      }, {})
      const termsRef = ref.child('search')
      return this.datastore.replaceRef(termsRef, searchTerms)
    })
  }

  bindSearchPath(path, fields, searchTerm, options) {
    this.searchPath(path, fields, searchTerm).then(results => {
      this.datastore.handleValueUpdate(results, options)
    })
  }

  searchPath(path, fields, searchTerm) {
    const sanitizedTerm = this.sanitizeSearch(searchTerm)
    const ref = this.datastore.getRef(path)
    const searches = fields.map(field => {
      return this.searchFromRef(ref, field, sanitizedTerm)
    })
    return Promise.all(searches).then(searchResults => {
      const merged = searchResults.reduce((previous, result) => {
        return Object.assign(previous, result)
      }, {})
      return merged
    }, err => {
      console.jsonLog('search err ', err)
    })
  }

  sanitizeSearch(term) {
    return term.toLowerCase()
  }

  searchFromRef(ref, field, term) {
    if (!term || term.length == 0) {
      return Promise.resolve({})
    }
    const lastIndex = term.length - 1
    const lastChar = term.charAt(lastIndex)
    const incrementedChar = incrementChar(lastChar)
    const boundingTerm = term.replaceCharAtIndex(incrementedChar, lastIndex)
    const searchField = `search/${field}`
    console.log(`search: ${term}, bounding: ${boundingTerm}, field: ${searchField}`)
    return new Promise((resolve, reject) => {
      ref.orderByChild(searchField)
       .startAt(term)
       .endAt(boundingTerm)
       .once('value', snap => {
         const searchResults = snap.val()
         if (searchResults) {
           const objectIds = Object.keys(searchResults)
           objectIds.forEach(id => {
             let object = searchResults[id]
             object.id = id
           })
           resolve(searchResults)
         } else {
           resolve({})
         }
      }, reject)
    })
  }
}
