import { CHANGE_DATE_RANGE } from './actionTypes/dashboardActions'
import * as DateUtils from '~/common/lib/date_utils'
import moment from 'moment'

export function viewLastWeek() {
  const today = new Date();
  const startOfLastWeek = moment().subtract(1, 'weeks').toDate()
  const dateString = moment(startOfLastWeek).format("MMMM DD, YYYY")
  return actionForQuery({
    unit: "week",
    startDate: moment().subtract(1, 'weeks').toDate(),
    endDate: today,
    description: `Since ${dateString}`
  })
}

export function viewLastMonth() {
  const today = new Date();
  const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() -1, 1)
  const monthName = moment(startOfLastMonth).format("MMMM")
  return actionForQuery({
    unit: "month",
    startDate: startOfLastMonth,
    endDate: today,
    description: `Since ${monthName} ${startOfLastMonth.getFullYear()}`
  })
}

export function viewLastQuarter() {
  const today = new Date();
  const startOfLastQuarter = DateUtils.getStartOfLastQuarter(today)
  const startOfThisQuarter = DateUtils.getStartOfQuarter(today)
  const lastQuarter = DateUtils.getQuarterForDate(startOfLastQuarter)
  return actionForQuery({
    unit: "quarter",
    startDate: startOfLastQuarter,
    endDate: startOfThisQuarter,
    description: `Q${lastQuarter + 1} ${startOfLastQuarter.getFullYear()}`
  })
}

export function viewLastYear() {
  const today = new Date();
  const startOfLastYear = moment().subtract(1, 'year').startOf('year').toDate()
  const startOfThisYear = DateUtils.getStartOfYear(today)
  return actionForQuery({
    unit: "year",
    startDate: startOfLastYear,
    endDate: startOfThisYear,
    description: `${startOfLastYear.getFullYear()}`
  })
}

function actionForQuery(query) {
  return {
    type: CHANGE_DATE_RANGE,
    query
  }
}
