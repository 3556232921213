import { CONFIG } from '../../config'
import { ROLES } from '@karla/karla-core'
import firebase from 'firebase/app'

import UserRepository from '../../db/UserRepository'
import { sortBy } from '../../util/helpers'

const app = firebase.app()

const AWS = window.AWS

export class AuthManager {
  constructor() {
    this.userRepo = new UserRepository()
  }

  loginWithProviders(logins, tenantId) {
    console.log('Logging in with auth providers')
    return this.getCognitoIdentity(logins)
      .then(() => {
        return this.loginToFirebase(tenantId)
      })
      .then((credentials) => {
        console.log('Getting auth state')
        return this.loadAuthState(credentials).then((authState) => {
          console.log('logged in as ', JSON.stringify(authState, null, 2))
          return authState
        })
      })
      .catch((err) => {
        console.error(
          'Error logging in loginWithProviders ',
          JSON.stringify({ ...err, logins, tenantId })
        )
        throw err
      })
  }

  refreshSession() {
    console.log('Refreshing session')
    const cachedAuthState = this.getCachedAuthState()
    console.log('Cached auth state', cachedAuthState)
    if (!cachedAuthState) {
      this.logout()
      return Promise.reject(new Error('No cached auth state'))
    }
    return this.validateAuthState(cachedAuthState)
      .then((authState) => {
        if (!this.isAuthorized(authState)) {
          throw new Error('Insufficient permissions')
        }
        return this.refreshAWSSession().then(() => {
          this.cacheAuthState(authState)
          return authState
        })
      })
      .catch((err) => {
        this.logout()
        throw err
      })
  }

  reauthenticateWithTenant(tenantRole) {
    return this.refreshAWSSession()
      .then(() => {
        const logins = this.getCachedProviderLogins()
        return this.getCognitoIdentity(logins)
      })
      .then(() => {
        return this.loginToFirebase(tenantRole.tenantId)
      })
      .then((authCredentials) => {
        const authState = {
          uid: authCredentials.uid,
          selectedRole: tenantRole
        }
        return this.validateAuthState(authState)
      })
      .then((authState) => {
        console.log('Updated auth state', authState)
        this.cacheAuthState(authState)
        return authState
      })
      .catch((err) => {
        this.logout()
        throw err
      })
  }

  updateCachedRole(role) {
    const authState = this.getCachedAuthState()
    if (!authState) {
      throw new Error('No cached auth state')
    }
    authState.selectedRole.role = role
    this.cacheAuthState(authState)
  }

  loadAuthState(authCredentials) {
    return this.getAllRoles(authCredentials.uid).then((availableRoles) => {
      const selectedTenantRoles = parseIndividualRolesDescending(
        authCredentials.tenantRoles
      )
      return {
        uid: authCredentials.uid,
        selectedRole: selectedTenantRoles[0],
        availableRoles
      }
    })
  }

  validateAuthState(authState) {
    return this.getAllRoles(authState.uid).then((availableRoles) => {
      if (!isCachedRoleValid(authState.selectedRole, availableRoles)) {
        console.log('Auth state invalid')
        authState.selectedRole = getHighestRankingRole(availableRoles)
      }
      authState.availableRoles = availableRoles
      return authState
    })
  }

  getAllRoles(userId) {
    return app
      .firestore()
      .collection(`rolesByUser/${userId}/roles`)
      .get()
      .then((rolesCollection) => {
        if (rolesCollection.empty) {
          return []
        }
        return rolesCollection.docs.map((doc) => {
          return doc.data()
        })
      })
      .then((tenantRoles) => {
        return app
          .firestore()
          .collection(`/tenants`)
          .get()
          .then((tenantsRefs) => {
            const tenants = tenantsRefs.docs.reduce((tenantsA, tenant) => {
              const t = tenant.data()
              tenantsA[tenant.id] = t
              return tenantsA
            }, {})
            const tenantIds = Object.keys(tenants)
            return tenantRoles
              .filter(
                ({ tenantId }) =>
                  tenantIds.includes(tenantId) &&
                  tenants[tenantId] &&
                  !tenants[tenantId].archived
              )
              .sort(sortBy('tenantName'))
          })
          .catch(() => {
            return tenantRoles
          })
      })
      .then(parseAllTenantRolesDescending)
  }

  isAuthorized(authState) {
    const highestRole = getHighestRankingRole(authState.availableRoles)
    return highestRole && highestRole.role != ROLES.CONSUMER
  }

  getHighestAuthRole(authState) {
    return getHighestRankingRole(authState.availableRoles)
  }

  logout() {
    try {
      const logins = this.getCachedProviderLogins()
      const credentials = this.AWSCredentialsForLogins(logins)
      credentials.clearCachedId()
    } catch (err) {
      console.error(err)
    }

    this.clearAuthStateFromLocalStorage()

    return new Promise((resolve) => {
      firebase
        .auth()
        .signOut()
        .then(
          function () {
            console.log('logged out')
            resolve()
          },
          function (error) {
            console.error('logout error ', error)
            resolve()
          }
        )
    })
  }

  //Firebase

  loginToFirebase(tenantId) {
    return this.getFirebaseToken(tenantId)
      .catch((err) => {
        console.error('Error getting firebase token', { ...err, tenantId })
        throw err
      })
      .then((credentials) => {
        return this.applyFirebaseToken(credentials).catch((err) => {
          console.error('Error applying firebase token', {
            ...err,
            credentials
          })
          throw err
        })
      })
      .catch((err) => {
        console.error('Error logging into Firebase ', { ...err, tenantId })
        throw err
      })
  }

  applyFirebaseToken(authCredentials) {
    return new Promise((resolve, reject) => {
      const auth = app.auth()
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          authCredentials.uid = user.uid
          unsubscribe()
          resolve(authCredentials)
        }
      })
      auth
        .signInWithCustomToken(authCredentials.idToken)
        .then(() => {
          console.log('Signed into Firebase')
        })
        .catch((err) => {
          console.error('Firebase auth failed', err)
          unsubscribe()
          reject(err)
        })
    })
  }

  refreshFirebaseSession() {
    const auth = firebase.auth()
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(function (user) {
        unsubscribe()
        if (user) {
          console.log('got persisted user')
          resolve(user)
        } else {
          console.log('no persisted user')
          reject()
        }
      })
    }).then(() => auth.currentUser)
  }

  // Cognito
  getCognitoIdentity(logins) {
    return new Promise((resolve, reject) => {
      AWS.config.update({ region: CONFIG.AWS.REGION })
      let credentials = this.AWSCredentialsForLogins(logins)
      credentials.clearCachedId()
      credentials = this.AWSCredentialsForLogins(logins)
      credentials.get((err) => {
        if (err) {
          console.error('Error getting identity ', err)
          AWS.config.credentials = null
          reject(err)
        } else {
          let id = credentials.identityId
          this.cacheProviderLogins(logins)
          AWS.config.credentials = credentials
          this.setCredentialsExpiry(credentials.expireTime.getTime())
          console.log('Got Cognito credentials')
          resolve(id)
        }
      })
    })
  }

  isAWSAuthTokenExpired() {
    const expiry = this.getCredentialsExpiry()
    if (!expiry) {
      return true
    } else {
      const now = new Date().getTime()
      const expired = now >= expiry
      return expired
    }
  }

  refreshAWSSession() {
    const expiry = this.getCredentialsExpiry()
    if (process.env.NODE_ENV !== 'production' && expiry) {
      const now = new Date().getTime()
      const thiryMinutes = 30 * 60 * 1000
      const timeUntilExpire = expiry - now
      if (timeUntilExpire > thiryMinutes) {
        console.log('Skipping auth0 refresh in debug mode')
        return this.refreshCognitoIdentity()
      }
    }
    return this.attemptAuth0Refresh().then((idToken) => {
      console.log('Refreshed AWS credentials')
      this.saveAuth0IdToken(idToken)
      const newLogins = this.getCachedProviderLogins()
      return this.getCognitoIdentity(newLogins)
    })
  }

  refreshCognitoIdentity() {
    const logins = this.getCachedProviderLogins()
    if (!logins) {
      return Promise.reject(new Error('Credentials not found'))
    }
    return this.getCognitoIdentity(logins)
  }

  attemptAuth0Refresh() {
    const refreshToken = this.getAuth0RefreshToken()
    if (!refreshToken) {
      return Promise.reject(new Error('No Auth0 refresh token found'))
    }
    const auth0 = this.auth0Client()
    return new Promise((resolve, reject) => {
      auth0.refreshToken(refreshToken, (err, result) => {
        if (err || !result.id_token) {
          console.error(`Error attempting Auth0 refresh: ${err}`)
          reject(err)
        } else {
          resolve(result.id_token)
        }
      })
    })
  }

  saveAuth0IdToken(idToken) {
    const logins = this.getCachedProviderLogins() || {}
    logins[CONFIG.AUTH0.AUTH0_DOMAIN] = idToken
    this.cacheProviderLogins(logins)
  }

  auth0Client() {
    return new Auth0({
      domain: CONFIG.AUTH0.AUTH0_DOMAIN,
      clientID: CONFIG.AUTH0.CLIENT_ID
    })
  }

  AWSCredentialsForLogins(logins) {
    return new AWS.CognitoIdentityCredentials({
      IdentityPoolId: CONFIG.AWS.IDENTITY_POOL_ID,
      Logins: logins
    })
  }

  // API Gateway
  getFirebaseToken(tenantId) {
    let accessKeyId = AWS.config.credentials.accessKeyId
    let secretAccessKey = AWS.config.credentials.secretAccessKey
    let sessionToken = AWS.config.credentials.sessionToken
    let region = AWS.config.region

    let apigClient = apigClientFactory.newClient({
      accessKey: accessKeyId,
      secretKey: secretAccessKey,
      sessionToken: sessionToken,
      region: region,
      baseURL: CONFIG.AWS.API_GATEWAY_URL
    })
    const params = {}
    if (tenantId) {
      params.tenantId = tenantId
    }
    return apigClient
      .firebaseGet(params)
      .then(this.parseAuthTokenResponse.bind(this))
  }

  parseAuthTokenResponse(body) {
    let token = body.data.token
    if (token) {
      return Promise.resolve({
        idToken: token,
        ...body.data
      })
    } else {
      return Promise.reject(new Error('firebase token not found'))
    }
  }

  //Local storage

  cacheAuthState(authState) {
    const key = this.authStateKey()
    return this.cacheObject(key, authState)
  }

  getCachedAuthState() {
    const key = this.authStateKey()
    return this.readCachedObject(key)
  }

  removeAuthState() {
    const key = this.authStateKey()
    localStorage.removeItem(key)
  }

  authStateKey() {
    return `karla_auth_state`
  }

  clearAuthStateFromLocalStorage() {
    this.removeAuthState()
    this.removeProviderLogins()
    this.setAuth0RefreshToken(null)
    this.setCredentialsExpiry(null)
  }

  getCachedProviderLogins() {
    const key = this.providerLoginsKey()
    return this.readCachedObject(key)
  }

  cacheProviderLogins(logins) {
    const key = this.providerLoginsKey()
    return this.cacheObject(key, logins)
  }

  removeProviderLogins() {
    const key = this.providerLoginsKey()
    localStorage.removeItem(key)
  }

  providerLoginsKey() {
    return `karla_provider_logins`
  }

  authRoleKey() {
    return `karla_auth_role_v4`
  }

  getAuth0RefreshToken() {
    const key = this.auth0RefreshTokenKey()
    return this.readCachedObject(key)
  }

  setAuth0RefreshToken(refreshToken) {
    const key = this.auth0RefreshTokenKey()
    return this.cacheObject(key, refreshToken)
  }

  auth0RefreshTokenKey() {
    return `auth0_refresh_token`
  }

  getCredentialsExpiry() {
    const key = this.authExpiryKey()
    return this.readCachedObject(key)
  }

  setCredentialsExpiry(expiryTime) {
    const key = this.authExpiryKey()
    return this.cacheObject(key, expiryTime)
  }

  authExpiryKey() {
    return `auth_expiry_time`
  }

  cacheObject(key, object) {
    if (object === null) {
      localStorage.removeItem(key)
    } else {
      const jsonStr = JSON.stringify(object)
      localStorage.setItem(key, jsonStr)
    }
  }

  readCachedObject(key) {
    const jsonStr = localStorage.getItem(key)
    if (jsonStr) {
      return JSON.parse(jsonStr)
    } else {
      return null
    }
  }
}

function getHighestRankingRole(userRoles) {
  return (
    getTenantRoleMatchingRole(userRoles, 'demo') ||
    getTenantRoleMatchingRole(userRoles, 'admin') ||
    getTenantRoleMatchingRole(userRoles, 'specialist') ||
    getTenantRoleMatchingRole(userRoles, 'consumer')
  )
}

function getTenantRoleMatchingRole(tenantRoles, role) {
  for (const r of tenantRoles) {
    if (r.role == role) {
      return r
    }
  }
  return null
}

function isCachedRoleValid(cachedRole, currentRoles) {
  const matching = currentRoles.filter((curRole) => {
    return (
      curRole.tenantId == cachedRole.tenantId && curRole.role == cachedRole.role
    )
  })
  return matching.length > 0
}

function parseIndividualRolesDescending(tenantRole) {
  const roles = []
  if (tenantRole.demo) {
    roles.push({
      tenantId: tenantRole.tenantId,
      tenantName: tenantRole.tenantName,
      role: 'demo'
    })
  }
  if (tenantRole.admin) {
    roles.push({
      tenantId: tenantRole.tenantId,
      tenantName: tenantRole.tenantName,
      role: 'admin'
    })
  }
  if (tenantRole.specialist) {
    roles.push({
      tenantId: tenantRole.tenantId,
      tenantName: tenantRole.tenantName,
      role: 'specialist'
    })
  }
  return roles
}

function parseAllTenantRolesDescending(tenantRoles) {
  let allRoles = []
  for (const tenantRole of tenantRoles) {
    const roles = parseIndividualRolesDescending(tenantRole)
    allRoles = allRoles.concat(roles)
  }
  return allRoles
}
