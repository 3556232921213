import * as React from 'react';
import { Link } from 'react-router'

type Props = {
  orgId: string
  name: string
  userCount: number
  handleSelection: () => void
  handleDelete: () => void
  class: string
}

const OrgRow: React.SFC<Props> = (props) => (
  <tr onClick={props.handleSelection} className={props.class}>
    <td>
      {props.name}
    </td>
    <td>
      {props.userCount}
    </td>
    <td>
      {
        props.orgId === 'global' ?
          <Link to={`/organizations/${props.orgId}`}>Edit</Link> :
          <div><Link to={`/organizations/${props.orgId}`}>Edit</Link> / <a onClick={props.handleDelete}>Delete</a></div>
      }
    </td>
  </tr>
)
export default OrgRow;
