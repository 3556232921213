import * as React from 'react';
import ClientTables from './ClientTables'

export class ClientDataDashboard extends React.Component {

  render() {
    if (!this.props.isVisible) {
      return null;
    }
    return (
      <div className="client-dashboard-pane">
        <ClientTables clientId={this.props.clientId} />
      </div>
    )
  }
}
