import * as React from 'react';
import { Grid, Row } from '@karla/karla-react-components';
import { browserHistory } from 'react-router'

export default class extends React.Component {
  
  buttonClicked(e) {
    console.log('no action for button');
  }
  
  smsClicked(e) {
    browserHistory.push('/sms')
  }

  render() {

    return (
      <Grid>
        <Row>
          <button className='sh-btn onboarding-btn full-width' lg type='submit' onClick={this.smsClicked}>
            <span>Log In With SMS</span>
          </button>
        </Row>
        <Row>
          <button className='facebook-btn sh-btn full-width' lg type='submit' onClick={this.buttonClicked}>
            <img src='/imgs/icons/fb-icon.svg' className='btn-icon' />
            <span>Log In with Facebook</span>
          </button>
        </Row>
        <Row>
          <button className='twitter-btn sh-btn full-width' lg type='submit' onClick={this.buttonClicked}>
            <img src='/imgs/icons/twitter-icon.svg' className='btn-icon' />
            <span>Log In with Twitter</span>
          </button>
        </Row>
        <Row>
          <button className='sh-btn onboarding-btn full-width' lg type='submit' onClick={this.buttonClicked}>
            <span>Organization Login</span>
          </button>
        </Row>
      </Grid>
    );
  }
}
