import * as React from 'react';
import { SidebarNavItem } from '@karla/karla-react-components';
import { ChatClient } from '~/db/ChatClient'

export class ClientSidebarNavItem extends React.Component {

  static propTypes = {
    specialistId: React.PropTypes.string.isRequired,
    clientId: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
  };

  state = { unreadCount: 0 }

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.loadUnreadCount(this.props)
  }
  
  loadUnreadCount({ specialistId, clientId }) {
    this.unbindIfNeeded()
    this.chatClient = new ChatClient(specialistId, clientId)
    this.ref = this.chatClient.bindUnreadMessageCount({
      context: this,
      state: 'unreadCount'
    })
  }
  
  componentWillUnmount() {
    this.unbindIfNeeded()
  }

  unbindIfNeeded() {
    if (this.ref) {
      this.chatClient.unbind(this.ref)
    }
  }

  render() {
    const encodedId = encodeURIComponent(this.props.clientId)
    const path = `/clients/${encodedId}`
    const unreadCount = this.state.unreadCount == 0 ? "" : `(${this.state.unreadCount})`
    const title = `${this.props.name} ${unreadCount}`
    return (<SidebarNavItem name={title} href={path} />) 
  }
}
