import * as React from 'react'
import SurveyResponseModal from './SurveyResponseModal'
import './survey_modal.css'

export default class BucketSurveyResponseModal extends React.Component {
  
  static propTypes = {
    response: React.PropTypes.object.isRequired,
    assignment: React.PropTypes.object.isRequired,
        onHide: React.PropTypes.func.isRequired
  };

  renderAnswer(answer) {
    const bucket = Object.keys(answer.bucketValue)[0]
    const value = answer.bucketValue[bucket]
    const sign = value >= 0 ? "+" : ""
    const valueStr = `${bucket}: ${sign}${value}`
    return (
      <div>
        <div>
          {answer.answer}
        </div>
        <div>
          Microjournal: {answer.microjournal || 'None'}
        </div>
        <div>
          {valueStr}
        </div>
      </div>
    )
  }

  renderScores(response) {
    const bucketNames = Object.keys(response.scoreBuckets)
    return (
      <div>
        {bucketNames.map(name => {
          return (
            <div id={name}>{name}: {response.scoreBuckets[name]}</div>
          )
        })}
      </div>
    )
  }
  
  render() {
    const { response, assignment } = this.props
    return (
      <SurveyResponseModal name={assignment.name} {...this.props}>
        {response.answers.map((answer, i) => {
          return (
            <div key={i} className="survey-section">
              <div className="survey-section-header">
                {`#${i + 1}. ${answer.question}`}
              </div>
              {this.renderAnswer(answer)}
            </div>
          )
        })}
        <div className="survey-section">
          <div className="survey-section-header">
            Summary
          </div>
          {this.renderScores(response)}
          <div>
            {response.summary}
          </div>
        </div>
      </SurveyResponseModal>
    )
  }
}