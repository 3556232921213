import { browserHistory } from 'react-router'
import { URN } from '@karla/karla-core'
import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST_KEY,
  LOGIN_KEY_REQUEST_COMPLETE,
  LOGIN_CANCEL_KEY_REQUEST,
  LOGIN_ERROR,
  PROVIDER_LOGIN_SUCCESS,
  CHANGE_TENANT_ROLE,
  SWITCH_TENTANT,
  LOGOUT
} from './actionTypes/authActions'
import { AuthManager } from '../../common/lib/AuthManager'
import { Keychain } from '../../common/lib/Keychain'
import UserRepository from '../../db/UserRepository'
import { ChatClient } from '../../db/ChatClient'
import { LOADING_BEGIN, LOADING_END } from './actionTypes/spinnerActions'

export function providerLoginSuccess(provider, idToken, refreshToken) {
  return (dispatch) => {
    dispatch({
      type: PROVIDER_LOGIN_SUCCESS,
      provider,
      token: idToken,
      showOnboardingError: false
    })
    return dispatch(loginWithProviders(refreshToken))
  }
}

export function loginWithProviders(refreshToken) {
  return (dispatch, getState) => {
    const state = getState()
    const logins = state.providers

    const authManager = new AuthManager()
    return authManager
      .loginWithProviders(logins, null)
      .then((authState: any) => {
        if (!authManager.isAuthorized(authState)) {
          console.error('User is not authorized', authState)
          authManager.logout()
          dispatch(logout())
          browserHistory.push('/come-back-soon')
          return Promise.resolve()
        }
        return authState
      })
      .then(
        (result) => {
          if (!result) {
            return
          }
          const { uid } = result
          dispatch(loginSuccess(result))
          saveUserPhoneNumber(state, uid)
          authManager.cacheAuthState(result)
          authManager.setAuth0RefreshToken(refreshToken)
          const keychain = new Keychain(uid)
          return keychain
            .hasValidKeyPair()
            .then((hasValidKeys) => {
              if (hasValidKeys) {
                browserHistory.push('/')
                return
              }
              return keychain.sendKeyRequest().then((keyRequest) => {
                setTimeout(() => dispatch(showKeyRequest(keyRequest)), 0)
                return keychain.listenForKeyResponse(keyRequest.id).then(() => {
                  // These gives time to the ui to complete its flow before redirecting
                  setTimeout(() => {
                    browserHistory.push('/')
                  }, 0)
                  dispatch(completeKeyRequestAction())
                })
              })
            })
            .catch((error) => {
              console.error('Error logging in loginPromiseChain', error)
              dispatch(cancelKeyRequestAction())
              dispatch(loginError(error))
            })
        },
        (error) => {
          console.error('Error logging in', error)
          dispatch(cancelKeyRequestAction())
          dispatch(loginError(error))
        }
      )
  }
}

function saveUserPhoneNumber(state, uid) {
  const userRepo = new UserRepository()
  const user = {
    [URN.CONSUMER.PHONENUMBER]: state.auth.phoneNumber
  }
  return userRepo.updateUser(uid, user)
}

export function clearKeychain() {
  return (dispatch, getState) => {
    const auth = getState().auth || {}
    if (!auth.uid) {
      return Promise.resolve()
    }

    const userId = auth.uid
    const keyChain = new Keychain(userId)

    keyChain.clearLocalKeys()

    if (auth.keyRequest) {
      const requestId = auth.keyRequest.id
      dispatch(cancelKeyRequestAction())
      return keyChain.cancelKeyRequest(requestId)
    }

    return Promise.resolve()
  }
}

function cancelKeyRequestAction() {
  return {
    type: LOGIN_CANCEL_KEY_REQUEST
  }
}

function completeKeyRequestAction() {
  return {
    type: LOGIN_KEY_REQUEST_COMPLETE
  }
}

export function logout() {
  const chatClient = new ChatClient()
  chatClient.clearMessageCache()
  return (dispatch) => {
    dispatch(clearKeychain())
      .then(() => {
        const authManager = new AuthManager()
        return authManager.logout()
      })
      .then(() => {
        browserHistory.push('/')
        return dispatch({
          type: LOGOUT
        })
      })
  }
}

export function changeAuthRole(tenantRole) {
  return async (dispatch, getState) => {
    // eslint-disable-next-line no-console
    console.time('changeAuthRole')
    dispatch({
      type: LOADING_BEGIN
    })
    const state = getState()
    const { selectedRole } = state.auth
    const authManager = new AuthManager()
    if (selectedRole.tenantId !== tenantRole.tenantId) {
      const newAuthState = await authManager.reauthenticateWithTenant(
        tenantRole
      )
      dispatch({
        type: SWITCH_TENTANT,
        payload: newAuthState
      })
    } else {
      authManager.updateCachedRole(tenantRole.role)
      dispatch({
        type: CHANGE_TENANT_ROLE,
        role: tenantRole.role
      })
    }
    // eslint-disable-next-line no-console
    console.timeEnd('changeAuthRole')
    return dispatch({
      type: LOADING_END
    })
  }
}

function loginSuccess(auth) {
  return {
    type: LOGIN_SUCCESS,
    payload: auth
  }
}

function showKeyRequest(keyRequest) {
  return {
    type: LOGIN_REQUEST_KEY,
    payload: {
      keyRequest
    }
  }
}

export function loginError(error) {
  return { type: LOGIN_ERROR, error }
}
