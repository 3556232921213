import { LOADING_BEGIN, LOADING_END, LOADING_END_ALL } from '../actions/actionTypes/spinnerActions'

const initialState = {
  loading: false,
  tasks: 0
}

export default (state = initialState, { type }) => {
  switch (type) {
    case LOADING_BEGIN:
      state.tasks++
      return { ...state, loading: state.tasks > 0 }
    case LOADING_END:
      if (state.tasks > 0) {
        state.tasks--
      }
      else state.tasks = 0
      return { ...state, loading: state.tasks > 0 }
    case LOADING_END_ALL:
      state.tasks = 0
      return { ...state, loading: false }

    default:
      return state
  }
}
