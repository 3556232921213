import Datastore from '~/db/Datastore'
import UserRepository from '~/db/UserRepository'
import { URN } from '@karla/karla-core'
import { getUser } from './Users'
import { Keychain } from '../common/lib/Keychain'

const recipesPath = 'recipes'
const recipeAuthorsPath = 'recipeAuthors'
const recipeOrgAssigneesPath = 'recipeOrgAssignees'
const recipeAssigneesPath = 'recipeAssignees'

const ORG_TYPE = 'org'
const USER_TYPE = 'user'

export default class RecipeRepository extends Datastore {
  
  constructor() {
    super()
    this.userRepo = new UserRepository()
  }

  // Updates
  saveRecipe(recipe) {

    if (!recipe[URN.SERVER.TIMESTAMP]) {
      recipe[URN.SERVER.TIMESTAMP] = this.getTimestampValue()
    }

    this.attachSignatureToRecipe(recipe)

    const xml = recipe[URN.RECIPE.XML]

    recipe[URN.RECIPE.TYPE] = xml ? 'xml' : 'js'
    recipe[URN.RECIPE.PLATFORMMOBILE] = true

    if (xml) {
      recipe["urn:recipe:trigger"] = buildTriggerInfo(xml)
    }

    if (recipe.assigneeName) { delete recipe.assigneeName }

    if (recipe.authorUser) { delete recipe.assignedUser }

    let recipeRef;

    if (recipe.id) {
      recipeRef = this.getRecipeRef(recipe.id)
    } else {
      recipeRef = this.getRecipesRef().push()
    }

    const recipeId = recipeRef.key
    return this.clearAssignments(recipeId).then(() => {

      const assigneeId = recipe[URN.RECIPE.ASSIGNEDTO]
      const assigneeType = recipe[URN.RECIPE.ASSIGNEETYPE] || USER_TYPE
      const assigneePath = `${assigneeType}:${assigneeId}`
      recipe[URN.RECIPE.ASSIGNEEPATH] = assigneePath

      const updateRecipe = this.replaceRef(recipeRef, recipe)

      const updateAuthor = this.updateAuthorForRecipe(recipeId, recipe[URN.RECIPE.AUTHOR], true)
      const updateAssignee = this.updateAssigneeForRecipe(recipeId, assigneeId, assigneeType, true)

      return Promise.all([updateRecipe, updateAuthor, updateAssignee])
    })
    .catch((err) => {
      console.error("Save recipe error: " + err)
      throw err
    })
  }

  attachSignatureToRecipe(recipe) {
    const authorId = this.userId()
    recipe[URN.RECIPE.AUTHOR] = authorId
    const flowSource = recipe[URN.RECIPE.JS]
    const keychain = new Keychain(authorId)
    const signature = keychain.createSignature(flowSource)
    recipe["urn:author:signatureBase64"] = btoa(signature)
  }

  clearAssignments(recipeId) {
    return this.getRecipe(recipeId).then(recipe => {
      if (!recipe[URN.RECIPE.ASSIGNEDTO]) {
        return
      }
      const assigneeId = recipe[URN.RECIPE.ASSIGNEDTO]
      const assigneeType = recipe[URN.RECIPE.ASSIGNEETYPE] || USER_TYPE
      return this.updateAssigneeForRecipe(recipeId, assigneeId, assigneeType, false)
    })
  }

  getRecipesAssignedToUser(userId) {

    const query = this.getRecipesRef()
      .orderByChild('urn:recipe:assignedto')
      .equalTo(userId);

    return new Promise((resolve, reject) => {
      return query.once('value', (snapshot) => {
        resolve(snapshot.val() || [])
      }, reject)
    });
  }

  toggleRecipeEnabled(recipeId) {
    const ref = this.getRecipeRef(recipeId)
    return this.getValueAtRef(ref).then(recipe => {
      const newValue = !recipe[URN.RECIPE.ENABLED]
      recipe[URN.RECIPE.ENABLED] = newValue
      this.attachSignatureToRecipe(recipe)
      return this.updateRef(ref, recipe)
    })
  }

  deleteRecipe(recipeId) {
    return this.getRecipe(recipeId).then(recipe => {
      const recipeRef = this.getRecipeRef(recipeId)
      const deleteRecipe = this.deleteRef(recipeRef)
      const authorId = recipe[URN.RECIPE.AUTHOR]
      const assigneeId = recipe[URN.RECIPE.ASSIGNEDTO]
      const assigneeType = recipe[URN.RECIPE.ASSIGNEETYPE]
      const removeAuthor = this.updateAuthorForRecipe(recipeId, authorId, false)
      const removeAssignee = this.updateAssigneeForRecipe(recipeId, assigneeId, assigneeType, false)
      return Promise.all([deleteRecipe, removeAuthor, removeAssignee])
    })
  }

  updateAuthorForRecipe(recipeId, authorId, isAuthor) {
    const authorValue = isAuthor ? true : null
    const authorRef = this.getRecipesAuthorsRef(authorId)
    const authorRecord = {
      [recipeId]: authorValue
    }
    return this.updateRef(authorRef, authorRecord)
  }

  updateAssigneeForRecipe(recipeId, assigneeId, assigneeType, assigned) {
    const assignedValue = assigned ? true : null
    const assigneeRef = this.getRecipeAssigneesRef(assigneeId, assigneeType)
    const assignmentRecord = {
      [recipeId]: assignedValue
    }
    return this.updateRef(assigneeRef, assignmentRecord)
  }

  // Queries
  getAuthoredRecipes(authorId) {
    const authorRef = this.getRecipesAuthorsRef(authorId)
    return this.getValueAtRef(authorRef).then(authoredRecipes => {
      if (!authoredRecipes) {
        return []
      }
      const recipeIds = Object.keys(authoredRecipes)
      const getRecipes = recipeIds.map(recipeId => {
        return this.getRecipeWithEmbeddedAssignee(recipeId)
      })
      return Promise.all(getRecipes)
    })
  }

  getRecipeWithEmbeddedAssignee(recipeId) {
    const ref = this.getRecipeRef(recipeId)
    const flatten = true
    return this.getValueAtRef(ref, flatten).then(recipe => {
      const assigneeId = recipe[URN.RECIPE.ASSIGNEDTO]
      if (!assigneeId) {
        return recipe
      }
      const assignedType = recipe[URN.RECIPE.ASSIGNEETYPE] || USER_TYPE
      let getName;
      if (assignedType == USER_TYPE) {
        getName = getUser(assigneeId)
          .then(user => user.fullName)
          .catch(() => {
            return 'unknown'
          })
      } else {
        getName = this.getValueAtPath(`orgs/${assigneeId}/name`)
      }
      return getName.then(name => {
        recipe.assigneeName = name
        return recipe
      })
    })
  }


  // Queries
  getAssignedRecipes(userId) {
    return this.userRepo.getUserOrgs(userId).then((orgs) => {
      const getUserRecipes = this.getAssignedRecipesForType(USER_TYPE, userId)
      const getOrgRecipes = orgs.map(org => {
        return this.getAssignedRecipesForType(ORG_TYPE, org.id)
      })
      return Promise.all([getUserRecipes, ...getOrgRecipes]).then((recipeResults) => {
        const merged = [].concat.apply([], recipeResults)
        const recipeIds = merged.map(recipe => recipe.id)
        const getRecipesWithAssignees = recipeIds.map(recipeId => {
          return this.getRecipeWithEmbeddedAssignee(recipeId)
        })
        const repciesWithAssigneesAndAuthors = Promise.all(getRecipesWithAssignees).then((recipesWithAssignees) => {
          return this.populateAuthorNames(recipesWithAssignees)
        })
        return repciesWithAssigneesAndAuthors
      }).catch((err) => {
        console.log("got err: ", err)
      })
    })
  }
  
  getAssignedRecipesForType(assigneeType, id) {
    const identifier = `${assigneeType}:${id}`
    const query = this.getRecipesRef()
      .orderByChild(URN.RECIPE.ASSIGNEEPATH)
      .equalTo(identifier);
    return this.getArrayFromRef(query)
  }
  
  populateAuthorNames(recipes) {
    const authorIdMap = {}
    recipes.forEach((recipe) => {
      const authorId = recipe[URN.RECIPE.AUTHOR]
      if (authorId) {
        authorIdMap[authorId] = true
      }
    })
    const authorIds = Object.keys(authorIdMap)
    const getAuthors = authorIds.map((id) => {
      return getUser(id)
    })
    return Promise.successes(getAuthors).then((authors) => {
      const authorsById = authors.reduce((map, author) => {
        map[author.id] = author
        return map
      }, {})
      return recipes.map((recipe) => {
        const authorId = recipe[URN.RECIPE.AUTHOR]
        if (authorId) {
          const author = authorsById[authorId]
          if (author) {
            recipe.authorUser = {
              [URN.CONSUMER.FULLNAME]: author.fullName
            }
          }
        }
        return recipe
      })
    })
  }

  getRecipe(recipeId) {
    const ref = this.getRecipeRef(recipeId)
    return this.getValueAtRef(ref, true)
  }

  getRecipeRef(recipeId) {
    return this.getRecipesRef().child(recipeId)
  }

  getRecipesRef() {
    return this.getRef(recipesPath)
  }

  getRecipesAuthorsRef(uid) {
    return this.getRef(recipeAuthorsPath).child(uid)
  }

  getRecipeAssigneesRef(uid, type) {
    if (type == ORG_TYPE) {
      return this.getOrgRecipeAssigneesRef(uid)
    } else {
      return this.getUserRecipeAssignessRef(uid)
    }
  }

  getUserRecipeAssignessRef(uid) {
    return this.getRef(recipeAssigneesPath).child(uid)
  }

  getOrgRecipeAssigneesRef(orgId) {
    return this.getRef(recipeOrgAssigneesPath).child(orgId)
  }
}

function buildTriggerInfo(xml) {
  const parser = new DOMParser()
  const xmlDOM = parser.parseFromString(xml, "text/xml")
  const blocks = xmlDOM.getElementsByTagName("block")
  const triggerBlock = getTriggerBlock(blocks)
  if (!triggerBlock) {
    return null
  }
  switch (triggerBlock.getAttribute("type")) {
    case 'location_enter':
    case 'location_exit':
      return buildLocationTriggerInfo(triggerBlock)
    default:
      return null
  }
}

function buildLocationTriggerInfo(block) {

  const valueElements = block.getElementsByTagName("value")

  for (let i = 0, length = valueElements.length; i < length; i++) {
    const valueBlock = valueElements[i]
    const name = valueBlock.getAttribute("name")
    switch (name) {
      case 'RADIUS':
        var radiusElement = valueBlock
        break
      case 'LAT':
        var latElement = valueBlock
        break
      case 'LONG':
        var longElement = valueBlock
        break
      case 'DELAY':
        var delayElement = valueBlock
        break
    }
  }

  const radius = numberValueFromBlock(radiusElement)
  const lat = numberValueFromBlock(latElement)
  const long = numberValueFromBlock(longElement)
  const delay = numberValueFromBlock(delayElement)

  return {
    type: "location",
    event: block.getAttribute("type"),
    args: {
      radius,
      lat,
      long,
      delay
    }
  }
}

function getTriggerBlock(blocks) {
  if (!blocks || blocks.length == 0) {
    return null
  }
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i]
    if (block.getAttribute("type") == 'control_when') {
      continue
    } else {
      return block
    }
  }
  return null
}

function numberValueFromBlock(block) {
  const value = parseFloat(valueFromBlock(block))
  return isNaN(value) ? null : value
}

function valueFromBlock(block) {
  return block.getElementsByTagName("field")[0].textContent
}
