import Datastore from './Datastore'
import { flattenObjectsByIdToArray } from '../common/lib/object_helpers'

const surveysPath = 'surveys'
const surveyResponsesPath = 'surveyResponses'
const assignmentsPath = 'contentAssignments'
const archivedAssignmentsPath = 'archivedContentAssignments'

export default class SurveyRepository extends Datastore {
  getSurveys(surveyId) {
    return this.getValueAtRef(surveyId)
  }

  getAllSurveys() {
    const query = this.getSurveysRef()
    return this.getArrayFromRef(query).then((surveys) => {
      return surveys
    })
  }

  getLatestSurveyResponse(clientId, assignmentId) {
    const responsesReference = this.getResponsesRef(clientId, assignmentId)
    return this.getValueAtRef(responsesReference).then(async (response) => {
      if (response) {
        response.id = assignmentId
      }
      return response
    })
  }

  getAssignedContent(clientId, listener) {
    const query = this.getAssignmentsRef(clientId)
    return this.bindToRef(query, (assignments) => {
      listener(flattenObjectsByIdToArray(assignments.val()))
    })
  }

  getArchivedContent(clientId, listener) {
    const query = this.getArchivedAssignmentsRef(clientId)
    return this.bindToRef(query, (assignments) => {
      listener(flattenObjectsByIdToArray(assignments.val()))
    })
  }

  saveAssignment(assignment, userId) {
    assignment.created = new Date().getTime() / 1000
    const reference = this.getRef(`${assignmentsPath}/${userId}`)
    return this.pushChildAtRef(reference, assignment)
  }

  updateAssignment(assignment, userId) {
    const reference = this.getRef(
      `${assignmentsPath}/${userId}/${assignment.id}`
    )
    return this.replaceRef(reference, assignment)
  }

  archiveAssignedContent(clientId, contentId) {
    const assignedReference = this.getAssignmentsRef(clientId).child(contentId)
    return this.getValueAtRef(assignedReference).then((assignment) => {
      const archivedReference = this.getArchivedAssignmentsRef(clientId).child(
        contentId
      )
      return this.updateRef(archivedReference, assignment).then(() => {
        this.deleteRef(assignedReference)
      })
    })
  }

  getSurveysRef() {
    return this.getRef(surveysPath)
  }

  getAssignmentsRef(clientId) {
    return this.getRef(assignmentsPath).child(clientId)
  }

  getArchivedAssignmentsRef(clientId) {
    return this.getRef(archivedAssignmentsPath).child(clientId)
  }

  getResponsesRef(clientId, assignmentId) {
    return this.getRef(surveyResponsesPath).child(clientId).child(assignmentId)
  }
}
