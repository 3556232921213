import * as React from 'react';
import { Modal } from 'react-bootstrap'

export default class ConfirmModal extends React.Component {

  static propTypes = {
    title: React.PropTypes.string.isRequired,
    prompt: React.PropTypes.string.isRequired,
    confirm: React.PropTypes.func.isRequired,
    dismiss: React.PropTypes.func.isRequired
  };

  render() {
    return (
      <Modal className="sh-modal dark-modal"
        show={true}
        backdrop={true}
        backdropClassName="modal-shadow-background"
        onHide={this.props.dismiss}>
        <img src="/imgs/icons/x-button.svg" className="close-btn hover-pointer" onClick={this.props.dismiss} style={{cursor: "pointer"}} />
        <Modal.Body>
          <h1 className="title">{this.props.title}</h1>
          {this.props.prompt}
        </Modal.Body>
        <Modal.Footer>
          <button className="sh-btn info" onClick={this.props.dismiss}>No</button>
          <button className="sh-btn danger" onClick={this.props.confirm}>Yes</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
