import * as React from 'react'
import { Grid, Row } from '@karla/karla-react-components'
import OrgList from './OrgList'
import OrgUsersList from './OrgUsersList'
import OrgInviteModal from './OrgInviteModal'
import { Link } from 'react-router'
import { ORGS } from '@karla/karla-core'
import APIGatewayClient from '../../api/APIGatewayClient'
import { connect } from 'react-redux'

type Props = {
  tenantId: string
}

type State = {
  inviteError?: any
  isSendingInvite: boolean
  orgId: string
  inviteModal: boolean
}

const rootOrg = ORGS.GLOBAL

const mapStateToProps = (state) => {
  return {
    tenantId: state.auth.selectedRole.tenantId
  }
}

const mapDispatchToProps = () => {
  return {}
}

@connect(
  mapStateToProps,
  mapDispatchToProps
)
export default class OrgContainer extends React.Component<Props, State> {

  apiClient: any
  onClickAddUser: any
  onClickHideModal: any
  onSaveUserInvite: any

  constructor(props) {
    super(props)
    this.state = {
      isSendingInvite: false,
      orgId: rootOrg,
      inviteModal: false,
      inviteError: null
    }
    this.apiClient = new APIGatewayClient()
    this.onClickAddUser = this.addUserClicked.bind(this)
    this.onClickHideModal = this.hideInviteModal.bind(this)
    this.onSaveUserInvite = this.inviteModalDidSave.bind(this)
  }

  orgSelected(id: string) {
    this.setState({
      orgId: id
    })
  }

  addUserClicked() {
    this.showInviteModal()
  }

  showInviteModal() {
    this.setState({
      inviteModal: true, 
      isSendingInvite: false,
      inviteError: null
    })
  }

  hideInviteModal() {
    this.setState({
      inviteModal: false, 
      isSendingInvite: false,
      inviteError: null
    })
  }

  inviteModalDidSave(invite: any) {
    this.setState({
      isSendingInvite: true,
      inviteError: null
    })
    invite.tenantId = this.props.tenantId
    return this.apiClient.sendInvite(invite).then(() => {
      this.hideInviteModal()
    }).catch((err: Error) => {
      this.setState({
        isSendingInvite: false,
        inviteError: err.message
      })
    })
  }

  renderModal() {
    if (!this.state.inviteModal) {
      return null
    }
    return (
      <OrgInviteModal 
        onHide={this.onClickHideModal}
        onSave={this.onSaveUserInvite}
        errorMessage={this.state.inviteError}
        isSending={this.state.isSendingInvite}
        tenantId={this.props.tenantId}
      />
    )
  }
  
  render() {
    
    
    return (
      <div id='body'>
        {this.renderModal()}
        <Grid>
          <Row>
          <div className="relative">
            <h1 className='float-left'>Organizations</h1>
            <div className='float-right section-header-control'>
            <Link to='/organizations/create'>Create New Org Unit </Link> | <Link onClick={this.onClickAddUser}> Invite User</Link>
            </div>
          </div>
          </Row>
          <Row>
            <OrgList tenantId={this.props.tenantId} orgSelected={this.orgSelected.bind(this)}/>
          </Row>
          <br />
          <Row>
            <div className="relative">
              <h1>Users</h1>
            </div>
          </Row>
          <Row>
            <OrgUsersList orgId={this.state.orgId} tenantId={this.props.tenantId} />
          </Row>
        </Grid>
      </div>
    )
  }
}
