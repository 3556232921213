import firebase from 'firebase/app';

const firestore = firebase.firestore();

const contentAssignmentStatsPath = '/contentAssignmentStats';

interface ContentAssignmentStatsRepository {
  props: ContentAssignmentStatsProps;
}
type ContentAssignmentStatsProps = {
  userId: string;
  role: string;
};

class ContentAssignmentStatsRepository implements ContentAssignmentStatsRepository {
  constructor(props: ContentAssignmentStatsProps) {
    this.props = props;
  }

  private getcontentAssignmentStatsDocument() {
    return firestore.collection(contentAssignmentStatsPath).doc(`${this.props.userId}`);
  }

  async updateContentAssignmentCounter(counter: number = 1) {
    const doc = this.getcontentAssignmentStatsDocument();
    const increment = firebase.firestore.FieldValue.increment(counter);
    try {
      const realDoc = await doc.get();
      if (realDoc.exists) {
        return await doc.update('assignedSurveys', increment);
      }
      return await doc.set({
        assignedSurveys: increment,
        completedSurveys: 0
      });
    } catch (error) {
      console.error(error);
      return error;
    }
  }
}

export default ContentAssignmentStatsRepository;
