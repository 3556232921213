import * as React from 'react'
import { SidebarNavItem, SidebarNav, Grid, Row, Col, Sidebar } from '@karla/karla-react-components'

import { connect } from 'react-redux'
import { ROLES } from '@karla/karla-core'
import { logout } from '../redux/actions/login'
import { ClientSidebarNavItem } from '~/common/ClientSidebarNavItem'
import { subscribeToTelehealthClients } from '../db/Telehealth';

const mapStateToProps = state => {
  const auth = state.auth
  return {
    role: auth.selectedRole && auth.selectedRole.role,
    userId: auth.uid,
    tenantId: auth.selectedRole.tenantId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(logout())
    }
  }
}

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class ApplicationSidebar extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      clients: []
    }
  }
  
  componentWillReceiveProps(props) {
    this.fetchClients(props)
  }
  
  componentDidMount() {
    this.fetchClients(this.props)
  }
  
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
      this.unsubscribe = null
    }
  }
  
  fetchClients({ userId, role, tenantId }) {

    if (this.unsubscribe) {
      this.unsubscribe()
    }

    if (!this.isSpecialist(role) || !userId) {
      this.setState({
        clients: []
      })
      return
    }
    this.unsubscribe = subscribeToTelehealthClients(userId, tenantId, (clients) => {
      this.setState({
        clients
      })
    })
  }
  
  buildWhenDoNav() {
    return (
      <SidebarNavItem icon='whendo-nav-icon' name='When Do' href='/whendo' />
    )
  }
  
  isSpecialist(role) {
    if (!role) {
      role = this.props.role
    }
    return role == ROLES.SPECIALIST || role == ROLES.DEMO
  }
  
  isAdmin(role) {
    if (!role) {
      role = this.props.role
    }
    return role == ROLES.ADMIN || role == ROLES.DEMO
  }

  isDemo() {
    return this.props.role == ROLES.DEMO
  }

  buildTelehealthNav() {

    const { clients } = this.state

    if (clients.length == 0 || !this.isSpecialist()) { return (<span />) }

    var items = clients.map(client => {
      const encodedId = encodeURIComponent(client.id)
      const path = `/clients/${encodedId}`
      const name = client.fullName
      return (<ClientSidebarNavItem key={client.id} clientId={client.id} specialistId={this.props.userId} name={name} href={path} />)
    })

    var self = (<SidebarNavItem key={"self:" + this.props.userId} name="Self" href={`/clients/${encodeURIComponent(this.props.userId)}`} />)
    items.push(self)

    return (
      <SidebarNavItem icon='telehealth-nav-icon' name='Participants'>
        <SidebarNav>
          {items}
        </SidebarNav>
      </SidebarNavItem>
    )
  }
  
  render() {
    return (
      <div>
        <Grid>
          <Row>
            <Col xs={12}>
              <div className='sidebar-nav-container'>
                <SidebarNav style={{ marginBottom: 0 }}>
                {this.isAdmin() ? <SidebarNavItem icon='orgs-users-nav-icon' name='Orgs and Users' href='/' /> : null}
                {this.isAdmin() ? <SidebarNavItem icon='orgs-users-nav-icon' name='Invitations' href='/invites' /> : null}
                {this.isAdmin() ? <SidebarNavItem icon='dashboard-nav-icon' name='Dashboard' href='/dashboard' /> : null}
                {this.buildTelehealthNav()}
                {this.isDemo() ? <SidebarNavItem icon='upload-nav-icon' name='Upload' href='/upload' /> : null}
                {this.buildWhenDoNav()}
                {this.isDemo() ? <SidebarNavItem icon='reporting-nav-icon' name='Reporting' href='/reporting' /> : null }
                {this.isDemo() ? <SidebarNavItem icon='settings-nav-icon' name='Settings' href='/settings' /> : null}
                </SidebarNav>
              </div>
            </Col>
          </Row>
          <Row>
            <div id="logout" onClick={this.props.logout}>LOG OUT</div>
          </Row>
        </Grid>
      </div>
    )
  }
}


export default class extends React.Component {
  render() {
    return (
      <div id='sidebar'>
        <div id='header-container' >
          <img src='/imgs/sh_logo.svg' />
        </div>
        <div id='sidebar-container'>
          <Sidebar sidebar={0}>
            <ApplicationSidebar />
          </Sidebar>
        </div>
      </div>
    )
  }
}
