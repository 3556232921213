import * as React from 'react';
import { Table } from '@karla/karla-react-components';
import InviteRow from './InviteRow'
import { Invite } from '../../../db/Invites';


class OrgListHeader extends React.Component {
  render() {
    return (
      <thead>
        <tr>       
          <th width="64">Name</th>
          <th width="64">Phone Number</th>
          <th width="64">Roles</th>
          <th width="64">Last Sent</th>
          <th width="64">Accepted</th>
          <th width="64">Times sent</th>
          <th width="64">Invite Link</th>
          <th width="64"></th>
        </tr>
      </thead>
    );
  }
}

type Props = {
  invites: Invite[],
  tenantId: String
}

export default class InviteList extends React.Component<Props, {}> {
  
  render() {

    const invites = this.props.invites

    return (
      <Table responsive className="sh-list-view bordered-box table-middle-align">
        <OrgListHeader />
        <tbody>
          {invites.map((invite, i) => {
            return <InviteRow key={i} tenantId={this.props.tenantId} {...invite} />
          })}
        </tbody>
      </Table>
    );
  }
}
