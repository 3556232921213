
module.exports = {
  SMS_LOGIN_ATTEMPT: "SMS_LOGIN_ATTEMPT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_REQUEST_KEY: "LOGIN_REQUEST_KEY",
  LOGIN_KEY_REQUEST_COMPLETE: "LOGIN_KEY_REQUEST_COMPLETE",
  LOGIN_CANCEL_KEY_REQUEST: "LOGIN_CANCEL_KEY_REQUEST",
  LOGIN_ERROR: "LOGIN_ERROR",
  PROVIDER_LOGIN_SUCCESS: "PROVIDER_LOGIN_SUCCESS",
  CHANGE_TENANT_ROLE: "CHANGE_TENANT_ROLE",
  SWITCH_TENTANT: "SWITCH_TENTANT",
  LOGOUT: "LOGOUT"
}
