import { CONFIG } from '~/config'
import { ROLES, ORGS, URN } from '@karla/karla-core'
import { objectWithValueForKeys, objectFromArray, copyObjectProperties } from '~/common/lib/object_helpers'
import Datastore from '~/db/Datastore'
import SearchClient from '~/db/SearchClient'

export default class UserRepository extends Datastore {

  // Data bindings

  bindOrgs(options) {
    return this.bindStateToPath('orgs', options)
  }

  bindToOrg(orgId, options) {
    return this.bindToObjectAtPath(`orgs/${orgId}`, options)
  }

  bindToUser(userId, options) {
    return this.bindStateToPath(`users/${userId}`, options)
  }

  bindUsersOfOrg(orgId, options) {
    const userOrgsRef = this.getRef(`orgUsers/${orgId}`)
    return this.bindRelatedObjects(userOrgsRef, 'users', options)
  }

  bindOrgsOfUser(userId, options) {
    const userOrgsRef = this.getUserRef(userId).child('orgs')
    return this.bindRelatedObjects(userOrgsRef, 'orgs', options)
  }

  // Queries

  getOrg(orgId, flatten = true) {
    return this.getValueAtPath(`orgs/${orgId}`, flatten)
  }

  getUser(userId, flatten = true) {
    return this.getValueAtPath(`users/${userId}`, flatten)
  }

  getUserRoles(userId) {
    return this.getValueAtPath(`userRoles/${userId}`).then((roles) => roles || {})
  }

  getOrgs() {
    const flatten = true
    return this.getValueAtPath('orgs', flatten).then((orgsMap) => {
      const orgs = orgsMap || {}
      delete orgs.id
      return orgs
    })
  }
  
  getUserOrgs(userId) {
    return this.getUserOrgIds(userId).then((orgIds) => {
      const getOrgs = orgIds.map(id => this.getOrg(id))
      return Promise.all(getOrgs)
    })
  }

  isRoleValidForUser(role, uid) {
    return this.getUserRoles(uid).then(userRoles => {
      return userRoles[role] === true
    })
  }

  getOrgUsers(orgId) {
    const flatten = false
    return this.getValueAtPath(`orgUsers/${orgId}`, flatten).then(orgUsers => {
      const userIds = orgUsers ? Object.keys(orgUsers) : []
      const getUsers = userIds.map(userId => {
        return this.getUser(userId)
      })
      return Promise.all(getUsers)
    })
  }

  getUserOrgIds(userId) {
    const flatten = false
    return this.getValueAtPath(`userOrgs/${userId}`, flatten).then(userOrgs => {
      return userOrgs ? Object.keys(userOrgs) : []
    })
  }

  getUserOrgIdsMap(userId) {
    const flatten = false
    return this.getValueAtPath(`userOrgs/${userId}`, flatten).then(userOrgs => {
      return userOrgs || {}
    })
  }

  getUserWithRole(role) {
    return this.getValueAtPath(`roleUsers/${role}`).then((userIds) => {
      userIds = Object.keys(userIds || {})
      const getUsers = userIds.map(id => this.getUser(id))
      return Promise.all(getUsers)
    })
  }

  getTotalOrgUserCount(orgId) {
    return this.countUsersOfOrg(orgId).then(rootOrgCount => {
      return this.getAllChildOrgRefs(orgId).then(childRefs => {
        const countChildren = childRefs.map(ref => {
          return this.countUsersOfOrg(ref.key)
        })
        return Promise.all(countChildren).then(counts => {
          const totalCount = counts.reduce((prev, count) => {
            return prev + count
          }, rootOrgCount)
          return totalCount
        })
      })
    })
  }

  countUsersOfOrg(orgId) {
    const ref = this.getRef(`orgUsers/${orgId}`)
    return this.countChildrenOfRef(ref)
  }

  getOrgsAvailableToUser(uid) {
    return this.getOrgs()
  }

  // Org Updates

  createOrganization(org) {
    const orgId = org.id

    const orgRef = this.getOrgRef(orgId)
    org.admins = null

    const updateOrg = this.updateRef(orgRef, org)

    return Promise.all([updateOrg]).then(() => {
      const searchClient = new SearchClient()
      return searchClient.updateOrgSearchTerms(orgId)
    })
  }

  updateOrganization(orgId, updatedOrg) {

    return this.getOrg(orgId).then(existingOrg => {

      var updates = []

      updatedOrg.admins = null

      // update org data
      const orgRef = this.getOrgRef(orgId)
      const updateOrgValues = this.updateRef(orgRef, updatedOrg)
      updates.push(updateOrgValues)

      return Promise.all(updates).then(() => {
        const searchClient = new SearchClient()
        return searchClient.updateOrgSearchTerms(orgId)
      })
    })
  }

  deleteOrg(orgId) {
    return this.getOrg(orgId).then(org => {
      if (!org) {
        return
      }

      let updates = []

      const orgUserIds = org.users ? Object.keys(org.users) : []

      if (orgUserIds.length > 0) {

        const unassignUsers = 
        updates.push(unassignUsers)
      }

      const orgRef = this.getOrgRef(orgId)
      const deleteOrg = this.deleteRef(orgRef)
      updates.push(deleteOrg)

      return Promise.all(updates)
    })
  }

  // User Updates

  updateUser(uid, updatedUser) {

    const userRef = this.getUserRef(uid)
    const searchClient = new SearchClient()

    const properties = [URN.CONSUMER.FIRSTNAME,
    URN.CONSUMER.LASTNAME,
    URN.CONSUMER.EMAIL,
    URN.CONSUMER.PHONENUMBER]

    let updatedRecord = copyObjectProperties(updatedUser, properties)

    const firstName = updatedRecord[URN.CONSUMER.FIRSTNAME]
    const lastName = updatedRecord[URN.CONSUMER.LASTNAME]
    if (firstName && lastName) {
      const fullName = `${firstName} ${lastName}`
      updatedRecord[URN.CONSUMER.FULLNAME] = fullName
    }

    let updates = []

    const updateUserRef = this.updateRef(userRef, updatedRecord)
    updates.push(updateUserRef)

    return Promise.all(updates).then(() => {
      return searchClient.updateUserSearchTerms(uid)
    })
  }

  updateUserOrgs(userId, orgsMap) {
    return this.getUserOrgs(userId).then(oldOrgs => {
      const oldOrgIds = oldOrgs.map(org => org.id)
      const removeOldOrgs = oldOrgIds.map((orgId) => this.updateRelationship('orgUsers', orgId, userId, false))
      return Promise.all(removeOldOrgs)
    }).then(() => {
      const newOrgIds = Object.keys(orgsMap)
      const setNewOrgs = newOrgIds.map((orgId) => this.updateRelationship('orgUsers', orgId, userId, true))
      return Promise.all(setNewOrgs)
    }).then(() => {
      const ref = this.getRef(`/userOrgs/${userId}`)
      return this.replaceRef(ref, orgsMap)
    })
  }

  updateUserOrgRelationship(userId, orgId, isMember) {
    const orgUsers = this.updateRelationship('orgUsers', orgId, userId, isMember)
    const userOrgs = this.updateRelationship('userOrgs', userId, orgId, isMember)
    return Promise.all([orgUsers, userOrgs])
  }

  updateUserRoles(userId, roles) {
    return this.getUserRoles(userId).then(oldRoles => {
      const oldRoleIds = Object.keys(oldRoles).filter(role => !roles[role])
      const removeOldRoles = oldRoleIds.map((role) => this.updateRelationship('roleUsers', role, userId, false))
      return Promise.all(removeOldRoles)
    }).then(() => {
      const newRoleIds = Object.keys(roles)
      const setNewRoles = newRoleIds.map((role) => this.updateRelationship('roleUsers', role, userId, roles[role]))
      return Promise.all(setNewRoles)
    }).then(() => {
      const ref = this.getRef(`/userRoles/${userId}`)
      for (const key of Object.keys(roles)) {
        if (!roles[key]) {
          roles[key] = null
        }
      }
      return this.replaceRef(ref, roles)
    })
  }

  updateRelationship(path, sourceId, relatedId, isRelated) {
    const ref = this.getRef(`${path}/${sourceId}/${relatedId}`)
    const value = isRelated ? true : null
    return this.replaceRef(ref, value)
  }

  setUpGlobalConsumerRole(uid) {
    const roles = {
      [ROLES.CONSUMER]: true
    }
    const ref = this.getRef(`/userRoles/${uid}`)
    return this.updateRef(ref, roles)
  }

  // Helpers

  getOrgRef(orgId) {
    return this.getRef('orgs').child(orgId)
  }

  getUserRef(userId) {
    return this.getRef('users').child(userId)
  }
}
