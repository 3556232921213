import * as React from 'react';
import { Grid, Row } from '@karla/karla-react-components';
import LocationTriggerDetail from './LocationTriggerDetail'
import { URN } from '@karla/karla-core' 
import { connect } from 'react-redux'
import './inspect_recipe.css';
import { getRecipe } from '../../../db/Recipes'

@connect(
  state => ({ 
    user: state.auth,
    tenantId: state.auth.selectedRole.tenantId
}))
class InspectRecipeContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      recipe: null
    }
  }

  componentWillMount() {
    this.loadRecipe(this.props.params)
  }

  componentWillReceiveProps(props) {
    this.loadRecipe(props.params)
  }

  async loadRecipe({ recipeId }) {
    const recipe = await getRecipe(this.props.tenantId, recipeId)
    this.setState({
      recipe
    })
  }

  renderTriggerContent() {
    if (!this.state.recipe) {
      return null
    }
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const clientId = params.get('userId'); 
    switch (this.state.recipe["urn:recipe:trigger"].type) {
      case 'location':
        return (<LocationTriggerDetail userId={this.props.user.uid} clientId={clientId} recipe={this.state.recipe} />)
      default:
        return null;
    }
  }
  
  render() {

    if (!this.state.recipe) {
      return <div id='body' />;
    }
    const recipe = this.state.recipe
    const name = recipe[URN.RECIPE.TITLE]
    
    return (
      <div id='body'>
        <Grid>
          <Row>
            <h1>{name}</h1>
          </Row>
          <Row>
            {this.renderTriggerContent()}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default InspectRecipeContainer