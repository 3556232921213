import _ from 'lodash'

const TYPE_NODE = 1
const TYPE_TEXT = 3

class WhenDoXmlParser {
  parseTypesFromXml(whenDoXml) {
    var parser = new DOMParser()
    var xmlDoc = parser.parseFromString(whenDoXml, 'application/xml')
    var typesArray = this.extractWhenDoInfoFromNodes(xmlDoc.documentElement, [])
    var formattedResult = this.formatResults(typesArray)
    return formattedResult
  }

  formatResults(elementsAndTypes) {
    var whenDoInfo = {
      when: [],
      whenInfo: [],
      doInfo: [],
      do: []
    }
    var parsingDoSection = false
    for (var i = 0; i < elementsAndTypes.length; i++) {
      var type = elementsAndTypes[i].type
      var element = elementsAndTypes[i].element
      if (!parsingDoSection && type.includes('_do')) {
        parsingDoSection = true
      }
      // Triggers
      if (!parsingDoSection) {
        if (element == 'block') {
          if (whenDoInfo.whenInfo[whenDoInfo.when.length - 1]) {
            const el = whenDoInfo.whenInfo[whenDoInfo.when.length - 1]
            el.value = el.value.trim()
          }
          whenDoInfo.whenInfo.push({ type, value: '' })
          whenDoInfo.when.push(type + ' ')
        } else if (!_.isEmpty(type)) {
          if (whenDoInfo.whenInfo[whenDoInfo.when.length - 1])
            whenDoInfo.whenInfo[whenDoInfo.when.length - 1].value += `${type} `
          if (whenDoInfo.when[whenDoInfo.when.length - 1])
            whenDoInfo.when[whenDoInfo.when.length - 1] += type + ' '
        }
      } else {
        // Actions
        if (element == 'block') {
          if (whenDoInfo.doInfo[whenDoInfo.do.length - 1]) {
            const el = whenDoInfo.doInfo[whenDoInfo.do.length - 1]
            el.value = el.value.trim()
          }
          whenDoInfo.doInfo.push({ type, value: '' })
          whenDoInfo.do.push(type + ' ')
        } else if (!_.isEmpty(type)) {
          if (whenDoInfo.doInfo[whenDoInfo.do.length - 1])
            whenDoInfo.doInfo[whenDoInfo.do.length - 1].value += `${type} `
          if (whenDoInfo.do[whenDoInfo.do.length - 1])
            whenDoInfo.do[whenDoInfo.do.length - 1] += type + ' '
        }
      }
    }
    const trimValues = (el) => {
      const { value } = el
      el.value = value.trim()
    }
    whenDoInfo.whenInfo.forEach(trimValues)
    whenDoInfo.doInfo.forEach(trimValues)
    return whenDoInfo
  }

  extractWhenDoInfoFromNodes(node, types) {
    for (var i = 0; i < node.childNodes.length; i++) {
      var thisNode = node.childNodes[i]
      if (thisNode.nodeType == TYPE_NODE) {
        let type = thisNode.getAttribute('type')
        const link = thisNode.getAttribute('link')
        if (thisNode.getAttribute('link'))
          try {
            type = JSON.parse(link)
            if (type.link) delete type.link
            type = JSON.stringify(type)
          } catch (error) {
            type = link
          }
        if (type) {
          types.push({ type: type, element: thisNode.tagName })
        }
        if (thisNode.childNodes.length > 0) {
          this.extractWhenDoInfoFromNodes(thisNode, types)
        }
      } else if (thisNode.nodeType == TYPE_TEXT) {
        types.push({
          type: thisNode.nodeValue.trim(),
          element: thisNode.tagName
        })
      } else {
        break
      }
    }
    return types
  }
}

module.exports.WhenDoXmlParser = WhenDoXmlParser
