import { PROVIDER_LOGIN_SUCCESS } from '../actions/actionTypes';

export default function(state = {}, action) {
  switch (action.type) {
    case PROVIDER_LOGIN_SUCCESS:
      var newState = {}
      newState[action.provider] = action.token
      return Object.assign({}, state, newState);
    default:
      return state;
  }
}
