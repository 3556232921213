import Datastore from './Datastore'
import UserRepository from './UserRepository'
import SpecialistRepository from './SpecialistRepository'
import RecipeRepository from './RecipeRepository'

export function resetAccount(userId) {
  return clearUserData(userId)
}

function clearUserData(userId) {
  const userDataNodes = [
    'metrics',
    'messages',
    'users',
    'readings',
    'lifeContextState',
    'karlaSettings,',
    'keyResponses',
    'keyRequests',
    'visits',
    'whenDoState',
    'userSettings',
    'pki',
    'randomSelections',
    'vaults/one-to-one',
    'contentAssignments',
    'surveyResponses',
    'residence',
    'specialistSessions',
    'devices',
    'sessions',
    'lifeContextGraph/registrationInfo',
    'lifeContextGraph/videoReports',
    'lifeContextGraph/programSurveyResponses',
    'flowState'
  ]
  const datastore = new Datastore()
  const removals = userDataNodes.map(node => {
    const fullPath = `${node}/${userId}`
    const ref = datastore.getRef(fullPath)
    return datastore.deleteRef(ref)
  })
  return settle(removals) 
}

function settle(promises) {
  const settledPromises = promises.map(p => {
    return p.catch((reason) => {
      console.log('caught err: ', reason)
      return undefined
    })
  })
  return Promise.all(settledPromises)
}

function clearUserOrgs(userId) {
  //TODO: update for Firestore / Tenants
  const repo = new UserRepository()
  return repo.updateUserOrgs(userId, {})
}

function clearRecipes(userId) {
  //TODO: update for Firestore / Tenants
  const repo = new RecipeRepository(userId)
  return repo.getRecipesAssignedToUser(userId).then(recipes => {
    const removals = Object.keys(recipes).map(repo.deleteRecipe.bind(repo))
    return settle(removals)
  })
}

function clearTelehealth(userId) {
  //TODO: update for Firestore / Tenants
  const repo = new SpecialistRepository()
  return repo.getSpecialistIdForUser(userId).then(specialistId => {
    const telehealthPath = `telehealth/${specialistId}/clients${userId}`
    const clientPath = `specialistClients/${specialistId}/${userId}`
    const clientChat = `chats/${userId}/${specialistId}`
    const specialistChat = `chats/${specialistId}/${userId}`
    const deletes = [telehealthPath, clientPath, clientChat, specialistChat]
                      .map(repo.getRef.bind(repo))
                      .map(repo.deleteRef.bind(repo))
    return settle(deletes)
  })
}
