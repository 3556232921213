import * as React from 'react'
import { connect } from 'react-redux'
import { Table } from '@karla/karla-react-components'
import { query } from '../../../db/Firestore'
import * as statsHelpers from '../../../common/lib/statsHelpers'

type AggregatedSurveyScores = {
  [survey: string]: {
    [date: string]: number
  }
}

type Props = {
  clientId: string
  tenantId: string
}

type State = {
  surveyScores: AggregatedSurveyScores
}

type SurveyScore = {
  score: number
  specialistId: string
  timestamp: number
  userId: string
  surveyDesc: string
}

function mapStateToProperties(state) {
  const { auth } = state
  return {
    tenantId: auth.selectedRole.tenantId
  }
}
class ContentAssignmentList extends React.Component {
  readonly state: State

  readonly props: Props

  constructor(properties) {
    super(properties)

    this.state = {
      surveyScores: {}
    }
  }

  componentDidMount() {
    this.getScores().then((surveyScores) => this.setState({ surveyScores }))
  }

  shouldComponentUpdate(
    { clientId, tenantId }: Props,
    { surveyScores }: State
  ) {
    return (
      this.props.clientId !== clientId ||
      this.props.tenantId !== tenantId ||
      (Object.keys(surveyScores).length > 0 &&
        !Object.keys(this.state.surveyScores).length)
    )
  }

  componentWillUpdate() {
    this.getScores().then((surveyScores) => this.setState({ surveyScores }))
  }

  async getScores() {
    const scoresReference = await query(
      `/userSurveyScoresStats/${this.props.tenantId}/${this.props.clientId}`
    ).get()

    const surveyScoreDocuments = scoresReference.docs

    return surveyScoreDocuments.reduce<AggregatedSurveyScores>(
      (aggregatedScores, score) => {
        const scoreData = score.data() as SurveyScore
        const scoreDate = new Date(
          scoreData.timestamp * 1000
        ).toLocaleDateString()
        const accumulated = aggregatedScores[scoreData.surveyDesc] || {}
        if (accumulated[scoreDate]) {
          accumulated[scoreDate] =
            (accumulated[scoreDate] + scoreData.score) / 2
        } else {
          accumulated[scoreDate] = scoreData.score
        }
        aggregatedScores[scoreData.surveyDesc] = accumulated
        return aggregatedScores
      },
      {}
    )
  }

  renderScore() {
    const scoreData = this.state.surveyScores

    if (!scoreData) {
      return
    }
    return Object.keys(scoreData).map((surveyName) => {
      const surveyVals = scoreData[surveyName]

      const surveyDates = Object.keys(surveyVals)
      const surveyResults = Object.values(surveyVals)

      const mean = statsHelpers.average(surveyResults).toFixed(2)
      const median = statsHelpers.median(surveyResults).toFixed(2)
      return (
        <Table striped key={surveyName}>
          <thead>
            <tr>
              <th>Survey: {surveyName}</th>
              {surveyDates.slice(-3).map((date) => (
                <th key={`${surveyName}${date}`}>{date}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Scores:</td>
              {surveyResults.slice(-3).map((score, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <td key={`${surveyName}${score}-${index}`}>
                  {typeof score === 'number' ? score.toFixed(2) : score}
                </td>
              ))}
            </tr>
            <tr>
              <td colSpan={4}>
                {!Number.isNaN(Number(mean)) &&
                !Number.isNaN(Number(median)) &&
                surveyResults.length > 1 ? (
                  <span>
                    Mean: {mean}
                    <br />
                    Median: {median}
                  </span>
                ) : undefined}
              </td>
            </tr>
          </tbody>
        </Table>
      )
    })
  }

  render() {
    if (Object.keys(this.state.surveyScores).length === 0) {
      // eslint-disable-next-line unicorn/no-null
      return null
    }
    return (
      <div className="row">
        <div className="col-xs-12">
          <p>Survey Score Stats</p>
          {this.renderScore()}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProperties)(ContentAssignmentList)
