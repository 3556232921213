/* @ts-ignore */
import React from 'react'
import './init'
import routes from './routes'
import render, * as reduxRender from './redux/render'
import reducers from './redux/reducers'

import './index.scss'

global.IS_PRODUCTION = false

reduxRender.setupReducers(reducers)

reduxRender.default(routes, () => {
  // ready
})

/* @ts-ignore */
if (module.hot) {
  /* @ts-ignore */
  module.hot.accept('./routes', () => {
    // reload routes again
    require('./routes').default
    render(routes)
  })
}
