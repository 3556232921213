import moment from 'moment'

// Years

export function getStartOfYear(date) {
  return new Date(date.getFullYear(), 0, 1)
}

export function getStartOfLastYear(date) {
  const lastYear = date.getFullYear() - 1
  return new Date(lastYear, 0, 1)
}

// Quarters

export function getQuarterForDate(date) {
  const month = date.getMonth()
  let quarter
  if (month < 3) {
    quarter = 0
  } else if (month < 6) {
    quarter = 1
  } else if (month < 9) {
    quarter = 2
  } else if (month < 12) {
    quarter = 3
  }
  return quarter
}

export function getStartOfQuarter(date) {
  const thisQuarter = getQuarterForDate(date)
  return new Date(date.getFullYear(), thisQuarter * 3, 1)
}

export function getStartOfLastQuarter(date) {
  const thisQuarter = getQuarterForDate(date)
  let lastQuarterYear = date.getFullYear()
  let lastQuarter
  if (thisQuarter > 0) {
    lastQuarter = thisQuarter - 1
  } else {
    lastQuarter = 3
    lastQuarterYear -= 1
  }
  const lastQuarterMonth = lastQuarter * 3
  return new Date(lastQuarterYear, lastQuarterMonth, 1)
}

export function getMonthsForQuarter(quarter: number) {
  const startMonth = quarter * 3
  const months: number[] = []
  for (let i = 0; i < 3; i++) {
    months.push(startMonth + i)
  }
  return months
}

// Months

export function getStartOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

export function getStartOfLastMonth(date) {
  let lastMonthYear = date.getFullYear()
  let lastMonth = date.getMonth() - 1
  if (lastMonth < 0) {
    lastMonth = 11
    lastMonthYear -= 1
  }
  return new Date(lastMonthYear, lastMonth, 1)
}

export function getMonthName(month) {
  const date = new Date()
  date.setMonth(month)
  return moment(date).format('MMM')
}

// Weeks

export function getStartOfWeek(date) {
  const dayOfWeek = date.getDay()
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - dayOfWeek
  )
}

export function getStartOfLastWeek(date) {
  const dayOfWeek = date.getDay()
  const offset = dayOfWeek + 7
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - offset)
}

export function getShortWeekdayName(date) {
  return moment(date).format('dddd')
}

export function getSecondsTimeStamp(date: Date | number = new Date()): number {
  // date is already a timestamp
  if (!(date instanceof Date) && typeof date === 'number') {
    if (new Date(date).getFullYear() > 2000) {
      return Math.trunc(date / 1000)
    }
    return date
  }
  return Math.trunc(date.getTime() / 1000)
}
