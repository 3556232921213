import * as React from 'react';


export default class CloseButton extends React.Component {
  
  render() {
    return (
      <img src="/imgs/icons/x-button.svg" className="close-btn hover-pointer" {...this.props}/>
    )
  }
}
