import * as React from 'react'
import classNames from 'classnames'

export default class OrgUserRow extends React.Component {

  static propTypes = {
    name: React.PropTypes.string.isRequired,
    uid: React.PropTypes.string.isRequired,
    role: React.PropTypes.string.isRequired,
    orgs: React.PropTypes.arrayOf(React.PropTypes.string),
    isSelected: React.PropTypes.bool,
    recentActivity: React.PropTypes.string,
    handleSelection: React.PropTypes.func.isRequired,
    handleClick: React.PropTypes.func.isRequired
  };

  render() {

    let buttonClasses = classNames({
      "selection-button": true,
      "active": this.props.isSelected
    })

    return (
      <tr onClick={this.props.handleClick}>
        <td>
          {this.props.name}
        </td>
        <td>
          {this.props.uid}
        </td>
        <td>
          {this.props.role}
        </td>
        <td>
          {this.props.orgs.join(', ')}
        </td>
        <td>
          {this.props.recentActivity}
        </td>
      </tr>
    )
  }
}
