import * as React from 'react';
import { FormControl, Button } from '@karla/karla-react-components';
import { connect } from 'react-redux'
import { applySMSAuthCode, resendSMSCode } from '~/redux/actions/smsAuth'
import { logout } from '../../redux/actions/login'
import { DropdownButton, Modal } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'

const mapDispatchToProps = (dispatch) => {
  return {
    verifySMSCode: (code) =>  dispatch(applySMSAuthCode(code)),
    resendCode: () => dispatch(resendSMSCode()),
    logout: () => dispatch(logout())
  }
}

const mapStateToProps = (state) => {
  return {auth: state.auth}
}

@connect(
  mapStateToProps,
  mapDispatchToProps)
export default class extends React.Component {
  
  static propTypes = {
    verifySMSCode: React.PropTypes.func,
    isCreatingAccount: React.PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = { 
      code: '', 
      isProcessing: false 
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      isProcessing: !props.auth.showOnboardingError
    })
  }

  handleCodeInput(event) {
    this.props.auth.showOnboardingError = false
    this.setState({
      code: event.target.value
    });
  }

  submit(event) {
    this.setState({
      isProcessing: true
    })
    const code = this.state.code;
    this.props.verifySMSCode(code)
  }

  formatPhoneNumber(phoneNumber) {

    if (phoneNumber && phoneNumber.length == 12) {

      let areaCode = phoneNumber.substring(2, 5)
      let firstGroup = phoneNumber.substring(5, 8)
      let secondGroup = phoneNumber.substring(8, 12)

      return `(${areaCode}) ${firstGroup}-${secondGroup}`

    } else {

      return phoneNumber 
    }
  }
  
  renderTerms() {
    return (
      <div className="onboarding-text terms-footer">
        <div>
          By creating a Synchronous Health account, I agree to the service's <a href="http://synchronoushealth.co/terms-of-use">terms and conditions</a> as well as the <a href="http://synchronoushealth.co/privacy-policy">privacy policy</a>.
        </div>
      </div>
    )
  }
  
  componentDidMount() {
    this.unregisterNavListener = browserHistory.listenBefore(this.routerWillLeave.bind(this))
  }
  
  componentWillUnmount() {
    if (this.unregisterNavListener) {
      this.unregisterNavListener()
    }
  }
  
  routerWillLeave(location) {
    if (this.props.auth.keyRequest) {
      // if the user tries to navigate without obtaining the key, log them out
      this.props.logout()
    }
  }
  
  renderKeyRequestPopover() {
    const keyRequest = this.props.auth.keyRequest
    if (!keyRequest) {
      return null
    }
    return (
      <Modal id="key-request-modal" show={true}>
        <Modal.Body>
          <h1>Link your Encrypted Mobile Data to this Account</h1>
          <div id="key-request-body">Open the Karla app on your phone and enter the PIN number shown below. </div>
          <div id="pin">{keyRequest.pin}</div>
        </Modal.Body>
      </Modal>
    )
  }

  renderOnboardingError() {
    return (
      <div className='onboarding-error-container onboarding-error'>
        Your code is incorrect
      </div>
    )
  }

  render() {

    let phoneNumber = this.formatPhoneNumber(this.props.auth.phoneNumber)

    const submitEnabled = !this.state.isProcessing;
    const submitButtonText = submitEnabled ? "Confirm Code" : "Confirming...";
    
    return (
      <div>
        {this.renderKeyRequestPopover()}
        <div className='onboarding-text onboarding-subtitle'>
          We just sent a text message with a verification code to <b>{phoneNumber}</b>. <br /> 
          Didn’t get the code? <a onClick={this.props.resendCode}>Resend</a>
        </div>
        <div className='form-container onboarding-text'>
          <div className='field-container'>
            {this.props.auth.showOnboardingError ? this.renderOnboardingError() : null}
            <span className={this.props.auth.showOnboardingError ? 'onboarding-error' : ''}>Enter the Code</span>
            <br />
            <FormControl placeholder='000000' value={this.state.code} onChange={this.handleCodeInput.bind(this)} className='sh-form-control'/>
          </div>
        </div>
        <br />
        <Button id='send-text-btn' disabled={!submitEnabled} className='sh-btn' onClick={this.submit.bind(this)}>{submitButtonText}</Button>
        {this.renderTerms()}
      </div>
    );
  }
}
