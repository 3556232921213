export function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export function shortenToWords(
  text: string,
  maxLength: number = 20,
  separator = ' '
) {
  try {
    if (text.length <= maxLength) {
      return text
    }
    return `${text.slice(0, text.lastIndexOf(separator, maxLength))}... `
  } catch (error) {
    console.warn(error)
    return text
  }
}
