import * as React from 'react';
import { Table } from '@karla/karla-react-components';
import LogRepository from '~/db/LogRepository'
import moment from 'moment'
import { objectFromArray } from '~/common/lib/object_helpers'
import { URN } from '@karla/karla-core'
import { SHConversationRowHeader } from '@karla/karla-react-components'

const OPTIONS = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };

export class KarlaLogContainer extends React.Component {

  constructor(props) {
    super(props)
    this.logRepo = new LogRepository()
    this.state = {
      items: []
    }
    this.getLogs(props.clientId)
  }



  componentWillReceiveProps(props) {
    this.setState({
      items: []
    })
    if (this.logRef) this.logRepo.unbind(this.logRef);
    setTimeout(() => this.getLogs(props.clientId), 0)
  }


  componentWillUnmount() {
    this.logRepo.unbind(this.logRef)
  }

  getLogs(clientId) {
    this.logRef = this.logRepo.getLogs(clientId, this);
  }

  renderDate(date, prevDate) {
    if ((!prevDate && date) || (date && (date - prevDate) / (1000 * 60) > 1))
      return (
        <h6 className="sh-conversation-row-header text-center" >
          {moment(date).fromNow()}, {date.toLocaleDateString('en-US', OPTIONS)} {date.toLocaleTimeString()}
        </h6>
      )
    else return null;
  }

  renderLogs() {
    let lastDate = new Date(0)

    return this.state.items.map(item => {
      return item.entries.map((entry, entryIndex) => {

        const entryDate = entry.timestamp ? new Date(entry.timestamp * 1000) : null
        const message = entry.message || ""

        return message.replace(/^\n+|\n+$/gm, '').split('\n').map((text, idx) => {
          const prevDate = lastDate
          lastDate = entryDate
          return (
            <div key={entryIndex + "." + idx}>
              {this.renderDate(entryDate, prevDate)}
              {moment(entryDate).format("MM/DD/YYYY h:mm:ss a")}: {text}<br />
            </div>
          )
        });
      })
    })
  }

  render() {
    if (!this.props.isVisible) {
      return null;
    }
    return (
      <div className="container-body">
        <div className="dashboard-panel-header">Device Log</div>
        <div className='container-body'>
          {this.renderLogs()}
        </div>
      </div>
    )
  }
}
