
import { User, getUser } from './Users'
import { getDoc, query } from './Firestore';
import SpecialistRepository from './SpecialistRepository';
import firebase from 'firebase/app';
import { successes } from '../util/promise_utils';

const firestore = firebase.firestore()

export async function getUserSpecialist(userId: string, tenantId: string): Promise<User | null> {
  const assignments = await getDoc(`clientSpecialist/${tenantId}/users/${userId}`)
  if (!assignments) {
    console.log("No assignments")
    return null
  }
  let specialistId: string | null = null
  for (const id of Object.keys(assignments.data)) {
    if (assignments.data[id] === true) {
      specialistId = id
    }
  }
  if (!specialistId) {
    return null
  }
  try {
    return await getUser(specialistId)
  } catch (err) {
    console.error("Error getting user specialist", err)
    return null
  }
}

async function getUserSpecialistId(userId: string, tenantId: string): Promise<string | null> {
  const assignments = await getDoc(`clientSpecialist/${tenantId}/users/${userId}`)
  if (!assignments) {
    console.log("No assignments")
    return null
  }
  let specialistId: string | null = null
  for (const id of Object.keys(assignments.data)) {
    if (assignments.data[id] === true) {
      specialistId = id
    }
  }
  return specialistId
}

export async function getAvailableSpecialists(tenantId: string): Promise<User[]> {
  try {
    const specialistRoles = await query(`rolesByTenant/${tenantId}/roles/`)
      .where("specialist", "==", true).get()
    const getSpecialists = specialistRoles.docs.map((doc) => {
      const role = doc.data()
      return getUser(role.userId)
    })
    return successes(getSpecialists)
  } catch (err) {
    console.log(`Error getting available specialists ${err}`)
    return []
  }
}

export async function getTenantFullInfo(tenantId: string): Promise<any> {
  try {

    const snapshot = await tenantFullInfo(tenantId).get()
    if (snapshot.exists) {
      return snapshot.data()
    }

  } catch (err) {
    console.error(`Error getting tenant full info ${err}`)
    return []
  }
}

export type ClientsListener = (clients: User[]) => void;

export type Unsubscribe = () => void;

export function subscribeToTelehealthClients(specialistId: string, tenantId: string, listener: ClientsListener): Unsubscribe {
  const unsubscribe = firestore.doc(`specialistClients/${tenantId}/specialists/${specialistId}`)
    .onSnapshot(async (doc) => {
      if (!doc.exists) {
        listener([])
        return
      }
      const assignments = doc.data() as object
      const userIds = Object.keys(assignments).filter(uid => {
        return assignments[uid] === true
      })
      const getUsers = userIds.map(getUser)
      const users = await successes(getUsers)
      listener(users)
    })

  return unsubscribe
}

export async function getSpecialistClientIds(tenantId: string, specialistId: string): Promise<string[]> {
  const snapshot = await firestore.doc(`specialistClients/${tenantId}/specialists/${specialistId}`).get()
  const data = snapshot.data()
  if (!data) {
    return []
  }
  return Object.keys(data)
}

export async function getSpecialistClients(tenantId: string, specialistId: string): Promise<User[]> {
  const userIds = await getSpecialistClientIds(tenantId, specialistId)
  const getUsers = userIds.map(uid => getUser(uid).catch(err => {
        console.error("Error getting client user " + uid)
        throw err
      }))
  return Promise.successes(getUsers)
}

export async function assignUserSpecialist(userId: string, tenantId: string, specialistId: string) {

  await deleteUserSpecialist(userId, tenantId)

  const updateClient = firestore
    .doc(`clientSpecialist/${tenantId}/users/${userId}`)
    .set({
      [specialistId]: true
  }).catch((err) => {
    console.error(`Error assigning clientSpecialist ${err}`)
    throw err
  })

  const updateSpecialist = firestore
    .doc(`specialistClients/${tenantId}/specialists/${specialistId}`)
    .set({
      [userId]: true
    }, { merge: true }).catch((err) => {
      console.error(`Error assigning specialistClients ${err}`)
      throw err
    })
    
  return Promise.all([
    updateClient, 
    updateSpecialist,
    saveSpecialistToFirebase(userId, specialistId) //specialist assignment must be duplicated in Firestore and Firebase until all data has migrated to Firestore
  ])
}

export async function deleteUserSpecialist(userId: string, tenantId: string) {

  const currentSpecialistId = await getUserSpecialistId(userId, tenantId)
  if (!currentSpecialistId) {
    return
  }
  console.log(`Removing specialist from user ${userId}. Previous ${currentSpecialistId}`)
  const updateClient = firestore
    .doc(`clientSpecialist/${tenantId}/users/${userId}`)
    .set({})
    .catch((err) => {
    console.error(`Error assigning clientSpecialist ${err}`)
    throw err
  })

  const updateSpecialist = firestore
    .doc(`specialistClients/${tenantId}/specialists/${currentSpecialistId}`)
    .set({
      [userId]: firebase.firestore.FieldValue.delete()
    }, { merge: true }).catch((err) => {
      console.error(`Error assigning specialistClients ${err}`)
      throw err
    })
    
  return Promise.all([
    updateClient, 
    updateSpecialist,
    saveSpecialistToFirebase(userId, currentSpecialistId)
  ])
}

async function saveSpecialistToFirebase(userId, assignedSpecialistId): Promise<any> {
  const specialistRepo = new SpecialistRepository()
  return specialistRepo.assignUserToSpecialist(userId, assignedSpecialistId)
}

function tenantFullInfo(tenantId: string): firebase.firestore.DocumentReference {
  return firestore.doc(`tenants/${tenantId}`)
}