import * as React from 'react'
import SurveyResponseModal from './SurveyResponseModal'

export default class MultipleChoiceResponseModal extends React.Component {
  
  static propTypes = {
    response: React.PropTypes.object.isRequired,
    assignment: React.PropTypes.object.isRequired,
        onHide: React.PropTypes.func.isRequired
  };
  
  render() {
    const { response, assignment } = this.props
    return (
      <SurveyResponseModal name={assignment.name} {...this.props}>
        <div>
          {'Total Score: ' + response.answers.reduce((prev, answer) => {
            return prev + answer.value || 0
          }, 0)}
        </div>
        {response.answers.map((answer, i) => {
          return (
            <div key={i}>
              <div>
                {`#${i + 1}. ${response.questions ? response.questions : '-'}`}
              </div>
              <div>
                {`${answer.answer} ${answer.value || ''}`}
              </div>
            </div>
          )
        })}
      </SurveyResponseModal>
    )
  }
}
