import firebase from 'firebase/app'

const dialogPath = '/packages/lib-recharge/latestMetadata'
const dialogPath2 = 'packages'
const firestore = firebase.firestore()

export type Dialog = {
  dialog: string
  scoring: string
  keywords: string
  version: string
  schema: string
  type: string
  description: string
  originalSource: string
}

class DialogRepository {
  async getAllDialogs(): Promise<Dialog[]> {
    try {
      const dialogsCollection = await firestore
        .collection(dialogPath2)
        .get({ source: 'server' })
      const dialogs: Dialog[] = []
      const dialogPromise = Promise.all(
        dialogsCollection.docs.map(async (snapshot) => {
          const dCollection = await firestore
            .collection(`${dialogPath2}/${snapshot.id}/latestMetadata`)
            .get()
          return dCollection.docs.forEach((document_) => {
            const data = document_.data()
            data.dialog = document_.id as String
            data.sourcePackage = snapshot.id
            dialogs.push(data as Dialog)
          })
        })
      )
      await dialogPromise
      return dialogs
    } catch (error) {
      console.error('Error fetching dialogs', error)
      throw error
    }
  }
}

export async function getQuestions(assignment) {
  const assignmentPath = `${dialogPath}/${assignment.dialog}`
  try {
    const snapshot = await firestore.doc(assignmentPath).get()
    if (snapshot.exists) {
      return snapshot.data()?.questions
    }
    return []
  } catch (error) {
    console.error('There was an error getting the questions', error)
  }
}

export default DialogRepository
