import * as React from 'react';
import { Modal } from 'react-bootstrap'

export class RecipeDeleteModal extends React.Component {

  static propTypes = {
    name: React.PropTypes.string.isRequired,
    assignedTo: React.PropTypes.string.isRequired,
    deleteConfirmed: React.PropTypes.func.isRequired,
    onHide: React.PropTypes.func.isRequired
  };

  render() {
    return (
      <Modal className="sh-modal dark-modal"
        show={true}
        backdrop={true}
        backdropClassName="modal-shadow-background"
        onHide={this.props.onHide}>
        <img src="/imgs/icons/x-button.svg" className="close-btn hover-pointer" onClick={this.props.onHide} />
        <Modal.Body>
          <p>You're about to delete the <b> {this.props.name} </b> Flow which is assigned to {this.props.assignedTo}.<br /><br />
            Are you sure you want to delete this Flow?</p>
          <button className="sh-btn btn-default" onClick={this.props.onHide}>Cancel</button>
          <button className="sh-btn danger" onClick={this.props.deleteConfirmed}>Delete</button><br />
        </Modal.Body>
      </Modal>
    )
  }
}
