// https://betterprogramming.pub/you-dont-have-to-lose-optimization-for-micro-frontends-60a63d5f94fe
import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import { AuthManager } from '../../../common/lib/AuthManager'

@connect((state) => ({
  user: state.auth,
  tenantId: state.auth.selectedRole.tenantId
}))
class MicroFrontend extends React.Component {
  componentDidMount() {
    const { name, host, document, loadedCallback } = this.props
    const authManager = new AuthManager()

    let headers = []
    let authQuery = ''
    const scriptId = `micro-frontend-script-${name}`

    if (document.getElementById(scriptId)) {
      this.renderMicroFrontend()
      return
    }

    authManager
      .getFirebaseToken(this.props.tenantId)
      .then((credentials) => {
        authQuery = `?token=${credentials.token}`
        return axios.get(`${host}/asset-manifest.json${authQuery}`)
      })
      .then((res) => Promise.all([res.data, res]))
      .then(([manifest, res]) => {
        const promises = Object.keys(manifest['files'])
          .filter((key) => key.endsWith('.js') || key.endsWith('.css'))
          .reduce((sum, key) => {
            sum.push(
              key.endsWith('.js')
                ? new Promise(async (resolve) => {
                    const filePath = manifest['files'][key]
                    let path = `${host}${filePath}`
                    if (filePath.startsWith('http')) {
                      path = filePath
                    }
                    axios.get(path + authQuery).then((response) => {
                      const { data } = response
                      if (!data) return
                      const script = document.createElement('script')

                      headers.push(script)
                      script.text = data
                      script.type = 'text/javascript'

                      script.crossOrigin = 'anonymous'
                      document.head.appendChild(script)
                      setTimeout(() => resolve(script), 0)
                    })
                  })
                : new Promise((resolve) => {
                    const filePath = manifest['files'][key]
                    let path = `${host}${filePath}`
                    if (filePath.startsWith('http')) {
                      path = filePath
                    }
                    axios.get(path + authQuery).then((response) => {
                      const { data: css } = response

                      var styles = document.createElement('style')
                      styles.setAttribute('type', 'text/css')
                      styles.appendChild(document.createTextNode(css))
                      styles.onload = () => {
                        headers.push(styles)

                        resolve(styles)
                      }
                      document.head.appendChild(styles)
                    })
                  })
            )
            return sum
          }, [])
        Promise.allSettled(promises).then(() => {
          if(loadedCallback) loadedCallback(true)
          this.setState({ headers })
          this.renderMicroFrontend()
        })
      })
  }

  componentWillUnmount() {
    const { name, window } = this.props

    window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`)
    const { headers } = this.state
    headers.forEach((header) => document.head.removeChild(header))
  }

  renderMicroFrontend = () => {
    const { name, window, history } = this.props
    const authManager = new AuthManager()

    window[`render${name}`] &&
      // Modified to be able to pass any props to the component
      window[`render${name}`](`${name}-container`, {
        history,
        credentials: 'include',
        AuthManager: authManager,
        ...this.props
      })
  }

  render() {
    return <main id={`${this.props.name}-container`} />
  }
}

MicroFrontend.defaultProps = {
  document,
  window
}

export default MicroFrontend
