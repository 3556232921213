import * as React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import SpecialistRepository from '~/db/SpecialistRepository'

function mapStateToProps(state) {
  return { user: state.auth }
}

@connect(mapStateToProps)
export default class ShowNotes extends React.Component {

  static contextTypes = {
    router: React.PropTypes.object
  };

  constructor(props) {
    super(props)
    this.specialistRepo = new SpecialistRepository()
    this.state = {
      notes: []
    }
  }

  componentDidMount() {
    this.fetchNotes(this.props)
  }

  componentWillReceiveProps(props) {
    this.fetchNotes(props)
  }

  fetchNotes({ user, clientId }) {
    const specialistId = user.uid
    this.ref = this.specialistRepo.bindToNotes(specialistId, clientId, {
      context: this,
      state: 'notes',
      asArray: true
    })
  }

  componentWillUnmount() {
    this.specialistRepo.unbind(this.ref)
  }

  renderNotes() {
    let notes = this.state.notes || []
    notes.reverse()
    return notes.map((note, idx) => {
      let date
      if (note.date > 10000000000) { //year 2302 in seconds, assuming this is milliseconds...
        date = note.date
      } else {
        date = note.date * 1000
      }
      const time = moment(date).format('dddd, MMMM Do — h:mm a')
      const noteText = note.note || ''
      let lines = note.lines || noteText.split("\n")
      return (
        <div key={idx}>
          <div className='time-stamp'>{time}</div>
          <div>{lines.map((line, i) => {
            return <div key={idx + "-" + i}>
              {line}
              <br />
            </div>
          })}</div>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <div className='dashboard-panel-header client-panel-header'>Past Notes</div>
        <div className="notes-list">
          {this.renderNotes()}
        </div>
      </div>
    );
  }
}
