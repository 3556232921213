import { Keychain } from '~/common/lib/Keychain'

let cache = {}

export default class DecryptionCache {

  constructor(userId) {
    this.keychain = new Keychain(userId)
  }

  findOrDecrypt(receiverId, key, encryptedValue) {
    const userKey = `${receiverId}:${key}`
    if (cache[userKey]) {
      return Promise.resolve(cache[userKey])
    }
    return this.keychain.decryptObject(encryptedValue, receiverId).then(decryptedValue => {
      decryptedValue.id = encryptedValue.id
      cache[userKey] = decryptedValue
      return decryptedValue
    })
  }

  clearCache() {
    cache = {}
  }
}
