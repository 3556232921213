import { CONFIG } from '~/config'
import { LOGIN_ERROR, SMS_LOGIN_ATTEMPT } from './actionTypes/authActions'
import { loginError, providerLoginSuccess } from './login'
import { browserHistory } from 'react-router'

export function startSMSLogin(phoneNumber) {
  return (dispatch, getState) => {
    dispatch({ type: SMS_LOGIN_ATTEMPT, phoneNumber })
    startPasswordlessAuth(phoneNumber)
    browserHistory.push('/sms-confirm')
  }
}

export function resendSMSCode() {
  return (dispatch, getState) => {
    const phone = getState().auth.phoneNumber
    startPasswordlessAuth(phone)
  }
}

export function applySMSAuthCode(code) {
  return (dispatch, getState) => {
    const phone = getState().auth.phoneNumber
    verifySMSCode(code, phone).then(result => {
      dispatch(providerLoginSuccess(CONFIG.AUTH0.AUTH0_DOMAIN, result.idToken, result.refreshToken))
    }, err => {
      dispatch(loginError(err))
    })
  }
}

function startPasswordlessAuth(phoneNumber = "", callback) {

  var auth0 = new Auth0({
    domain:       CONFIG.AUTH0.AUTH0_DOMAIN,
    clientID:     CONFIG.AUTH0.CLIENT_ID
  });

  const body = { 'phoneNumber': phoneNumber }
      
  auth0.requestSMSCode(body, err => {
    if (callback) callback(err)
  });
}

function verifySMSCode(authCode = "", phoneNumber = "") {

  var auth0 = new Auth0({
    domain:       CONFIG.AUTH0.AUTH0_DOMAIN,
    clientID:     CONFIG.AUTH0.CLIENT_ID
  })

  const body = {
    phoneNumber: phoneNumber,
    code: authCode,
    scope: 'openid offline_access'
  }

  return new Promise((resolve, reject) => {
    auth0.verifySMSCode(body, (err, result) => {
      if (err) { 
        console.log(`error with sms code: ${err}`)
        reject(err) 
      } else {
        resolve(result)
      }
    })
	})
}
