import { CREATE_NOTE, FETCH_NOTES } from '../actions/actionTypes/telehealthActions';

export default function(state = [], action) {
  switch (action.type) {
    case CREATE_NOTE:
      return { ...state, note: action.payload };
    case FETCH_NOTES:
      return { ...state, notes: action.payload };
    default:
      return state;
  }
}
