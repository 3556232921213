import * as React from 'react';
import { Grid, Row, Col } from '@karla/karla-react-components';


export default class extends React.Component {
  
  render() {
    return (
      <div id='body'>
        <Grid>
          <Row>
            <Col md={4}>
              <h1>WhenDo Flow Library</h1>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}