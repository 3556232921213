import * as React from 'react';
export default class extends React.Component {

  render() {

    return (
      <h1>Create Account</h1>
    );
  }
}
