import * as React from 'react'
import {
  MenuItem,
  NavbarHeader,
  ButtonToolbar,
  Grid,
  Row,
  Col,
  Navbar,
  SidebarBtn
} from '@karla/karla-react-components'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { Dropdown } from 'react-bootstrap'
import { changeAuthRole } from '../redux/actions/login'

import { observeUser } from '../db/Users'
import Spinner from './ui/Spinner'

const mapStateToProps = (state) => {
  return { auth: state.auth }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAuthRole: (role) => {
      dispatch(changeAuthRole(role))
    }
  }
}

class Brand extends React.Component {
  onRoleSelection(role) {
    this.props.updateAuthRole(role)
  }

  renderDropdownMenu() {
    const roles = this.props.auth.availableRoles || []

    const rolesByTenant = roles.reduce((map, role) => {
      let roles = map[role.tenantName]
      if (!roles) {
        roles = []
        map[role.tenantName] = roles
      }
      roles.push(role)
      return map
    }, {})

    return Object.keys(rolesByTenant).map((tenantName) => {
      const items = rolesByTenant[tenantName].map((tenantRole) => {
        const id = `tenant-${tenantRole.tenantId}-${tenantRole.role}`
        return (
          <MenuItem
            eventKey={id}
            onClick={this.onRoleSelection.bind(this, tenantRole)}
          >
            {tenantRole.role.capitalize()}
          </MenuItem>
        )
      })
      return [<MenuItem header>{tenantName}</MenuItem>, ...items]
    })
  }

  render() {
    const { selectedRole } = this.props.auth
    if (!selectedRole) {
      return null
    }
    const orgName = selectedRole.tenantName
    const { role } = selectedRole
    const roleText = role ? `Viewing as ${role.capitalize()}` : ''
    return (
      <NavbarHeader className={this.props.className}>
        <div className="auth-role-container">
          <ButtonToolbar>
            <Dropdown id="role-dropdown-toggle">
              <Dropdown.Toggle>
                <span className="auth-org-name">{orgName}</span>
                <br />
                <span className="auth-org-role">{roleText}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>{[this.renderDropdownMenu()]}</Dropdown.Menu>
            </Dropdown>
          </ButtonToolbar>
        </div>
      </NavbarHeader>
    )
  }
}

class HeaderNavigation extends React.Component {
  static contextTypes = {
    router: React.PropTypes.object.isRequired
  }

  componentDidMount() {
    this.subscription = observeUser(this.props.auth.uid).subscribe((user) => {
      this.setState({
        user
      })
    })
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  render() {
    const props = {
      ...this.props,
      className: classNames('pull-right', this.props.className)
    }

    let userName = ''
    let avatarImage
    if (this.state && this.state.user) {
      userName = this.state.user.fullName
      const avatarUrl = this.state.user.avatar
      if (avatarUrl) {
        avatarImage = <img className="img-circle" src={avatarUrl} />
      }
    }

    return (
      <NavbarHeader className={props.className}>
        <Spinner>
          <div className="navbar-user-info-wrapper">
            {userName}
            {avatarImage}
          </div>
        </Spinner>
      </NavbarHeader>
    )
  }
}

function Header(props) {
  return (
    <Grid id="navbar">
      <Row>
        <Col xs={12}>
          <Navbar fixedTop id="rubix-nav-header" fluid>
            <div>
              <Row>
                <Col xs={3} visible="xs">
                  <SidebarBtn />
                </Col>
                <Col xs={6} sm={4}>
                  <Brand {...props} />
                </Col>
                <Col xs={3} sm={8}>
                  <HeaderNavigation {...props} />
                </Col>
              </Row>
            </div>
          </Navbar>
        </Col>
      </Row>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
