import * as React from 'react';
import { FormGroup, Grid, Col, Form } from '@karla/karla-react-components';
import { ORGS } from '@karla/karla-core'
import { connect } from 'react-redux'
import { ControlLabel } from 'react-bootstrap'
import FormField from './editForm'
import UserOrgForm from './userOrgForm'
import { browserHistory } from 'react-router'
import Autocomplete from 'react-autocomplete'
import { resetAccount}  from '../../db/ResetAccount'
import ConfirmModal from './confirmModal'
import { getUserRoles, updateUserRoles, Roles } from '../../db/Roles'
import { getUserSpecialist, getAvailableSpecialists, assignUserSpecialist, deleteUserSpecialist } from '../../db/Telehealth'
import { getUser, updateUser } from '../../db/Users';
import AddUserOrgModal from './AddOrgModal';
import { getTenantOrgsById, TenantOrganization, updateUserTenantOrgs, getUserTenantOrgs } from '../../db/Orgs';
import UserRepository from '../../db/UserRepository';
import SpecialistRepository from '../../db/SpecialistRepository';
import SearchClient from '../../db/SearchClient';

type Props = {
  userId: string
  tenantId: string
  tenantName: string
}

type State = {
  userName: string
  edittedUser: any
  userOrgs: {[id: string]: boolean}
  orgs: {[id: string]: TenantOrganization}
  showAddOrgModal: boolean
  userRoles: Roles
  specialistId: string | null
  specialistSearch: string
  allSpecialists: any[]
  filteredSpecialists: any[]
  resetAccountId: string | null
}

const mapStateToProps = state => {
  return {
    tenantName: state.auth.selectedRole.tenantName,
    tenantId: state.auth.selectedRole.tenantId 
  }
}

@connect(
  mapStateToProps,
  () => ({})
)
class UserEditForm extends React.Component<Props, State> {

  usersRepo: UserRepository
  specialistRepo: SpecialistRepository
  searchClient: SearchClient

  constructor(props) {
    super(props)
    this.state = {
      userName: "",
      edittedUser: {},
      userOrgs: {},
      orgs: {},
      showAddOrgModal: false,
      userRoles: {admin: false, specialist: false, consumer: false, demo: false},
      specialistId: null,
      specialistSearch: "",
      allSpecialists: [],
      filteredSpecialists: [],
      resetAccountId: null
    }
    this.usersRepo = new UserRepository()
    this.specialistRepo = new SpecialistRepository()
    this.searchClient = new SearchClient()
  }

  componentDidMount() {
    this.reload()
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId != prevProps.userId) {
      this.reload()
    }
  }

  reload() {
    const { userId, tenantId } = this.props
    this.loadUser(userId, tenantId)
    this.getRoles(userId)
    this.getOrgs(tenantId)
    this.getSpecialistInfo(userId)
  }

  async loadUser(userId, tenantId) {
    getUser(userId).then(user => {
      this.setState({
        userName: user.fullName,
        edittedUser: user
      })
    })
    getUserTenantOrgs(userId, tenantId).then(userOrgs => {
      this.setState({
        userOrgs: userOrgs
      })
    })
  }

  getRoles(userId) {
    return getUserRoles(userId, this.props.tenantId).then(roles => {
      this.setState({
        userRoles: roles || {}
      })
    }).catch((err) => {
      console.error("Get roles err", err)
      throw err
    })
  }

  async getOrgs(tenantId) {
    try {
      const orgs = await getTenantOrgsById(tenantId)
      this.setState({
        orgs
      })
    } catch (err) {
      console.error("Error fetching orgs: ", err)
    }
  }

  getSpecialistInfo(userId) {
    getUserSpecialist(userId, this.props.tenantId).then(specialist => {
      this.setState({
        specialistId: specialist ? specialist.id : null,
        specialistSearch: specialist ? specialist.fullName : ""
      })
    }).catch((err) => {
      console.error("Get specialist error: ", err.toString())
      this.setState({
        specialistId: null,
        specialistSearch: ""
      })
    })
    getAvailableSpecialists(this.props.tenantId).then(specialists => {
      this.setState({
        allSpecialists: specialists
      })
    }).catch((err) => {
      console.error("Get available specialists error: ", err.toString())
      this.setState({
        allSpecialists: []
      })
    })
  }

  onFieldUpdate(name, value) {
    let user = this.state.edittedUser
    user[name] = value
    this.setState({
      edittedUser: user
    })
  }

  deactivateClicked() {

  }

  async saveUser() {
    const { userId, tenantId, tenantName } = this.props
    const edittedUser = this.state.edittedUser
    const assignedSpecialistId = this.state.specialistId
    const userRoles = this.state.userRoles
    const userOrgs = this.state.userOrgs

    edittedUser.fullName = edittedUser.firstName + ' ' + edittedUser.lastName

    const updateSpecialist = assignedSpecialistId ? 
      assignUserSpecialist(userId, tenantId, assignedSpecialistId) :
      deleteUserSpecialist(userId, tenantId)

    const updates = [
      updateUser(edittedUser),
      updateUserRoles(userRoles, userId, tenantId, tenantName),
      updateUserTenantOrgs(userId, tenantId, userOrgs),
      updateSpecialist
    ] as Promise<void>[]

    try {
      await Promise.all(updates)
      browserHistory.push('/')
    } catch (err) {
      console.error("Error saving user: ", err)
    }
  }

  getValueForField(name) {
    return this.state.edittedUser[name] || ''
  }

  buildForm(fields) {
    return (
      <FormGroup>
        {fields.map(field => {
          const { label, name, value, readOnly } = field
          const readOnlyValue = readOnly ? true : false
          return <FormField
            key={name}
            label={label}
            name={name}
            value={value}
            fieldValueChanged={this.onFieldUpdate.bind(this)}
                    readOnly={readOnlyValue} />
        })}
      </FormGroup>
    )
  }

  createField(label: string, name: string, value: any = null, readOnly = false) {
    return {
      label: label,
      name: name,
      value: value || this.getValueForField(name),
      readOnly: readOnly
    }
  }

  onRolesChanged(role, enabled) {
    let currentRoles = this.state.userRoles
    currentRoles[role] = enabled
    this.setState({
      userRoles: currentRoles
    })
  }

  onOrgDeleted(orgId) {
    if (orgId == ORGS.GLOBAL) {
      return
    }
    const userOrgs = this.state.userOrgs
    delete userOrgs[orgId]
    this.setState({
      userOrgs: userOrgs
    })
  }

  onSpecialistSelect(specialistId, specialist) {
    this.refs.specialistInput.refs.input.blur()
    this.setState({
      specialistId: specialistId,
      specialistSearch: specialist.fullName
    })
  }

  onSpecialistSearch(event, value) {
    const searchTerm = value && value.toLowerCase() || ''
    const filtered = this.state.allSpecialists.filter(specialist => {
      const fullName = specialist.fullName
      return fullName && fullName.toLowerCase().includes(searchTerm)
    })
    this.setState({
      filteredSpecialists: filtered,
      specialistSearch: value
    })
  }

  onClearSpecialistSearch() {
    this.setState({
      specialistId: null,
      specialistSearch: ''
    })
  }
  
resetAccountClicked() {
  this.setState({
    resetAccountId: this.props.userId
  })
}

dismissModal() {
  this.setState({
    resetAccountId: null
  })
}

resetAccountConfirmed() {
  this.dismissModal()
  return resetAccount(this.props.userId)
}

showAddOrgModal = () => {
  const orgs = this.getOrgsAvailableToAdd()
  if (orgs.length) {
    this.setState({ showAddOrgModal: true })
  }
}

handleAddOrganization = (newOrgId: string) => {
  let userOrgs = this.state.userOrgs
  userOrgs[newOrgId] = true
  this.setState({
    userOrgs: userOrgs,
    showAddOrgModal: false
  })
}

dismissOrgModal() {
  this.setState({
    showAddOrgModal: false
  })
}

getOrgsAvailableToAdd(): TenantOrganization[] {
  const orgsById = this.state.orgs
  const userOrgs = this.state.userOrgs || {}
  return Object.keys(orgsById).reduce((prev, orgId) => {
    if (!userOrgs[orgId]) {
      const org = orgsById[orgId]
      prev.push(org)
    }
    return prev
  }, [] as TenantOrganization[])
}

render() {
    
  const isCreating = this.props.userId ? false : true

  let fields = [
    this.createField("First Name", 'firstName'),
    this.createField("Last Name", 'lastName'),
    this.createField("Email", 'email')
  ]

  if (!isCreating) {
    const idField = this.createField("User Id", 'id', this.props.userId, true)
    fields.push(idField)
  }

  const pageTitle = isCreating ? 'Create New User' : this.state.userName

  const orgOptions = this.getOrgsAvailableToAdd()
  const searchedSpecialists = Object.values(this.state.filteredSpecialists)

  let clearButton: JSX.Element | null = null
  if (this.state.specialistSearch.length != 0) {
    clearButton = (<div onClick={this.onClearSpecialistSearch.bind(this)}
  className = 'clear-search' >
                        &times;
                     </div >)
}

let confirmModal: JSX.Element | null = null
if (this.state.resetAccountId) {
  confirmModal = (<ConfirmModal title="Confirm Account Reset?"
  prompt="Resetting an Account will delete all data include clinical data, when-do, flows, etc."
  confirm={this.resetAccountConfirmed.bind(this)}
  dismiss = {this.dismissModal.bind(this) } />)
}
let addOrgModal: JSX.Element | null = null
if (this.state.showAddOrgModal) {
  addOrgModal = (
  <AddUserOrgModal 
    orgOptions={orgOptions}
    orgSelected={this.handleAddOrganization}
    dismiss = {this.dismissOrgModal.bind(this) } />
  )
}

const orgsById = this.state.orgs
const userOrgs = this.state.userOrgs || {}

return (
  <div>
    <div>
      <h1 className='inline-block'>{pageTitle}</h1>
      <div className='section-header-control float-right'>
        <a onClick={this.saveUser.bind(this)}>Save Changes</a>
    </div>
  </div>
  <div className="container-body edit-form-container">
    <Grid>
      <Col id='left-col' md={4} >
        <Form>
          <div className="form-title">Basic Account Information</div>
          {this.buildForm(fields)}
        </Form>
      </Col>
      <Col id="right-col" md={8}>
        <div className="form-title">Permissions and Access</div>
        <UserOrgForm
          orgsById={orgsById}
          userOrgs={userOrgs}
          userRoles={this.state.userRoles}
          getAvailableOrgs={this.getOrgsAvailableToAdd.bind(this)}
          onAddOrgClicked={this.showAddOrgModal.bind(this)}
          onDeleteOrgClicked={this.onOrgDeleted.bind(this)}
          onRolesChanged={this.onRolesChanged.bind(this)}
        />
          <div className="form-title">Telehealth Information</div>
          <FormGroup>
            <ControlLabel>Specialist</ControlLabel>
            <Autocomplete
              inputProps={{
                name: "Search for a specialist...",
                className: "form-control"
              }}
              ref="specialistInput"
              value={this.state.specialistSearch}
              items={searchedSpecialists}
              getItemValue={item => item.id}
              onSelect={this.onSpecialistSelect.bind(this)}
                    onChange={this.onSpecialistSearch.bind(this)}
                    wrapperStyle={{ zIndex: 100, position: "relative" }}
            renderItem={(item) => (
              <div
                className="admin-dropdown-item"
                key={item.id}
                id={item.id} >
                {item.fullName}
              </div>
            )}
            />
            {clearButton}
          </FormGroup>
        </Col>
      </Grid>
    </div>
      { confirmModal }
      { addOrgModal }
    </div >
    );
  }
}

export default UserEditForm