import auth from './authActions'
import dashboard from './dashboardActions'
import telehealth from './telehealthActions'
import spinnerActions from './spinnerActions'

module.exports = {
  ...auth,
  ...dashboard,
  ...spinnerActions,
  ...telehealth
}
