import * as React from 'react';
import { Col } from '@karla/karla-react-components';
import NoteForm from './NoteForm';
import ShowNotes from './ShowNotes';
import { Link } from 'react-router';

export class ClinicalDataDashboard extends React.Component {

  render() {
    if (!this.props.isVisible) {
      return null;
    }
    const style = { width: "40%", float: "left", height: "100%" }
    return (
      <div id="clinical">
        <Col md={6}>
          <NoteForm clientId={this.props.clientId} />
        </Col>
        <Col md={6}>
          <ShowNotes className='showNotes' clientId={this.props.clientId} />
        </Col>
      </div>
    )
  }
}
