import * as React from 'react';
import { Button } from '@karla/karla-react-components';


export default class OrgUserUpload extends React.Component {
  
  render() {
    return (
      <div className="container-body text-center">
        <Button id="upload-users-btn" className="sh-btn">Import users from a recent upload</Button>
      </div>
    )
  }
}
