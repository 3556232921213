import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/analytics'
import 'firebase/functions'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole, Dedupe } from '@sentry/integrations'
import { CONFIG } from './config'

/* eslint-disable  no-console */
console.debug(`Version # ${process.env.BITRISE_BUILD_NUMBER}`)
console.debug('Environment:', 'process.env.APP_ENV')
if (window.location.hostname !== 'localhost') {
  console.debug('Not localhost, Initializing sentry')
  /* eslint-enable  no-console */
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: `react@${process.env.BITRISE_BUILD_NUMBER}`, // To set your release version
    debug: process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'staging',
    environment: 'process.env.APP_ENV' || 'dev',
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({ levels: ['error', 'warning'] }),
      new Dedupe()
    ],
    attachStacktrace: true,
    beforeSend(event) {
      // eslint-disable-next-line no-console
      let authState
      try {
        authState = JSON.parse(localStorage.getItem('karla_auth_state') || '')
      } catch {
        authState = {}
      }

      // eslint-disable-next-line no-console
      console.log('sending error log', event)
      event.contexts = { ...event.contexts, localStorage }
      event.user = { ...event.user, id: authState ? authState.uid : null }
      event.tags = {
        ...event.tags,
        ...authState
      }

      return event
    },
    tracesSampleRate: 1 // We recommend adjusting this in production
  })
}
// test log events
// Sentry.captureException(new Error('error2'))
// console.error(new Error('error1'))

if (firebase.apps.length === 0) {
  const config = {
    databaseURL: CONFIG.FIREBASE.ROOT,
    apiKey: CONFIG.FIREBASE.API_KEY,
    projectId: CONFIG.FIREBASE.PROJECT_ID,
    appId: CONFIG.FIREBASE.APP_ID,
    measurementId: CONFIG.FIREBASE.MEASUREMENT_ID
  }
  firebase.initializeApp(config)
  if (config.measurementId) {
    firebase.analytics()
  }
}

// @ts-ignore
window.GOOGLE_MAPS_API_KEY = CONFIG.GOOGLE.MAPS_API_KEY

// @ts-ignore
Promise.successes = (promises) => {
  const caught = promises.map((temporaryPromise) => {
    return temporaryPromise.catch(() => {})
  })
  return Promise.all(caught).then((results) => {
    return results.filter((value) => value !== undefined)
  })
}
