export const LOADING_BEGIN = 'LOADING_BEGIN'
export const LOADING_END = 'LOADING_END'
export const LOADING_END_ALL = 'LOADING_END_ALL'

type ReturnAction = {
  type: 'LOADING_BEGIN' | 'LOADING_END' | 'LOADING_END_ALL'
}

export const spinnerAction = (
  loading: boolean,
  forcedEnd?: boolean
): ReturnAction => {
  if (forcedEnd) {
    return {
      type: LOADING_END_ALL
    }
  }
  return {
    type: loading ? LOADING_BEGIN : LOADING_END
  }
}
