export function average(values: number[]) {
  let count = values.length
  const sum = values.reduce((previous, current) => (current += previous))
  return sum / values.length
}

export function median(values: number[]) {
  values.sort((a, b) => a - b)
  let lowMiddle = Math.floor((values.length - 1) / 2)
  let highMiddle = Math.ceil((values.length - 1) / 2)
  return (values[lowMiddle] + values[highMiddle]) / 2
}
