import { Observable } from 'rxjs'
import firebase from 'firebase/app'
import { fromCollectionRef } from 'rxfire/firestore'
import { map } from 'rxjs/operators';
import { Roles } from './Roles';
import { OrgMemberships} from './Orgs';

const firestore = firebase.firestore()

type InviteData = {
  firstName: string
  lastName: string
  phoneNumber: string
  sentSMS: boolean
  createdDate: number
  counter?: number
  acceptedDate: number | null
  code: string
  link: string
  createdBy: string
  specialistId: string
  tenantId?: string | String
  organizations?: OrgMemberships
}

type GlobalInvite = InviteData & {
  grants: {[key: string]: Roles}
}

export type Invite = InviteData & {
  roles: Partial<Roles>
}

export function getTenantInvites(tenantId: string): Observable<Invite[]> {
  const query = firestore.collection('invites')
    .where("tenants", "array-contains", tenantId)
  return fromCollectionRef(query).pipe(
    map((snapshot: firebase.firestore.QuerySnapshot): Invite[] => {
      return snapshot.docs
      .map(doc => {
        const invite = doc.data() as GlobalInvite
        const roles = invite.grants[tenantId]
        const tenantInvite: any = {...invite}
        delete tenantInvite.grants
        tenantInvite.roles = roles
        return tenantInvite
      })
    })
  )
}