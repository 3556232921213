import firebase from 'firebase/app'

const firestore = firebase.firestore()

export type Document = {
  id: string,
  data: {[key: string]: any}
}

export async function getDoc(path: string): Promise<Document | null> {
  try {
    const snapshot = await firestore.doc(path).get()
    if (snapshot.exists) {
      return {
        id: snapshot.id,
        data: snapshot.data() as {[key: string]: any}
      }
    } else {
      return null
    }
  } catch (err) {
    console.error(`Error fetching document at`, path, err.toString())
    return null
  }
}

export function query(path: string): firebase.firestore.Query {
  return firestore.collection(path)
}