import * as React from 'react'
import { Map, Circle, Popup, TileLayer } from 'react-leaflet'
import { latLngBounds, latLng } from 'leaflet'
import { Grid, Row, Col } from '@karla/karla-react-components'
import { KarlaChatClient } from '~/db/KarlaChatClient'
import { URN } from '@karla/karla-core'
import moment from 'moment'
import classNames from 'classnames'

const initialState = {
  dayIdentifiers: [],
  expandedDays: {},
  expandedReadings: {},
  decryptedReadings: {},
  readingsByDay: {},
  selectedReadingId: null
}

export default class LocationTriggerDetail extends React.Component {

  static propTypes = {
    userId: React.PropTypes.string.isRequired,
    clientId: React.PropTypes.string.isRequired,
    recipe: React.PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentWillMount() {
    this.reloadUserLocation(this.props)
  }

  componentWillReceiveProps(props) {
    if (props.specialistId != this.props.specialistId || props.clientId != this.props.clientId) {
      this.setState(initialState)
      this.reloadUserLocation(props)
    }
  }

  reloadUserLocation({userId, clientId}) {
    this.unbind()
    this.karlaClient = new KarlaChatClient(userId, clientId)
    this.ref = this.karlaClient.bindReadingsFromSource("urn:location", {
      handler: this.handleLocations.bind(this),
      decrypt: false,
    })
  }
  
  handleLocations(encryptedLocations) {
    
    const dayIds = {}
    const readingsByDay = {}
    
    encryptedLocations.forEach((encLocation) => {
      const dayId = this.dayIdentifierForReading(encLocation)
      const location = this.state.decryptedReadings[encLocation.id] || encLocation
      dayIds[dayId] = true
      const dayList = readingsByDay[dayId] || []
      dayList.push(location)
      readingsByDay[dayId] = dayList
    })
    
    this.setState({
      dayIdentifiers: Object.keys(dayIds),
      readingsByDay,
    })
  }
  
  expandDay(dayId) {
    const expanded = this.state.expandedDays || {}
    expanded[dayId] = true
    this.setState({
      expandedDays: expanded
    })
  }
  
  collapseDay(dayId) {
    const expanded = this.state.expandedDays || {}
    expanded[dayId] = undefined
    this.setState({
      expandedDays: expanded
    })
  }
  
  expandReading(reading) {
    const currentSelections = this.state.expandedReadings || {}
    currentSelections[reading.id] = true
    if (this.isEncrypted(reading)) {
      this.karlaClient.findOrDecrypt(reading, this.ref).then((decrypted) => {
        if (decrypted) {
          const decryptedReadings = this.state.decryptedReadings
          decryptedReadings[reading.id] = decrypted
          this.setState({
            expandedReadings: currentSelections,
            selectedReadingId: reading.id,
            decryptedReadings,
          })
        }
      })
    } else {
      this.setState({
        expandedReadings: currentSelections,
        selectedReadingId: reading.id,
      })
    }
  }
  
  collapseReading(reading) {
    if (this.state.selectedReadingId != reading.id) {
      this.expandReading(reading)
      return
    }
    const currentSelections = this.state.expandedReadings || {}
    currentSelections[reading.id] = undefined
    this.setState({
      expandedReadings: currentSelections,
      selectedReadingId: null
    })
  }
  
  isEncrypted(reading) {
    return !reading["urn:server:timestamp"]
  }
  
  dayIdentifierForReading(reading) {
    const timestamp = reading["urn:server:timestamp"] || reading.metadata.timestamp
    return moment.unix(timestamp).format("dddd, MMMM D, YYYY")
  }

  unbind() {
    if (this.ref) {
      this.karlaClient.unbind(this.ref)
    }
  }

  renderSidebar() {
    return (
    <div className="location-sidebar">
      <h2>Location Readings</h2>
      {this.state.dayIdentifiers.map(this.renderDay.bind(this))}
    </div>
    )
  }
  
  renderDay(dayId) {
    if (this.state.expandedDays[dayId]) {
      const locations = this.state.readingsByDay[dayId]
      return (
        <div key={dayId}>
          <h3 onClick={this.collapseDay.bind(this, dayId)}>{dayId}</h3>
          {locations.map(this.renderLocation.bind(this))}
        </div>
      )
    } else {
      return (
        <div key={dayId}>
          <h3 onClick={this.expandDay.bind(this, dayId)}>{dayId}</h3>
        </div>
      )
    }
  }

  renderLocation(reading, index) {
      const location = this.state.decryptedReadings[reading.id] || reading
      const timestamp = location["urn:server:timestamp"] || location.metadata.timestamp
      const date = moment.unix(timestamp).format("h:mm:ss a - MM/DD/YYYY")
      if (this.state.expandedReadings[location.id]) {
        if (this.isEncrypted(location)) {
          throw new Error("reading expanded but not yet decrypted")
        }
        const lat = location["urn:location:latitude"]
        const long = location["urn:location:longitude"]
        const accuracy = location["urn:location:horizontalAccuracy"]
        const classes = classNames({
          "reading-timestamp": true,
          "selected-date": reading.id == this.state.selectedReadingId
        })
        return (
          <div key={index} onClick={this.collapseReading.bind(this, location)}>
            
            <div className={classes}>- {date}</div>
            <div className="reading-info">
              <div>Latitude: {lat}</div>
              <div>Longitude: {long}</div>
              <div>Accuracy: {accuracy}</div>
            </div>
          </div>
        )
      } else {
        return <div 
          key={index} 
          className="reading-timestamp"
          onClick={this.expandReading.bind(this, location)}>
            + {date}
          </div>
      }
  }
  
  renderUserLocation() {
    if (this.state.selectedReadingId) {
      const location = this.state.decryptedReadings[this.state.selectedReadingId]
      const lat = location["urn:location:latitude"]
      const long = location["urn:location:longitude"]
      const accuracy = location["urn:location:horizontalAccuracy"]
      const center = [lat, long]
      const date = moment.unix(location["urn:server:timestamp"]).format("MMMM D, YYYY h:mm:ss a")
      return (
        <Circle center={center} radius={accuracy}>
          <Popup>
            <span>
              Location: {lat}, {long} <br />
              Accuracy: {accuracy} meters <br />
              Date: {date}
            </span>
          </Popup>
        </Circle>
      )
    } else {
      return null
    }
  }

  render() {
    const trigger = this.props.recipe["urn:recipe:trigger"]
    const regionRadius = trigger.args.radius
    const regionLat = trigger.args.lat
    const regionLong = trigger.args.long
    let regionCenter = [regionLat, regionLong]
    let mapProps = {
      className: "location-map"
    }
    if (this.state.selectedReadingId) {
      const location = this.state.decryptedReadings[this.state.selectedReadingId]
      const locationCenter = [location["urn:location:latitude"], location["urn:location:longitude"]]
      const regionLatLong = latLng(regionCenter)
      const locationLatLong = latLng(locationCenter)
      const mapBounds = latLngBounds([regionLatLong, locationLatLong])
      mapProps.bounds = mapBounds
    } else {
      mapProps.center = regionCenter
      mapProps.zoom = 19
    }
    return (
      <Grid>
        <Row>
          <Col md={9} sm={12}>
            <Row>
              <div className="container-body">
                <div className="region-info">
                  Location: {regionLat}, {regionLong} Radius: {regionRadius} meters
                </div>
                <div className="location-container">
                  <Map {...mapProps}>
                    <TileLayer
                      url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Circle center={regionCenter} radius={regionRadius}>
                      <Popup>
                        <span>{regionLat}, {regionLong} <br />
                        {regionRadius} meters</span>
                      </Popup>
                    </Circle>
                    {this.renderUserLocation()}
                  </Map>
                </div>
              </div>
            </Row>
          </Col>
          <Col md={3} sm={12}>
            <div className="container-body">
              {this.renderSidebar()}
            </div>
          </Col>
        </Row>
      </Grid>
    )
  }
}
