import * as React from 'react';
import moment from 'moment'

export class UpcomingSessionsPanel extends React.Component {

  static propTypes = {
    sessions: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    deleteSession: React.PropTypes.func.isRequired
  };

  render() {
    const sessions = this.props.sessions
    return (
      <div className="container-body">
        <div className='dashboard-panel-header'>
          Upcoming Sessions
        </div>
        <ul className="sessions-list">
          {sessions.map((session, idx) => {
            const startMoment = moment.unix(session.startDate)
            const startDate = startMoment.format("dddd, MMMM DD")
            const startTime = startMoment.format("h:mm a")
            const endTime = moment.unix(session.endDate).format("h:mm a")
            const onClickDelete = () => {
              this.props.deleteSession(session)
            }
            return (
              <li key={idx}>
                <div className="schedule-date">{startDate}</div>
                <div className="remove-session-btn" onClick={onClickDelete}>&times;</div>
                <div>{startTime} - {endTime}</div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
