import {combineReducers} from 'redux';
import { routerReducer } from 'react-router-redux';
import {reducer as formReducer} from 'redux-form';
import login from './login';
import providerAuth from './providerAuth';
import dashboard from './dashboard'
import telehealth from './telehealth'
import spinnerReducer from './spinnerReducer';

const rootReducer = combineReducers({
  auth: login,
  providers: providerAuth,
  dashboard: dashboard,
  telehealth: telehealth,
  spinner: spinnerReducer,
  routing: routerReducer,
  form: formReducer
});

export default rootReducer;
