import * as React from 'react';
import ContentAssignmentList from './ContentAssignmentList'

export default class AssignedContentList extends ContentAssignmentList {
  
  static propTypes = {
    onEdit: React.PropTypes.function,
    clientId: React.PropTypes.string
  }
  
  constructor(props) {
    super(props)
    this.state.archived = false
  }

  clickArchiveContent(content) {
    const responseId = content.id
    console.log(responseId)
    this.surveyRepo.archiveAssignedContent(this.props.clientId, responseId)
  }
}
