import * as React from 'react';
import SurveyResponseModal from './SurveyResponseModal'

export default class IntakeResponseModal extends React.Component {
  
  static propTypes = {
    response: React.PropTypes.object.isRequired,
        onHide: React.PropTypes.func.isRequired
  };
  
  render() {
    const response = this.props.response
    const answer = response.answers[0]
    const choices = Object.values(answer.choices)
    const question = answer.question
    return (
      <SurveyResponseModal name="Intake Survey" {...this.props}>
        <div className="survey-question">{question}</div>
        {choices.map(choice => {
          return <div key={choice} className="survey-answer">{choice}</div>
        })}
      </SurveyResponseModal>
    )
  }
}
