import * as React from 'react'
import { isWebUri } from 'valid-url'
import { Modal, Row, Col, Button, ButtonGroup, DropdownButton, MenuItem } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'

export const ContentTypes = {
  LINK: 'link',
  OPEN_ENDED: 'openEnded',
  SURVEY: 'survey',
  DIALOG: 'reactive-dialog',
}

export default class AssignContentModal extends React.Component {
  static propTypes = {
    show: React.PropTypes.bool,
    onHide: React.PropTypes.function,
    onConfirm: React.PropTypes.function,
    selectedActivity: React.PropTypes.object,
    surveys: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
    dialogs: React.PropTypes.arrayOf(React.PropTypes.object).isRequired,
  }

  constructor(props) {
    super(props)
    this.confirm = this.confirmClicked.bind(this)
    this.onTitleChange = this.titleChanged.bind(this)
    this.onRadioClick = this.radioClicked.bind(this)
    this.onLinkChange = this.linkChanged.bind(this)
    this.state = {}
  }

  componentDidMount() {
    this.setupSelectedActivity(this.props.selectedActivity)
  }

  componentWillReceiveProps(props) {
    this.resetAllErrors()
    this.setupSelectedActivity(props.selectedActivity)
    if (props.surveys.length > 0) {
      this.setState({
        selectedSurvey: props.surveys[0],
      })
    } else {
      this.setState({
        selectedSurvey: { title: 'No surveys available' },
      })
    }
    if (props.dialogs.length > 0) {
      this.setState({
        selectedDialog: props.dialogs[0],
      })
    } else {
      this.setState({
        selectedDialog: { description: 'No dialogs available' },
      })
    }
  }

  stateForProps(props) {
    const selectedActivity = props.selectedActivity
    if (selectedActivity) {
      return {
        contentType: selectedActivity.type,
        name: selectedActivity.name || '',
        linkText: selectedActivity.link || '',
      }
    } else {
      return {
        contentType: ContentTypes.LINK,
      }
    }
  }

  setupSelectedActivity(selectedActivity) {
    if (!selectedActivity) {
      this.setState({
        contentType: ContentTypes.LINK,
      })
      return
    } else {
      this.setState({
        contentType: selectedActivity.type,
      })
      if (this.refs.titleField) {
        this.refs.titleField.value = selectedActivity.name
      }
      if (this.refs.linkField) {
        this.refs.linkField.value = selectedActivity.link
      }
      if (this.refs.openEndedField) {
        this.refs.linkField.value = selectedActivity.body
      }
    }
  }

  titleChanged(event) {
    this.setState({ name: event.target.value })
  }

  linkChanged(event) {
    this.setState({ linkText: event.target.value })
  }

  isChecked(type) {
    return this.state.contentType == type
  }

  confirmClicked() {
    this.resetAllErrors()

    var assignment = null
    try {
      switch (this.state.contentType) {
        case ContentTypes.LINK:
          assignment = this.buildLinkAssignment()
          break
        case ContentTypes.OPEN_ENDED:
          assignment = this.buildOpenEndedAssignment()
          break
        case ContentTypes.SURVEY:
          assignment = this.buildSurveyAssignment()
          break
        case ContentTypes.DIALOG:
          assignment = this.buildDialogAssigment()
          break
        default:
          throw new Error('Unhandled content type ' + this.state.contentType)
      }
    } catch (error) {
      if (error.titleMessage) {
        this.setState({ titleError: error.titleMessage })
      }

      if (error.linkMessage) {
        this.setState({ linkError: error.linkMessage })
      }

      if (error.bodyMessage) {
        this.setState({ bodyError: error.bodyMessage })
      }

      return
    }

    if (this.props.selectedActivity) {
      assignment.id = this.props.selectedActivity.id
    }

    this.props.onConfirm(assignment)
  }

  resetAllErrors() {
    delete this.state.titleError
    delete this.state.linkError
    delete this.state.bodyError
  }

  buildLinkAssignment() {
    let url = this.refs.linkField.value
    if (!url.startsWith('http')) {
      url = 'http://' + url
    }
    url = url.replace(/^http:\/\//i, 'https://')
    let name = this.refs.titleField.value
    let type = this.state.contentType

    let error = new Error('Cannot create link assignment')
    if (!name) {
      error.titleMessage = 'Title - Field Required'
    }
    if (!this.isValidURL(url)) {
      error.linkMessage = 'Link - Invalid Link'
    }
    if (error.titleMessage || error.linkMessage) {
      throw error
    }

    return {
      link: url,
      name: name,
      type: type,
    }
  }

  isValidURL(str) {
    return isWebUri(str)
  }

  buildOpenEndedAssignment() {
    let body = this.refs.openEndedField.value
    let name = this.refs.titleField.value
    let type = this.state.contentType

    let error = new Error('Cannot create open ended assignment')
    if (!body || !body.length) {
      error.bodyMessage = 'Content - Field Required'
    }
    if (!name) {
      error.titleMessage = 'Title - Field Required'
    }

    if (error.titleMessage || error.bodyMessage) {
      throw error
    }

    return {
      body: body,
      name: name,
      type: type,
    }
  }

  buildSurveyAssignment() {
    let type = this.state.contentType
    let name = this.state.selectedSurvey.title
    let surveyId = this.state.selectedSurvey.id
    return {
      name: name,
      type: type,
      survey: surveyId,
    }
  }

  buildDialogAssigment() {
    let type = this.state.contentType
    let name = this.state.selectedDialog.description
    let dialogId = this.state.selectedDialog.dialog
    let scoring = this.state.selectedDialog.scoring
    const assignment = {
      name,
      type,
      dialog: dialogId
    }
    if (scoring) {
      assignment.scoring = scoring
    }
    return assignment
  }

  radioClicked(event) {
    this.resetAllErrors()
    this.setState({
      contentType: event.target.value,
    })
  }

  radioButton(ref, title, value) {
    return (
      <Button bsClass="radio-btns" active onClick={this.onRadioClick} value={value}>
        {' '}
        {title}
        <input
          className="radio"
          disabled={false}
          ref={ref}
          type="radio"
          name={value}
          value={value}
          checked={this.isChecked(value)}
        />
      </Button>
    )
  }

  surveySelectionChanged(event) {
    const { surveys } = this.props
    var selectedSurvey
    surveys.forEach(function (survey) {
      if (survey.title == event) {
        selectedSurvey = survey
      }
    })
    if (selectedSurvey) {
      this.setState({
        selectedSurvey: selectedSurvey,
      })
    }
  }

  dialogSelectionChanged(event) {
    if (!event || event.length <= 0) return
    const dialogId = event[0].dialog
    const { dialogs } = this.props
    let selectedDialog
    dialogs.find((dialog) => {
      if (dialog.dialog === dialogId) {
        selectedDialog = dialog
        return dialog
      }
    })
    if (selectedDialog) {
      this.setState({
        selectedDialog,
      })
    }
  }

  renderContentPanel(surveys, dialogs) {
    return (
      <div>
        {(() => {
          if (!surveys) {
            return
          }
          const filteredSurveys = surveys.filter(survey => !survey.title.toLowerCase().includes('intake'))
          const filterDialogs = dialogs
          switch (this.state.contentType) {
            case ContentTypes.SURVEY:
              return (
                <div>
                  <div className="modal-title">Select Survey</div>
                  <div>
                    <Row className="assign-form-row">
                      <Col className="assign-form-column">
                        <DropdownButton
                          id="survey-dropdown"
                          title={this.state.selectedSurvey.title}
                          onSelect={this.surveySelectionChanged.bind(this)}
                        >
                          {filteredSurveys.map(survey => {
                            return (
                              <MenuItem key={survey.title} eventKey={survey.title}>
                                {survey.title}
                              </MenuItem>
                            )
                          })}
                        </DropdownButton>
                      </Col>
                    </Row>
                  </div>
                </div>
              )
            case ContentTypes.LINK:
              return (
                <div>
                  <div className={this.state.titleError ? 'modal-title-error ' : 'modal-title'}>
                    {this.state.titleError ? this.state.titleError : 'Title'}
                  </div>
                  <input
                    name="assignmentTitle"
                    type="text"
                    ref="titleField"
                    defaultValue={this.props.selectedActivity ? this.props.selectedActivity.name : ''}
                  />
                  <div>
                    <div className={this.state.linkError ? 'modal-title-error ' : 'modal-title'}>
                      {this.state.linkError ? this.state.linkError : 'Link'}
                    </div>
                    <input
                      name="assignmentURL"
                      type="text"
                      ref="linkField"
                      defaultValue={this.props.selectedActivity ? this.props.selectedActivity.link : ''}
                    />
                  </div>
                </div>
              )
            case ContentTypes.OPEN_ENDED:
              return (
                <div>
                  <div className={this.state.titleError ? 'modal-title-error ' : 'modal-title'}>
                    {this.state.titleError ? this.state.titleError : 'Title'}
                  </div>
                  <input
                    name="assignmentTitle"
                    type="text"
                    ref="titleField"
                    defaultValue={this.props.selectedActivity ? this.props.selectedActivity.name : ''}
                  />
                  <div>
                    <div className={this.state.bodyError ? 'modal-title-error ' : 'modal-title'}>
                      {this.state.bodyError ? this.state.bodyError : 'Content'}
                    </div>
                    <textarea
                      className="modal-input"
                      id="open-ended-input"
                      name="openEndedBody"
                      ref="openEndedField"
                      defaultValue={this.props.selectedActivity ? this.props.selectedActivity.body : ''}
                    />
                  </div>
                </div>
              )
            case ContentTypes.DIALOG:
              return (
                <div>
                  <div className="modal-title">Select Dialog</div>
                  <div>
                    <Typeahead
                      id="basic-typeahead-example"
                      labelKey="description"
                      onChange={this.dialogSelectionChanged.bind(this)}
                      options={filterDialogs}
                      placeholder="Select Dialog"
                      renderMenuItemChildren={(option) => (
                        <div>
                          {option.description}
                          <div>
                            <small>{option.dialog}</small>
                          </div>
                          <div>
                            <small>Scoring: {option.scoring ? option.scoring : 'No scoring'}</small>
                          </div>
                          {option.sourcePackage && <div>
                            <small>Source: {option.sourcePackage}</small>
                          </div>}
                        </div>
                      )}
                    />
                  </div>
                </div>
              )
            default:
              return null
          }
        })()}
      </div>
    )
  }

  render() {
    const { show, onHide, surveys, dialogs } = this.props
    return (
      <Modal id="assign-modal" bsSize="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body closeButton>
          <div className="row">
            <div className="col-md-5 col-sm-6">
              <div className="modal-title">Task Category</div>
              <ButtonGroup>
                {this.radioButton('surveyButton', 'Survey', ContentTypes.SURVEY)}
                {this.radioButton('linkButton', 'Link', ContentTypes.LINK)}
                {this.radioButton('openEndedButton', 'Open Ended', ContentTypes.OPEN_ENDED)}
                {this.radioButton('dialogButton', 'Dialog', ContentTypes.DIALOG)}
              </ButtonGroup>
            </div>
            <div className="col-md-7 col-sm-6 select-group border-left">
              {this.renderContentPanel(surveys, dialogs)}
              <Button className="assignButton" onClick={this.confirm}>
                Assign Content
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
