import * as React from 'react';
import { Form, FormGroup, Grid, Col } from '@karla/karla-react-components';
import { connect } from 'react-redux'
import { ControlLabel, FormControl } from 'react-bootstrap'
import DeleteOrgModal from '../DeleteOrgModal'
import { browserHistory } from 'react-router'
import { createTenantOrg, getTenantOrg, updateTenantOrg, deleteTenantOrg, getTenantOrgUserCount } from '../../../db/Orgs';

const mapStateToProps = state => {
  return {
    userId: state.auth.uid,
    tenantId: state.auth.selectedRole.tenantId
  }
}

type Props = {
  orgId?: string,
  userId: string,
  tenantId: string
}

type State = {
  org: {
    id: string
    name: string
  },
  userCount: number,
  edittedOrg: {
    id: string
    name: string
  },
  deleteModal: {

  } | null
}

@connect(
  mapStateToProps,
  dispatch => ({}))
export default class OrgEditForm extends React.Component<Props, State> {

  constructor(props) {
    super(props)
    this.state = {
      org: {
        id: "",
        name: ""
      },
      userCount: 0,
      edittedOrg: {
        id: "",
        name: ""
      },
      deleteModal: null,
    }
  }
  
  componentDidMount() {
    this.reload()
  }

  componentDidUpdate(prevProps) {
    if (this.props.tenantId != prevProps.tenantId ||
        this.props.orgId != prevProps.orgId) {
      this.reload()
    }
  }

  async reload() {
    const { tenantId, orgId } = this.props
    if (!orgId) {
      return
    }
    try {
      const [org, userCount] = await Promise.all([
        getTenantOrg(tenantId, orgId),
        getTenantOrgUserCount(tenantId, orgId)
      ])
      this.setState({
        org: {
          id: org.id,
          name: org.name
        },
        userCount,
        edittedOrg: {
          id: org.id,
          name: org.name
        }
      })
    } catch (err) {
      console.error("Error getting org " + err)
    }
  }

  onNameInput(event) {
    const name = event.target.value
    let org = this.state.edittedOrg
    org.name = name
    
    if (!this.props.orgId) {
      org.id = name.slugify()
    }
    
    this.setState({
      edittedOrg: org
    })
  }
  
  async saveClicked() {

    const { orgId, tenantId } = this.props
    let edittedOrg = this.state.edittedOrg

    const save = orgId ? 
      updateTenantOrg(tenantId, edittedOrg) : 
      createTenantOrg(tenantId, edittedOrg)

    try {
      await save
      this.goBack()
    } catch (err) {
      console.error("Error saving org: ", err)
    }
  }
  
  deleteClicked() {
    this.setState({
      deleteModal: true
    })
  }
  
  async deleteConfirmed() {
    this.dismissModal()
    if (this.props.orgId) {
      await deleteTenantOrg(this.props.tenantId, this.props.orgId)
      this.goBack()
    }
  }
  
  dismissModal(){
    this.setState({
      deleteModal: false
    })
  }
  
  clearClicked() {
    
  }
  
  goBack() {
    browserHistory.push('/organizations')
  }
  
  renderOrg(org) {
    return <option value={org.id}>{org.name}</option>
  }
  
  createDeleteModal() {
    const {userCount} = this.state
    return <DeleteOrgModal orgName={this.state.org.name}
                           userCount={userCount}
                           deleteOrg={this.deleteConfirmed.bind(this)}
                           dismiss={this.dismissModal.bind(this)} />
  }
  
  render() {
  
    const orgId = this.props.orgId
    
    let title = ""
    if (!orgId) {
      title = 'Create New Organization'
    } else if (this.state.org && this.state.org.name) {
      title = `${this.state.org.name} Organization`
    }
    
    const edittedOrg = this.state.edittedOrg
    
    let deleteButton: JSX.Element | undefined
    if (this.props.orgId) {
      deleteButton = <a onClick={this.deleteClicked.bind(this)}>Delete</a>
    } else {
      deleteButton = <a onClick={this.clearClicked.bind(this)}>Clear</a>
    }
    
    let deleteModal: JSX.Element | undefined
    if (this.state.deleteModal) {
      deleteModal = this.createDeleteModal()
    }
    
    return (
      <div>
        <div>
          <h1 className="inline-block">{title}</h1>
          <div className='section-header-control float-right'>
            <a onClick={this.saveClicked.bind(this)}>Save</a> | {deleteButton}
          </div>
        </div>
        <div className="container-body edit-form-container">
          {deleteModal}
          <Grid>
            <Col id="left-col" md={6} >
              <Form>
                <FormGroup>
                  <ControlLabel>Name</ControlLabel>
                  <FormControl
                    type="text"
                    value={edittedOrg.name}
                    onChange={this.onNameInput.bind(this)}
                  />
                  <ControlLabel>Org Key</ControlLabel>
                  <FormControl
                    type="text"
                    value={edittedOrg.id}
                    readOnly
                  />
                </FormGroup>
              </Form>
            </Col>
          </Grid>
        </div>
      </div>
    );
  }
}
