import * as React from 'react';
import { Grid, Row, Col } from '@karla/karla-react-components';
import OrgUsersList from '../OrgUsersList'
import OrgUserUpload from './OrgUserUploadComponent'
import OrgEditForm from './OrgEditForm'

export default class OrgDetailContainer extends React.Component {

  static propTypes = {
    orgId: React.PropTypes.string
  };

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      key: '',
    }
  }
  
  render() {

    var orgId = undefined
    if (this.props.params) {
      orgId = this.props.params.orgId
    }

    let usersComponent = orgId ? (<OrgUsersList orgId={orgId} />) : (<OrgUserUpload />)

    return (
      <div id='body'>
        <Grid>
          <Row>
            <OrgEditForm orgId={orgId}/>
          </Row>
          <br />
          <Row>
            <Col md={4} className="section-header-title">
              Organization Users
            </Col>
          </Row>
          <Row>
            {usersComponent}
          </Row>
        </Grid>
      </div>
    );
  }
}
