import * as React from 'react';
import { Col, Row, Grid } from '@karla/karla-react-components';


export class DashElement extends React.Component {

  render() {
    return (
      <Col sm={this.props.size} md={this.props.size} lg={this.props.size} xsOnlyGutterBottom={!this.props.isLastCol} smCollapseRight={!this.props.isLastCol}>
        <div className="graph-card">
          {this.props.children}
        </div>
      </Col>
    )
  }
}

export class DashGridRow extends React.Component {

  constructor(props) {
    super(props)
    
    // this.props.children.forEach(function(element, index, array) {
    //   element.props.isLastCol = index < array.length - 1
    // })
  }

  render() {
    return (
      <Row className="dash-grid-row">
        {this.props.children}
      </Row>
    )
  }
}

export class DashGridRowData {

  constructor(firstElement) {
    this.elements = [firstElement]
  }

  canFitElement(element) {
    return 12 - (this.filledSize() + element.props.size) >= 0
  }

  addElement(element) {
    this.elements.push(element)
  }

  filledSize() {
    return this.elements.reduce((prev, curr) => prev + curr.props.size, 0)
  }

  isFull() {
    return this.filledSize() == 12
  }
}

export class DashGrid extends React.Component {

  constructor(props) {
    super(props)
    this.generateRows = this.generateRows.bind(this)
  }

  generateRows() {
    var allRows = [];
    var unfilledRows = [];

    // Build data for rows
    this.props.data.forEach(function(element) {

      // Check if we have an unfilled row that can fit this element
      var currentRow = undefined
      for (var i = 0; i < unfilledRows.length; i++) {
        var unfilledRow = unfilledRows[i]
        if (unfilledRow.canFitElement(element)) {
          unfilledRow.addElement(element)
          currentRow = unfilledRow
          if (unfilledRow.isFull()) {
            unfilledRows.splice(i, 1)
            break;
          }
        }
      }

      // Check if we found an row for this data and if not, create one
      if (!currentRow) {
        currentRow = new DashGridRowData(element)
        allRows.push(currentRow)
        unfilledRows.push(currentRow)
      }
    })
    
    return allRows.map(function(row, index) {
      return <DashGridRow elements={row.elements} />
    })
  }

  render() {
    return (
      <Grid>
        {this.props.children}
      </Grid>
    );
  }
}
