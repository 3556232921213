/* eslint-disable react/no-string-refs */
import * as React from 'react'
import {
  ControlLabel,
  FormControl,
  Modal,
  Form,
  FormGroup
} from '@karla/karla-react-components'

import { Typeahead } from 'react-bootstrap-typeahead'

import UserRolesForm, { TenantOrgsForm } from '../users/UserRolesForm'
// import SearchClient from '../../db/SearchClient'
import { getAvailableSpecialists } from '../../db/Telehealth'
import { getTenantOrgs, TenantOrganization } from '../../db/Orgs'
import { uniqBy } from '../../common/lib/object_helpers'
import { User } from '../../db/Users'

type Props = {
  onHide: (any) => void
  onSave: (any) => void
  errorMessage: string
  tenantId: string
  isSending: boolean
}

export type State = {
  specialistSearch: string
  specialistId?: string
  allSpecialists: User[]
  filteredSpecialists: unknown[]
  selectedRoles: {
    [role: string]: boolean
  }
  tenantOrgs: Partial<
    {
      name: string
      id: string
      selected: boolean
    } & TenantOrganization
  >[]
  invalid: {
    [role: string]: any
  }
  inputTargets: {
    [inputName: string]: string
  }
}

export default class OrgInviteModal extends React.Component<Props, State> {
  refs: {
    [inputField: string]: any
  }

  // private searchClient: SearchClient

  constructor(properties) {
    super(properties)
    this.state = {
      specialistSearch: '',
      specialistId: '',
      allSpecialists: [],
      filteredSpecialists: [],
      selectedRoles: {
        consumer: true
      },
      tenantOrgs: [],
      invalid: {},
      inputTargets: {}
    }
    this.onOrgChange = this.onOrgChange.bind(this)
    this.saveClicked = this.saveClicked.bind(this)
    this.roleChanged = this.roleChanged.bind(this)
    this.validatePhone = this.validatePhone.bind(this)
    this.getSpecialistInfo = this.getSpecialistInfo.bind(this)
    // this.searchClient = new SearchClient()
  }

  componentDidMount() {
    this.getSpecialistInfo()
    this.assignTenantOrgs(this.props.tenantId)
  }

  componentDidUpdate(nextProperties) {
    if (nextProperties.tenantId !== this.props.tenantId) {
      this.assignTenantOrgs(nextProperties.tenantId)
      this.getSpecialistInfo()
    }
  }

  getSpecialistInfo() {
    getAvailableSpecialists(this.props.tenantId)
      .then((specialists) => {
        this.setState({
          allSpecialists: specialists
        })
      })
      .catch((error) => {
        console.error('Get available specialists error:', error.toString())
        this.setState({
          allSpecialists: []
        })
      })
  }

  assignTenantOrgs = async (tenantId) => {
    const tenantOrgs = (await getTenantOrgs(tenantId)) as State['tenantOrgs']
    const tenantOrgsSt = this.state.tenantOrgs
    const orgs = uniqBy(
      tenantOrgs.concat(tenantOrgsSt),
      'id'
    ) as TenantOrganization[]
    this.setState({ tenantOrgs: orgs })
  }

  saveClicked() {
    const organizations = this.state.tenantOrgs
      .filter(({ selected }) => selected)
      .map(({ id, name }) => {
        return { id, name }
      })
    // TODO: Avoid submitting if there are validation errors
    const invalid = this.validateInputs()
    if (
      !this.state.specialistId ||
      Object.values(invalid).find((isError) => isError)
    ) {
      return this.setState((previous) => {
        const state = {
          ...previous,
          invalid: { ...previous.invalid, ...invalid }
        }
        return this.specialistValidationState(state)
      })
    }

    const invite: {
      phoneNumber
      firstName: string
      lastName: string
      specialistId?: string
      organizations: typeof organizations
      roles: string[]
    } = {
      phoneNumber: this.state.inputTargets.phoneInput.replace(/[\s#()-]/g, ''),
      firstName: this.state.inputTargets.firstNameInput,
      lastName: this.state.inputTargets.lastNameInput,
      specialistId: this.state.specialistId,
      organizations,
      roles: []
    }
    Object.keys(this.state.selectedRoles).forEach((role) => {
      if (this.state.selectedRoles[role] === true) {
        invite.roles.push(role)
      }
    })

    this.props.onSave(invite)
  }

  roleChanged(role, isChecked) {
    const { selectedRoles } = this.state
    selectedRoles[role] = isChecked
    selectedRoles.consumer = true
    this.setState({
      selectedRoles
    })
  }

  onOrgChange(orgId, selected) {
    const { tenantOrgs } = this.state
    const selectedOrg = tenantOrgs.find(({ id }) => id === orgId)
    if (selectedOrg) {
      selectedOrg.selected = selected
    }
    this.setState({ tenantOrgs })
  }

  renderError() {
    const error = this.props.errorMessage
    if (!error || error.length === 0) {
      return null
    }
    return <div>{error}</div>
  }

  onSpecialistSelect = (specialistId, specialist) => {
    // this.refs.specialistInput.clear()
    this.setState((previousState) => {
      let state = { ...previousState }
      delete state.specialistId
      state = {
        ...state,
        specialistId,
        specialistSearch: specialist.fullName
      }
      state = this.specialistValidationState(state)
      return state
    })
  }

  specialistValidationState = (previous: State = this.state) => {
    if (!previous.specialistId) {
      return {
        ...previous,
        invalid: {
          ...previous.invalid,
          specialistInput: 'Please choose a specialist from the list'
        }
      }
    }
    delete previous.invalid.specialistInput
    return previous
  }

  onSpecialistSearch = (event, value = event.target?.value) => {
    const searchTerm = (value && value.toLowerCase()) || ''
    const filtered = this.state.allSpecialists.filter((specialist) => {
      const { fullName } = specialist
      return fullName && fullName.toLowerCase().includes(searchTerm)
    })
    this.setState((previousState) => {
      let state = { ...previousState }
      delete state.specialistId
      state = this.specialistValidationState(state)

      return {
        ...state,

        filteredSpecialists: filtered,
        specialistSearch: value
      }
    })
  }

  validateInputs = () => {
    const { invalid = {} } = this.state.invalid
    const requiredError = 'This field is required, please fill it'
    const firstName = this.state.inputTargets.firstNameInput
    const lastName = this.state.inputTargets.lastNameInput
    const phone = this.state.inputTargets.phoneInput
    // eslint-disable-next-line unicorn/better-regex
    const phoneRegex = /^[+]*\(?([0-9]{1,3})\)?[-.]?([0-9]*)[-.]?([0-9]*)$/
    const phoneErrorMessage = 'Phone number invalid'
    if (!firstName) {
      invalid.firstNameInput = requiredError
    }
    if (!lastName) {
      invalid.lastNameInput = requiredError
    }
    if (!phone || !phoneRegex.test(phone)) {
      invalid.phoneInput = phoneErrorMessage
    }

    // this.setState((prev) => {
    //   return { ...prev, invalid }
    // })

    return invalid
  }

  validatePhone({ target }) {
    const ErrorMessage = 'Phone number invalid'
    const phoneInput = target.value.replace(/[\s#()-]/g, '')
    const inputTargets = {
      ...this.state.inputTargets,
      phoneInput
    }

    if (!target.checkValidity()) {
      this.setState({
        invalid: { phoneInput: ErrorMessage },
        inputTargets
      })
    } else if (this.state.invalid && this.state.invalid.phoneInput) {
      this.setState((previousState) => {
        const newState = { ...previousState }
        if (previousState.invalid && previousState.invalid.phoneInput) {
          delete newState.invalid.phoneInput
        }
        newState.inputTargets = inputTargets
        return newState
      })
    } else {
      this.setState({ inputTargets })
    }
  }

  setInputValueToState = (type: string) => {
    return ({ target }) => {
      this.setState((previousState) => {
        const { invalid = {} } = previousState
        if (!target.value) {
          invalid[type] = 'This field is required, please fill it'
          return {
            ...previousState,
            invalid
          }
        }
        if (invalid[type] && target.value) {
          delete invalid[type]
        }
        return {
          ...previousState,
          invalid,
          inputTargets: {
            ...previousState.inputTargets,
            [type]: target.value
          }
        }
      })
    }
  }

  render() {
    const buttonsDisabled =
      this.props.isSending || Object.values(this.state.invalid).length > 0
    const saveButtonText = this.props.isSending ? 'Saving...' : 'Save'
    const invalidSpecialistSearch =
      this.state.invalid && this.state.invalid.specialistInput
    const specialistErrorClassName = invalidSpecialistSearch
      ? 'validation-error'
      : ''

    return (
      <Modal
        className="sh-modal"
        show
        backdrop
        backdropClassName="modal-shadow-background"
        onHide={this.props.onHide}
      >
        <Modal.Body>
          <h1 className="title"> Invite User </h1>
          <Form>
            <FormGroup>
              <ControlLabel className="requiredCtrl">First Name</ControlLabel>
              <FormControl
                required
                type="text"
                onChange={this.setInputValueToState('firstNameInput')}
                // onFocus={this.setInputValueToState('firstNameInput')}
                id="org-invite-firstName-input"
                className={
                  this.state.invalid && this.state.invalid.firstNameInput
                    ? 'validation-error'
                    : ''
                }
              />
              {this.state.invalid && this.state.invalid.firstNameInput && (
                <p className="validation-error">
                  {this.state.invalid.firstNameInput}
                </p>
              )}
              <ControlLabel className="requiredCtrl">Last Name</ControlLabel>
              <FormControl
                required
                type="text"
                onChange={this.setInputValueToState('lastNameInput')}
                // onFocus={this.setInputValueToState('lastNameInput')}
                id="org-invite-lastName-input"
                className={
                  this.state.invalid && this.state.invalid.lastNameInput
                    ? 'validation-error'
                    : ''
                }
              />
              {this.state.invalid && this.state.invalid.lastNameInput && (
                <p className="validation-error">
                  {this.state.invalid.lastNameInput}
                </p>
              )}
              <ControlLabel className="requiredCtrl">Phone Number</ControlLabel>
              <FormControl
                required
                type="text"
                pattern="^[\+]*\(?([0-9]{1,3})\)?[-.]?([0-9]*)[-.]?([0-9]*)$"
                onChange={this.validatePhone}
                title="Should be only numbers"
                id="org-invite-phone-input"
                className={
                  this.state.invalid && this.state.invalid.phoneInput
                    ? 'validation-error'
                    : ''
                }
              />
              {this.state.invalid && this.state.invalid.phoneInput && (
                <p className="validation-error">
                  {this.state.invalid.phoneInput}
                </p>
              )}
              <ControlLabel>Roles</ControlLabel>
              <div className="col-xs-12">
                <div className="col-xs-6">
                  <ControlLabel className="requiredCtrl">Roles</ControlLabel>
                  <UserRolesForm
                    userRoles={this.state.selectedRoles}
                    onSelect={this.roleChanged}
                  />
                </div>
                {this.state.tenantOrgs && this.state.tenantOrgs.length > 0 && (
                  <div className="col-xs-6">
                    <ControlLabel className="requiredCtrl">
                      Organizations
                    </ControlLabel>
                    <TenantOrgsForm
                      tenantOrgs={
                        this.state.tenantOrgs as {
                          name: string
                          id: string
                          selected: boolean
                        }[]
                      }
                      onSelect={this.onOrgChange}
                    />
                  </div>
                )}
              </div>
              <ControlLabel className="requiredCtrl">Specialist</ControlLabel>

              <Typeahead
                id="basic-typeahead-single"
                dropup
                flip
                clearButton
                onChange={([specialist]) => {
                  if (specialist && specialist.id) {
                    this.onSpecialistSelect(specialist.id, specialist)
                  }
                }}
                onInputChange={(event) => {
                  if (!this.state.specialistSearch || !event.target?.value) {
                    this.onSpecialistSearch(event)
                  }
                }}
                onBlur={(event) => {
                  if (!this.state.specialistSearch || !event.target?.value) {
                    this.setState((previous) => {
                      const state = { ...previous }
                      delete state.specialistId
                      return this.specialistValidationState(state)
                    })
                  }
                }}
                ref="specialistInput"
                className={`basic-typeahead-single ${specialistErrorClassName}`}
                // isValid={!invalidSpecialistSearch}
                options={this.state.allSpecialists}
                labelKey="fullName"
                placeholder="Search for a specialist..."
                // value={this.state.specialistSearch}
              />

              {this.state.invalid && this.state.invalid.specialistInput && (
                <p className="validation-error">
                  {this.state.invalid.specialistInput}
                </p>
              )}
            </FormGroup>
          </Form>
          {this.renderError()}
          <div>
            <button
              className="sh-btn btn-default"
              onClick={this.props.onHide}
              disabled={buttonsDisabled}
              type="button"
            >
              Cancel
            </button>
            <button
              className="sh-btn btn-default"
              onClick={this.saveClicked}
              disabled={buttonsDisabled}
              type="button"
            >
              {saveButtonText}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
