import * as React from 'react';
import SurveyResponseModal from './SurveyResponseModal'
import moment from 'moment'

export default class BasicActivityModal extends React.Component {

  static propTypes = {
    activity: React.PropTypes.object.isRequired,
    onHide: React.PropTypes.func.isRequired
  };

  render() {
    const activity = this.props.activity
    const views = activity.viewedTimestamps ? Object.values(activity.viewedTimestamps) : []
    let body = null
    if (activity.link) {
      const url = activity.link.startsWith("http") ? activity.link : `https://${activity.link}`
      body = (<a href={url} target="_blank">{activity.link}</a>)
    } else if (activity.body) {
      body = (activity.body)
    }
    const timesText = views.length == 1 ? 'time' : 'times'
    return (
      <SurveyResponseModal name={'Assigned Content: ' + activity.name} {...this.props}>
        <div className="survey-question">{body}</div>
        <div className="survey-question">{`Viewed ${views.length} ${timesText}`}</div>
        {views.map(timestamp => {
          const date = new Date(timestamp * 1000)
          return <div className="survey-answer">{moment(date).format("dddd, MMMM D, YYYY - h:mm a")}</div>
        })}
      </SurveyResponseModal>
    )
  }
}
