import firebase from 'firebase/app'
import { getTenantRoles } from './Roles';
import { successes } from '../util/promise_utils';
import { Observable } from 'rxjs';
import { fromDocRef } from 'rxfire/firestore';
import { map } from 'rxjs/operators';

const firestore = firebase.firestore()

export type User = {
  id: string
  fullName: string
  firstName: string
  lastName: string
  phoneNumber: string
  email?: string
}

export async function getTenantUsers(tenantId: string): Promise<User[]> {
  const roles = await getTenantRoles(tenantId)
  const fetchUsers = roles.map((role) => {
    return getUser(role.userId)
  })
  return successes(fetchUsers)
}

export function observeUser(id: string): Observable<User> {
  const doc = firestore.doc(`users/${id}`)
  return fromDocRef(doc).pipe(
    map((snapshot: firebase.firestore.DocumentSnapshot): User => {
      if (!snapshot.exists) {
        throw new Error(`User ${id} not found`)
      }
      return snapshot.data() as User
    })
  )
}

export async function getUser(id: string): Promise<User> {
  try {
    const snapshot = await firestore.doc(`users/${id}`).get()
    if (!snapshot.exists) {
      throw new Error(`User ${id} not found`)
    }
    return snapshot.data() as User
  } catch (error) {
    console.warn('Error fetching user', id, error)
    throw error
  }
}

export async function updateUser(user: User): Promise<void> {
  try {
    await firestore.doc(`users/${user.id}`).set(user, { merge: true })
  } catch (err) {
    console.error("Error updating user", user, err)
    throw err
  }
}