import * as React from 'react';
import { Table } from '@karla/karla-react-components';
import DeleteOrgModal from './DeleteOrgModal'
import classNames from 'classnames'
import OrgRow from './OrgRow'
import { TenantOrganization, observeTenantOrgs, getTenantOrgUserCount, deleteTenantOrg } from '../../db/Orgs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

class OrgListHeader extends React.Component {
  render() {
    return (
      <thead>
        <tr>
          <th width="150">Name</th>
          <th width="75">Users</th>
          <th width="75">Actions</th>
        </tr>
      </thead>
    );
  }
}

type Props = {
  tenantId: string
  orgSelected: (id: string) => void
}

type State = {
  orgs: TenantOrganization[]
  orgUsersCount: {[orgId: string]: number}
  selectedOrgId: string | null
  deleteOrgId: string | null
}

export default class OrgList extends React.Component<Props, State> {
  subscription: Subscription | null

  constructor(props) {
    super(props)
    this.state = {
      orgs: [],
      orgUsersCount: {},
      selectedOrgId: null,
      deleteOrgId: null
    }
  }

  componentDidMount() {
    this.reload()
  }

  componentDidUpdate(prevProps) {
    if (this.props.tenantId !== prevProps.tenantId) {
      this.reload()
    }
  }

  reload() {
    this.dispose()
    observeTenantOrgs(this.props.tenantId)
      .pipe(
        map(orgs => orgs.sort(sortOrgs))
      )
      .subscribe((orgs) => {
        this.setState({
          orgs
        })
        this.getOrgUserCounts(orgs)
      }, error => {
        console.error("Error getting tenant orgs ", error)
      })
  }

  async getOrgUserCounts(orgs: TenantOrganization[]) {
    type UserCount = {
      orgId: string
      userCount: number
    }
    const getCounts: Promise<UserCount>[] = []
    for (const org of orgs) {
      getCounts.push(
        getTenantOrgUserCount(this.props.tenantId, org.id)
          .then(count => {
            return {orgId: org.id, userCount: count}
          })
      )
    }
    const counts = await Promise.all(getCounts)
    const allCounts: {[orgId: string]: number} = {}
    for (const count of counts) {
      allCounts[count.orgId] = count.userCount
    }
    this.setState({
      orgUsersCount: allCounts
    })
  }

  componentWillUnmount() {
    this.dispose()
  }

  dispose() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  rowSelected(key) {
    this.setState({
      selectedOrgId: key
    })
    this.props.orgSelected(key)
  }
  
  deleteOrgClicked(key) {
    this.setState({
      deleteOrgId: key
    })
  }
  
  dismissModal() {
    this.setState({
      deleteOrgId: null
    })
  }
  
  confirmDeleteOrg() {
    this.dismissModal()
    const { deleteOrgId } = this.state
    if (deleteOrgId) {
      deleteTenantOrg(this.props.tenantId, deleteOrgId)
    }
  }

  renderDeleteModal() {
    const { deleteOrgId } = this.state
    if (!deleteOrgId) {
      return null
    }
    const orgToDelete = this.state.orgs.filter(org => org.id === deleteOrgId)[0]
    const userCount = this.state.orgUsersCount[deleteOrgId] || 0
    return <DeleteOrgModal orgName={orgToDelete.name}
                            userCount={userCount}
                            deleteOrg={this.confirmDeleteOrg.bind(this)}
                            dismiss={this.dismissModal.bind(this)} />
  }
  
  render() {
    
    const { orgs } = this.state

    return (
      <Table responsive className="sh-list-view bordered-box table-middle-align">
        <OrgListHeader />
        {this.renderDeleteModal()}
        <tbody>
          {orgs.map(this.renderRow.bind(this))}
        </tbody>
      </Table>
    );
  }
  
  renderRow(org, index) {

    let orgId = org.id
    
    let isSelected = orgId == this.state.selectedOrgId
    let isTinted = !isSelected && index % 2 == 0
    
    var rowClass = classNames({
      'row-selected': isSelected,
      'row-tinted': isTinted
    })

    const userCount = this.state.orgUsersCount[orgId] || 0

    return <OrgRow key={orgId}
                   orgId={orgId}
                   class={rowClass}
                   name={org.name}
                   userCount={userCount}
                   handleSelection={this.rowSelected.bind(this, orgId)}
                   handleDelete={this.deleteOrgClicked.bind(this, orgId)} />
  }
}

function sortOrgs(a: TenantOrganization, b: TenantOrganization) {
  if (a.id == 'global') {
    return Number.MIN_SAFE_INTEGER
  }
  if (b.id == 'global') {
    return Number.MAX_SAFE_INTEGER
  }
  if(a.name < b.name) return -1
  if(a.name > b.name) return 1
  return 0
}