import * as React from 'react'
import { Grid, Row, Col } from '@karla/karla-react-components'
import SpecialistRepository from '~/db/SpecialistRepository'
import { URN } from '@karla/karla-core'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'

import { ClinicalDataDashboard } from './clinical/ClinicalDataDashboard'
import { ClientDataDashboard } from './clientdata/ClientDataDashboard'
import { KarlaChatContainer } from './chat/KarlaChatContainer'
import { KarlaLogContainer } from './logs/KarlaLogContainer'
import { SpecialistChatContainer } from './chat/SpecialistChatContainer'
import { WhenDoContainerTab } from '../whendo/containers/WhenDoContainerTab'
import { NewFlowModal, FlowTypes } from '../whendo/components/NewFlowModal'
import { WhenDoEditor } from '../whendo/containers/WhenDoEditor'
import Microfrontend from '../whendo/components/Microfrontend'
import { SessionContainer } from './scheduling/SessionContainer'
import { browserHistory } from 'react-router'
import { getUser } from '../../db/Users';
import { saveRecipe, Recipe } from '../../db/Recipes'
import WhenDoSaveModal from '../whendo/containers/WhenDoSaveModal'
import { TemplateComponent } from '../../common/ui/Spinner'
import APIGatewayClient from '../../api/APIGatewayClient'
import Datastore from '~/db/Datastore'
import {extractMetrics} from '../whendo/lib/util';


const { REACT_APP_WHENDO_HOST: whendoHost } = process.env

@connect(
  state => ({ 
    user: state.auth,
    tenantId: state.auth.selectedRole.tenantId
  })
)
class TelehealthClientDashboard extends React.Component {

  constructor(props) {
    super(props)
    this.specialistRepo = new SpecialistRepository()
    this.apiClient = new APIGatewayClient()
    this.datastore = new Datastore()

    const self = (this.props.params.clientId == this.props.user.uid)

    this.state = {
      clientName: null,
      specialistId: null,
      sessions: [],
      key: self ? 4 : 0,
      whendoEditRecipe: null,
      whendoRecipeActionType: null, // CREATE, UPDATE, DELETE
      showNewFlowModal: false,
      saveModal: false,
      showSpinner: false
    }
  }

  componentDidMount() {
    this.getClientSpecialistData_(this.props)
    this.setState({ saveModal: false })
  }

  componentWillReceiveProps(props) {
    if (props.params.clientId != this.props.params.clientId) {
      this.getClientSpecialistData_(props)
      this.onWhenDoEditHide()
    }
  }

  getClientSpecialistData_(props) {
    this.getClientName(props.params)

    if (props.params.clientId == props.user.uid) {
      this.setState({
        specialistId: null,
        key: (this.state.key == 0) ? 2 : this.state.key
      })
      this.getSpecialistId(props)
    }
    else
      this.setState({
        specialistId: props.user.uid
      })
  }

  getClientName({ clientId }) {
    getUser(clientId).then(user => {
      this.setState({
        clientName: user.fullName,
        clientFirstName: user.firstName
      })
    })
    .catch(console.error)
  }

  getSpecialistId(props) {
    this.specialistRepo.getSpecialistIdForUser(props.user.uid).then(specialistId => {
      this.setState({
        specialistId: specialistId
      })
    })
    .catch(console.error)
  }

  // WHENDO FLOW EDIT MODALS
  onWhenDoEditShow(recipe, shouldSaveAsCopy) {
    if (shouldSaveAsCopy) {
      delete recipe["id"]
      delete recipe[URN.SERVER.TIMESTAMP]
    }
    this.setState({ whendoEditRecipe: recipe, whendoRecipeActionType: "UPDATE", isCopy: shouldSaveAsCopy, showSpinner: true})
  }

  onWhenDoEditHide() {
    this.setState({ whendoEditRecipe: null, whendoRecipeActionType: null, saveModal: false, showSpinner: false })
  }

  inspectRecipe(recipe) {
    browserHistory.push(`whendo/flows/${recipe.id}?userId=${this.props.params.clientId}`)
  }

  onWhenDoCreateShow() {
    this.setState({
      showNewFlowModal: true
    })
  }

  onCancelCreateFlow() {
    this.setState({
      showNewFlowModal: false
    })
  }

  renderNewFlowModal() {
    if (!this.state.showNewFlowModal) {
      return null
    }
    return (
      <NewFlowModal onSave={this.didSelectNewFlowType.bind(this)} onHide={this.onCancelCreateFlow.bind(this)} />
    )
  }

  didSelectNewFlowType(flowType) {
    var emptyRecipe = {
      [URN.RECIPE.JS]: '',
      [URN.RECIPE.XML]: '',
      [URN.RECIPE.TITLE]: '',
      [URN.RECIPE.ASSIGNEDTO]: this.props.clientId,
    }
    if (flowType == FlowTypes.js) {
      emptyRecipe[URN.RECIPE.JS] = ''
    } else if (flowType == FlowTypes.xml) {
      emptyRecipe[URN.RECIPE.XML] = ''
    } else {
      throw new Error('Unknown flow type: ' + flowType)
    }
    emptyRecipe[URN.RECIPE.TYPE] = flowType
    this.setState({ 
      whendoEditRecipe: emptyRecipe,
      showSpinner: true,
      whendoRecipeActionType: "CREATE",
      showNewFlowModal: false
    })
  }

  onWhenDoSave(recipe) {
    saveRecipe(this.props.tenantId, recipe).then(() => {
      this.logWhendoMetric(recipe)
      this.onWhenDoEditHide()
    })
  }

  saveRecipeWithName({ name, assigneeId, assigneeType, isActive }) {
    let recipe = this.state.whendoEditRecipe as Recipe
    recipe[URN.RECIPE.ENABLED] = isActive
    recipe[URN.RECIPE.TITLE] = name
    recipe[URN.RECIPE.ASSIGNEDTO] = assigneeId ? assigneeId : null
    recipe[URN.RECIPE.ASSIGNEETYPE] = assigneeType ? assigneeType : null

    // TODO: implement this
    if (this.state.isCopy) {
      const newCodeID = uid(25) as string
      // @ts-ignore
      recipe = this.modifySourceCodeWithNewId(newCodeID)
    }

    this.onWhenDoSave(recipe)
  }

  clickSave(recipe) {
    this.setState({ whendoEditRecipe: recipe, saveModal: true })
  }
  dismissSaveModal() {
    this.setState({
      saveModal: false
    })
  }
  hideSpinner() {
    this.setState({showSpinner: false})
  }
  createSaveModal() {
    if (this.state.saveModal && this.state.whendoEditRecipe) {
      return (
        <WhenDoSaveModal
          selectedId={this.state.whendoEditRecipe[URN.RECIPE.ASSIGNEDTO]}
          selectedType={this.state.whendoEditRecipe[URN.RECIPE.ASSIGNEETYPE]}
          onHide={() => this.dismissSaveModal()}
          onConfirm={(...arguments_) => this.saveRecipeWithName(...arguments_)}
          recipeName={this.state.whendoEditRecipe[URN.RECIPE.TITLE]}
          active={this.state.whendoEditRecipe[URN.RECIPE.ENABLED]}
        />
      )
    }
    return null
  }

  onWhenDoEditRender() {
    const recipe = this.state.whendoEditRecipe
    const params = new URLSearchParams(window.location.search)
    const showSpinner = this.state.showSpinner
    return (
      <div className='row'>
        <div className='col-xs-12'>
         {
            (!params.has('whendo')) ?
            <WhenDoEditor
            onSave={this.onWhenDoSave.bind(this)}
            onClose={this.onWhenDoEditHide.bind(this)}
            authorId={this.props.userId}
            isCopy={this.state.isCopy}
            recipe={recipe}
            />
          
          :
           (showSpinner && <TemplateComponent/>)}
         {params.has('whendo') && <Microfrontend
            host={whendoHost}
            name="WhenDoEditorComponent"
            onClose={() => this.onWhenDoEditHide()}
            loadedCallback = {() => this.hideSpinner()}
            saveWhenDo={(recipe) => this.clickSave(recipe)}
            authorId={this.props.userId}
            initialRecipe={recipe}
            isCopy={this.state.isCopy}
          />}
        </div>
      </div>
    )
  }

  async logWhendoMetric(recipe) {
    const whendoMetric = await extractMetrics(
      recipe,
      this.props.user,
      this.state.whendoRecipeActionType
    )

    console.log('POST METRIC :>> ', whendoMetric);

    this.apiClient.sendFlowEditorMetric(whendoMetric).catch((err) => {
      console.log('WhenDo METRIC Error ', err)
    })
  }

  //Tab Save UI State
  handleSelect(key) {
    this.setState({ key });
  }

  render() {

    const { clientName, specialistId, clientFirstName } = this.state
    const clientId = this.props.params.clientId

    const noPadding = { padding: "0px" }

    const isLoading = (!specialistId || (!clientName && clientName != ""));
    const self = (clientId == this.props.user.uid)

    var empty = (
      <div className='container-body'><div className='chat-container'>
        <div className='dashboard-panel-header'>Connecting to Life Context Graph</div>
      </div></div>
    );

    var notavailable = (
      <div className='container-body'><div className='chat-container'>
        <div className='dashboard-panel-header'>Not available for self</div>
      </div></div>
    );

    const isVisible = (key) => {
      return this.state.key == key
    }

    return (
      <div id='body'  >
        {this.renderNewFlowModal()}
        {this.state.saveModal && this.createSaveModal()}
        {this.state.whendoEditRecipe ? this.onWhenDoEditRender() : null}
        <Grid className={this.state.whendoEditRecipe ? 'hidden' : ''}>
          <Row>
            <h1>{`${clientName} Dashboard`}</h1>
          </Row>
          <Row>
            <Col md={self ? 12 : 9} sm={12}>
              <Row>
                <div className="container-body" style={noPadding}>
                  <Tabs activeKey={this.state.key} onSelect={this.handleSelect.bind(this)} animation={false} id="client-dashboard-tabs">
                    {<Tab eventKey={0} title="Clinical Data">
                      {isLoading ? empty : <ClinicalDataDashboard clientId={clientId} isVisible={isVisible(0)}/>}
                    </Tab>}
                    <Tab eventKey={1} title="Client Data">
                      {isLoading ? empty : <ClientDataDashboard clientId={clientId} isVisible={isVisible(1)} />}
                    </Tab>
                    <Tab eventKey={2} title="Karla Data">
                      {isLoading ? empty : <KarlaChatContainer self={self} specialistId={specialistId} clientId={clientId} firstName={clientFirstName} isVisible={isVisible(2)} />}
                    </Tab>
                    <Tab eventKey={3} title="Log">
                      {isLoading ? empty : <KarlaLogContainer clientId={clientId} isVisible={isVisible(3)} />}
                    </Tab>
                    <Tab eventKey={4} title="When-Do">
                      {this.state.whendoEditRecipe ? null :
                        (isLoading ? empty :
                          <WhenDoContainerTab
                            tenantId={this.props.tenantId}
                            specialistId={specialistId}
                            clientId={clientId}
                            clientName={clientName}
                            onEdit={this.onWhenDoEditShow.bind(this)}
                            onCreate={this.onWhenDoCreateShow.bind(this)}
                            onInspect={this.inspectRecipe.bind(this)}
                            isVisible={isVisible(4)} 
                            logWhendoMetric={this.logWhendoMetric.bind(this)}
                          />
                        )}
                    </Tab>
                  </Tabs>
                </div>
              </Row>
              <Row>
                {(isLoading || self) ? null : <SpecialistChatContainer senderId={specialistId} receiverId={clientId} />}
              </Row>
            </Col>
            {self ? null :
              <Col md={3} sm={12}>
                {(isLoading) ? null : <SessionContainer specialistId={specialistId} clientId={clientId} firstName={clientFirstName} />}
              </Col>}
          </Row>
        </Grid>
      </div>
    )
  }
}
export { TelehealthClientDashboard }