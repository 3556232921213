import * as React from 'react'
import { connect } from 'react-redux'
import AssignedContentList from './AssignedContentList'
import ArchivedContentList from './ArchivedContentList'
import AssignContentModal, { ContentTypes } from './modals/AssignContentModal'
import SurveyRepository from '~/db/SurveyRepository'
import DialogRepository from '~/db/DialogRepository'
import DataReportingRepository from '../../../db/dataReportingRepository'
import SurveyScoresList from './SurveyScoresList'

const mapStateToProps = state => {
  const auth = state.auth
  return {
    role: auth.selectedRole && auth.selectedRole.role,
    userId: auth.uid
  }
}

@connect(mapStateToProps)
class ClientTables extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      showCreateModal: false,
      edittingAssignment: null,
      surveys: [],
      dialogs: [],
    }
    this.onHide = this.close.bind(this)
    this.onConfirm = this.assignmentConfirmed.bind(this)
    this.onClickAssignNew = this.open.bind(this)
    this.onClickEditAssignment = this.editAssignmentClicked.bind(this)
    this.surveyRepo = new SurveyRepository()
    this.dialogRepo = new DialogRepository()
    this.dataReportingRepository = new DataReportingRepository({
      userId: props.userId,
      role: props.role,
    })
  }

  componentDidMount() {
    this.getAllSurveys()
    this.getAllDialogs()
  }

  close() {
    this.setState({
      showModal: false,
      edittingAssignment: null,
    })
  }

  open() {
    this.setState({ showModal: true })
  }

  getAllDialogs() {
    this.dialogRepo.getAllDialogs().then(dialogFromRepo => {
      this.setState({
        dialogs: dialogFromRepo,
      })
    })
  }

  getAllSurveys() {
    this.surveyRepo.getAllSurveys().then(surveysFromRepo => {
      this.setState({
        surveys: surveysFromRepo,
      })
    })
  }

  assignmentConfirmed(assignment) {
    const {scoring = null} = assignment
    if (assignment.id) {
      this.surveyRepo.updateAssignment(assignment, this.props.clientId)
    } else {
      if (scoring || assignment.type === ContentTypes.SURVEY) { 
        this.dataReportingRepository.updateContentAssignmentCounter(1)
      }
      this.surveyRepo.saveAssignment(assignment, this.props.clientId)
    }
    this.close()
  }

  editAssignmentClicked(assignment) {
    this.setState({
      showModal: true,
      edittingAssignment: assignment,
    })
  }

  render() {
    const showModal = this.state.showModal || this.state.edittingAssignment
    return (
      <div id="client-tables">
        <SurveyScoresList clientId={this.props.clientId} />
        <p>Assigned Content</p>
        <a className="links" onClick={this.onClickAssignNew}>
          Assign New Content
        </a>
        <AssignedContentList clientId={this.props.clientId} onEdit={this.onClickEditAssignment} />
        <p>Archived Content</p>
        <ArchivedContentList clientId={this.props.clientId} />
        <AssignContentModal
          show={showModal}
          onHide={this.onHide}
          onConfirm={this.onConfirm}
          selectedActivity={this.state.edittingAssignment}
          surveys={this.state.surveys}
          dialogs={this.state.dialogs}
        />
      </div>
    )
  }
}
export default ClientTables
