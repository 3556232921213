import * as React from 'react'
import { Table } from '@karla/karla-react-components'
import SurveyRepository from '~/db/SurveyRepository'
import IntakeResponseModal from './modals/IntakeResponseModal'
import BasicActivityModal from './modals/BasicActivityModal'
import MultipleChoiceResponseModal from './modals/MultipleChoiceResponseModal'
import BucketSurveyResponseModal from './modals/BucketSurveyResponseModal'
import RDXDialogResponseModal from './modals/RDXDialogResponseModal'
import moment from 'moment'
import { objectFromArray } from '~/common/lib/object_helpers'

export default class ContentAssignmentList extends React.Component {

  static propTypes = {
    onEdit: React.PropTypes.function,
    clientId: React.PropTypes.string
  }

  constructor(props) {
    super(props)
    this.surveyRepo = new SurveyRepository()
    this.state = {
      content: [],
      responsesById: {},
      selectedAssignment: null
    }
    this.onHideModal = this.onHideResponseModal.bind(this)
  }

  componentDidMount() {
    this.getAssignedContent(this.props.clientId)
  }

  componentWillReceiveProps(props) {
    this.getAssignedContent(props.clientId)
  }

  getAssignedContent(clientId) {
    const renderContent = (content) => {
      let assignmentIds = content.map(content => content.id)
      const getResponses = assignmentIds.map(id => {
        return this.surveyRepo.getLatestSurveyResponse(clientId, id)
      })
      Promise.all(getResponses).then(responses => {
        const responsesById = objectFromArray(responses, 'id')
        this.setState({
          content,
          responsesById
        })
      })
    }
    if (this.state.archived) {
      this.surveyRepo.getArchivedContent(clientId, renderContent)
    } else {
      this.surveyRepo.getAssignedContent(clientId, renderContent)
    }
  }

  clickReviewAssignment(assignment) {
    this.setState({
      selectedAssignment: assignment
    })
  }

  clickEditAssignment(assignment) {
    this.props.onEdit(assignment)
  }

  renderAssignedContent() {
    const isArchived = this.state.archived
    return this.state.content.map(assignment => {
      const name = assignment.name
      const dateStr = moment.unix(assignment.created).format("MM/DD/YY")
      let progress;
      if (assignment.survey || (assignment.dialog && assignment.scoring)) {
        if (assignment.completed) {
          progress = 'Complete'
        } else {
          progress = 'Incomplete'
        }
      } else {
        const views = assignment.viewedTimestamps || {}
        const viewCount = Object.keys(views).length
        const times = viewCount == 1 ? 'time' : 'times'
        progress = `Viewed ${viewCount} ${times}`
      }

      const reviewButton = (<a onClick={this.clickReviewAssignment.bind(this, assignment)}>Review</a>)
      let actionButtons = null
      if (isArchived || assignment.isIntake) {
        actionButtons = (<td>{reviewButton}</td>)
      } else {
        actionButtons = (<td>{reviewButton} / <a onClick={this.clickArchiveContent.bind(this, assignment)}>Archive</a></td>)
      }
      return (
        <tr key={assignment.id}>
          <td>{name}</td>
          <td>{dateStr}</td>
          <td>{progress}</td>
          {actionButtons}
        </tr>
      )
    })
  }

  renderProgressModal() {
    const selectedAssignment = this.state.selectedAssignment
    if (!selectedAssignment) {
      return null
    }
    switch (selectedAssignment.type) {
      case 'link':
        return (<BasicActivityModal activity={selectedAssignment} onHide={this.onHideModal}/>)
      case 'openEnded':
        return (<BasicActivityModal activity={selectedAssignment} onHide={this.onHideModal}/>)
      case 'reactive-dialog':
        var response = this.state.responsesById[selectedAssignment.id]
        return <RDXDialogResponseModal assignment={selectedAssignment} response={response} onHide={this.onHideModal}/>
      case 'survey':
      case null:
      case undefined:
        var response = this.state.responsesById[selectedAssignment.id]
        if (!response) {
          return null
        }
        if (response.type == 'intake') {
          return (
            <IntakeResponseModal response={response} onHide={this.onHideModal} />
          )
        } else if (response.type == 'bucketSurvey') {
          return (
            <BucketSurveyResponseModal assignment={selectedAssignment} response={response} onHide={this.onHideModal}/>
          )
        } else {
          return <MultipleChoiceResponseModal assignment={selectedAssignment} response={response} onHide={this.onHideModal}/>
        }
    }
  }

  onHideResponseModal() {
    this.setState({
      selectedAssignment: null
    })
  }

  render() {
    return (
      <div className='row'>
        <div className='col-xs-12'>
          {this.renderProgressModal()}
          <Table striped>
            <thead>
              <tr>
                <th>Content</th>
                <th>Date Assigned</th>
                <th>Progress</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
               {this.renderAssignedContent()}
             </tbody>
          </Table>
        </div>
      </div>
    )
  }
}
