import * as React from 'react';
import { Grid, Row } from '@karla/karla-react-components';
import { browserHistory } from 'react-router'

export default class extends React.Component {

  loginClicked() {
    browserHistory.push('/sms');
  }

  registerClicked() {
    browserHistory.push('/register');
  }

  render() {
    return (
      <Grid>
        <Row>
          <button className='sh-btn onboarding-btn full-width' onClick={this.loginClicked}>
            <span>Login To My Account</span>
          </button>
        </Row>
      </Grid>
    );
  }
}
