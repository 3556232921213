import * as React from 'react';
import ContentAssignmentList from './ContentAssignmentList'
import { Table } from '@karla/karla-react-components';
import SurveyRepository from '~/db/SurveyRepository'
import IntakeResponseModal from './modals/IntakeResponseModal'
import MultipleChoiceResponseModal from './modals/MultipleChoiceResponseModal'
import moment from 'moment'
import { objectFromArray } from '~/common/lib/object_helpers'

export default class ArchivedContentList extends ContentAssignmentList {
  
  constructor(props) {
    super(props)
    this.state.archived = true
  }
}
