import * as React from 'react';
import { Row, Col, Checkbox, Grid } from '@karla/karla-react-components';
import { ROLES} from '@karla/karla-core';
import { Role } from '../../db/Roles';
import { TenantOrganization } from '../../db/Orgs';

type Props = {
  orgsById: {[orgId: string]: TenantOrganization}
  userOrgs: {[role: string]: boolean}
  userRoles: object
  onAddOrgClicked: () => void
  onDeleteOrgClicked: (orgId: string) => void
  getAvailableOrgs: () => TenantOrganization[]
  onRolesChanged: (role: Role, selected: boolean) => void
}

export default class UserOrgForm extends React.Component<Props, {}> {

  onDeleteClicked = (orgId: string) => {
    this.props.onDeleteOrgClicked(orgId)
  }

  onRoleChanged = (role: Role, enabled: boolean) => {
    this.props.onRolesChanged(role, enabled)
  }

  getOrgs(orgIds: string[], orgsById: {[id: string]: TenantOrganization}) {
    return orgIds.map(orgId => {
      const org = orgsById[orgId];
      if (!org) {
        return null
      }
      return (
        <li className="org-name" key={orgId}>
          <div>
            {org.name}
            {org.id === 'global' ? 
              null : 
              <div 
                className="delete-x-container" 
                onClick={this.onDeleteClicked.bind(this, org.id)}>
                  &times;
              </div>
            }
          </div>
        </li>
      )
    })
  }

  renderRoles() {
    const userRoles = this.props.userRoles
    return (
    <div>
      {ROLES.allDescending().map(role => {
        const selected = userRoles[role] === true
        return (
          <Checkbox
              key={role}
              onClick={this.onRoleChanged.bind(this, role, !selected)}
              checked={selected}>
              {role}
            </Checkbox>
        )
      })}
    </div>)
  }

  render() {
    const { orgsById, userOrgs } = this.props
    const orgIds = Object.keys(userOrgs)
    const availableOrgs = this.props.getAvailableOrgs().length > 0

    return (
      <div>
        <Grid>
          <Col className="org-form-column">
            <Row>Roles</Row>
            {this.renderRoles()}
          </Col>
          <Row className="org-form-row">
            <Col className="org-form-column">
              Organizations
            </Col>
          </Row>
          <ul>
          {this.getOrgs(orgIds, orgsById)}
          </ul>
        </Grid>
        {availableOrgs && <a onClick={this.props.onAddOrgClicked} > + Click to assign a new organization </a>}
        <hr />
      </div>
    )
  }
}
