import { AuthManager } from '~/common/lib/AuthManager'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

import {
  Router, match, RouterContext, applyRouterMiddleware,
  hashHistory, browserHistory
} from 'react-router'

import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { syncHistoryWithStore, routerReducer } from 'react-router-redux';
import thunk from 'redux-thunk';

import onRouterSetup from '@sketchpixy/rubix/lib/node/onRouterSetup';

import isBrowser from '@sketchpixy/rubix/lib/isBrowser';

if (isBrowser()) {
  onRouterSetup()
}

class WrapperComponent extends React.Component {
  render() {
    return this.props.children;
  }
}

var isRouterSet = false, history, reducer, store, routes;

export function setupReducers(reducers) {
  reducer = reducers
}

export default function render(Component, onRender) {
  if (!onRender) onRender = function () { }


  let initializeApp = (initialState = undefined) => {

    isRouterSet = true

    history = (Modernizr.history
      ? browserHistory
      : hashHistory)


    const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
    const store = createStoreWithMiddleware(reducer, initialState)
    const logState = false
    history = syncHistoryWithStore(history, store)

    if (process.env.NODE_ENV === 'development') {
      console.log("Initial state: ", JSON.stringify(initialState, null, 2))
    }

    routes = (
      <Provider store={store} key='provider'>
        <Router history={history} >
          {Component}
        </Router>
      </Provider>
    )
    ReactDOM.render(<div id='app-container-wrapper'><WrapperComponent>{routes}</WrapperComponent></div>,
      document.getElementById('app-container'),
      onRender)
  }


  if (!isRouterSet) {

    getPersistedSession().then(initialState => {
      return initializeApp(initialState)
    })
    .catch(err => {
      console.warn("Exception reloading session: ", err)
      initializeApp()
    })

  } else {
    ReactDOM.render(<div id='app-container-wrapper'><WrapperComponent>{routes}</WrapperComponent></div>,
      document.getElementById('app-container'),
      onRender)
  }
}

function getPersistedSession() {
  const authManager = new AuthManager()
  return authManager.refreshSession().then(authState => {
    console.log("Refreshed session")
    return {
      auth: authState
    }
  })
}