import * as React from 'react';
import { Invite } from '../../../db/Invites';
import ApiGatewayClient from '../../../api/APIGatewayClient';

type State = {
  userCount: string
  inviteText: string
}

type capRole = {
  capitalize: () => string
} & string
export default class InviteRow extends React.Component<Invite, State> {

  apiClient: any
  createdBy: any
  tenantId: any

  constructor(props) {
    super(props)
    this.state = {
      userCount: "",
      inviteText: 'Resend Invite'
    }
    this.apiClient = new ApiGatewayClient()
    this.sendInvite = this.sendInvite.bind(this)

  }

  sendInvite () {
    const invite = {
      code: this.props.code,
      link: this.props.link,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      phoneNumber: this.props.phoneNumber || null,
      createdBy: this.props.createdBy,
      specialistId: this.props.specialistId,
      tenantId: this.props.tenantId,
      roles: Object.keys(this.props.roles),
      counter: this.props.counter,
      organizations: this.props.organizations,
      override: true
    };
    this.setState({inviteText: 'Sending...'})
    return this.apiClient.sendInvite(invite).then(() => {
      this.setState({inviteText: 'Succesfully Sent'})
    }).catch((e) => {
      console.error(e)
      this.setState({inviteText: 'Could not send the invite'})
    })
  }

  render() {

    const { firstName, lastName, phoneNumber, link, roles, acceptedDate, createdDate, counter } = this.props

    const rolesList = Object.keys(roles)
      .filter(r => roles[r])
      .map((r:capRole) => r.capitalize())
      .join(", ")
    
    return (
      <tr>
        <td>
          {firstName + ' ' + lastName}
        </td>
        <td>
          {phoneNumber}
        </td>
        <td>
          {rolesList}
        </td>
        <td>
          {createdDate ? new Date(createdDate * 1000).toDateString() : "-"}
        </td>
        <td>
          {acceptedDate ? new Date(acceptedDate * 1000).toDateString() : "-"}
        </td>
        <td>
          {counter || '-'}
        </td>
        <td>
          {!acceptedDate && link}
        </td>
        <td>
          {!acceptedDate && <a onClick={this.sendInvite} style={{textDecoration: 'underline'}}>{this.state.inviteText}</a>}
        </td>
      </tr>
    );
  }
}
