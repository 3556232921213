import { CHANGE_DATE_RANGE } from '../actions/actionTypes';

export default function(state = {}, action) {
  switch (action.type) {
    case CHANGE_DATE_RANGE:
      const { type, ...rest } = action
      const newState = Object.assign({}, state, {
        query: action.query
      });
      return newState
    default:
      return state;
  }
}
