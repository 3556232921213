import * as React from 'react';
import { connect } from 'react-redux';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { createNote } from '~/redux/actions/telehealth'

const renderTextArea = ({ input, label, type, meta: { touched, error }}) => (
  <FormGroup controlId={input.name}>
    <ControlLabel>{label}</ControlLabel>
    <FormControl rows="3" {...input} name={input.name} componentClass={type} />
  </FormGroup>
);

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitNote: (note, clientId) => {
      dispatch(createNote(note, clientId))
      dispatch(reset('NoteForm')) //clears form
    }
  }
};

@connect(mapStateToProps, mapDispatchToProps)

class NoteForm extends React.Component {

  static propTypes = {
    clientId: React.PropTypes.string
  };

  componentDidMount(){
    window.addEventListener("beforeunload", (e) => {
      if (this.props.hasNoteValue !== undefined){
        this.formSubmit({note: this.props.hasNoteValue}, this.props.clientId)
      }
    });
  }

  formSubmit(submission) {
    const clientId = this.props.clientId;
    return this.props.submitNote(submission, clientId)
  }

  render() {
    const { noteValue, handleSubmit, pristine, reset, submitting } = this.props
    return (
      <div>
        <div className='dashboard-panel-header client-panel-header'>Notes</div>
        <form style={{width: "100%", height: "100%"}} onSubmit={handleSubmit(this.formSubmit.bind(this))}>
          <Field ref='note' name="note" type="textarea" style={{height: '300px'}} component={renderTextArea} />
          <button className="rounded-form-button" type="submit">Log Note</button>
        </form>
      </div>
    );
  }
}

//redux-form feature that allows you to grab the input value
const selector = formValueSelector('NoteForm')
NoteForm = connect(
  state => {
    const hasNoteValue = selector(state, 'note')
    return {
      hasNoteValue
    }
  }
)(NoteForm)

export default reduxForm({
  form: 'NoteForm'
})(NoteForm)
