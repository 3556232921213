import * as React from 'react';
import { ControlLabel, FormControl } from 'react-bootstrap'

export default class FormField extends React.Component {
  
  static propTypes = {
    label: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.string,
    fieldValueChanged: React.PropTypes.func.isRequired,
    readOnly: React.PropTypes.bool
  };
  
  onFieldUpdate(fieldName, event) {
    const input = event.target.value
    this.props.fieldValueChanged(fieldName, input)
  }
  
  render() {
    
    const { label, value, name } = this.props
    
    return (  
      <div>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          type="text"
          value={value}
          onChange={this.onFieldUpdate.bind(this, name)}
          readOnly={this.props.readOnly}
        />
      </div>
    )
  }
}
