
var debugLog: (...args) => void
var debugError: (...args) => void

if (process.env.NODE_ENV === 'production') {
  debugLog = (...args) => {
    return
  }
  debugError = (...args) => {
    return
  }
} else {
  debugLog = (...args) => {
    console.log(...args)
  }
  debugError = (...args) => {
    console.error(...args)
  }
}

export const log = debugLog
export const error = debugError