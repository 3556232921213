import * as React from 'react';
import { Modal } from 'react-bootstrap'

export default class DeleteOrgModal extends React.Component {

  static propTypes = {
    orgName: React.PropTypes.string.isRequired,
    userCount: React.PropTypes.number.isRequired,
    deleteOrg: React.PropTypes.func.isRequired,
    dismiss: React.PropTypes.func.isRequired
  };

  render() {
    const userCount = this.props.userCount
    const usersWord = userCount == 1 ? 'user' : 'users'
    const name = this.props.orgName;
    return (
      <Modal show={true} onHide={this.dismiss}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {name} has {userCount} {usersWord}. Are you sure you want to delete this organization?
        </Modal.Body>
        <Modal.Footer>
          <button className="sh-btn danger" onClick={this.props.deleteOrg}>Delete {name}</button>
          <button className="sh-btn info" onClick={this.props.dismiss}>Close</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
