import * as React from 'react'
import { Table, Pagination } from '@karla/karla-react-components'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { Subscription } from 'rxjs'
import _ from 'lodash'

import OrgUsersListHeader from './OrgUsersListHeader'
import OrgUserRow from './OrgUserRow'
import {
  observeTenantOrgUsers,
  getUserTenantOrgs,
  OrgMemberships
} from '../../db/Orgs'
import AnalyticsRepository from '../../db/AnalyticsRepository'
import { User } from '../../db/Users'

import { Roles, getTenantRoles } from '../../db/Roles'
import {
  LOADING_BEGIN,
  LOADING_END
} from '../../redux/actions/actionTypes/spinnerActions'

type Props = {
  tenantId: string
  orgId: string
  beginLoading: any
  endLoading: any
}

type UsersOrgs = {
  [userId: string]: OrgMemberships
}
type UsersRoles = {
  [userId: string]: Roles
}

type State = {
  allUsers: User[]
  users: User[]
  selectedKeys: { [key: string]: boolean }
  recentActivity: { [userId: string]: string }
  usersOrgs: UsersOrgs
  usersRoles: UsersRoles
  items: number
  page: number
  pageSize: number
}

const mapStateToProperties = (state) => {
  return {
    tenantId: state.auth.selectedRole.tenantId
  }
}

const mapDispatchToProperties = (dispatch) => {
  return {
    beginLoading: () => dispatch({ type: LOADING_BEGIN }),
    endLoading: () => dispatch({ type: LOADING_END })
  }
}

class OrgUsersListDumb extends React.Component<Props, State> {
  analytics: AnalyticsRepository

  subscription: Subscription | null

  constructor(properties) {
    super(properties)
    this.state = {
      allUsers: [],
      users: [],
      recentActivity: {},
      usersOrgs: {},
      usersRoles: {},
      selectedKeys: {},
      page: 1,
      pageSize: 10,
      items: 0
    }
    this.analytics = new AnalyticsRepository()
    this.subscription = null
    this.getUserOrgs = this.getUserOrgs.bind(this)
    this.loadUserOrgs = this.loadUserOrgs.bind(this)
  }

  componentDidMount() {
    this.reload()
  }

  componentDidUpdate(previousProperties, previousState) {
    if (
      this.props.orgId !== previousProperties.orgId ||
      this.props.tenantId !== previousProperties.tenantId
    ) {
      this.reload()
    } else if (previousState.page !== this.state.page) {
      this.props.beginLoading()
      this.getUserOrgsCb(this.state.allUsers)
    }
  }

  reload() {
    this.unsubscribe()
    if (this.props.orgId) {
      this.queryUsersForOrg(this.props.orgId)
    } else {
      this.setState({
        users: []
      })
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  getUserOrgsCb = async (us: User[] = this.state.allUsers) => {
    const { page, pageSize } = this.state
    const users = us
      .sort((user1, user2) =>
        user1.firstName.toLowerCase() > user2.firstName.toLowerCase() ? 1 : -1
      )
      .slice((page - 1) * pageSize, page * pageSize)
    const { rolesUnmapped, usersOrgs } = await this.loadUserData(users)

    const usersRoles: UsersRoles = rolesUnmapped.reduce<UsersRoles>(
      (usersRoles, tenantRole): UsersRoles => {
        const { userId, tenantId, tenantName, ...roles } = tenantRole
        usersRoles[tenantRole.userId] = roles
        return usersRoles
      },
      {}
    )
    this.setState({
      allUsers: us,
      users,
      usersOrgs,
      usersRoles,
      items: us.length
    })
    this.fetchRecentActivity(users)
    this.props.endLoading()
  }

  private loadUserData = _.memoize(
    async (users: User[]) => {
      const usersOrgs = await this.loadUserOrgs(users)
      const rolesUnmapped = await getTenantRoles(this.props.tenantId)
      return { rolesUnmapped, usersOrgs }
    },
    () => [this.props.tenantId, this.props.orgId, this.state.page].join(',')
  )

  queryUsersForOrg(orgId) {
    this.props.beginLoading()
    this.subscription = observeTenantOrgUsers(
      this.props.tenantId,
      orgId
    ).subscribe(this.getUserOrgsCb, (error) => {
      console.error('Get users error', error)
      this.setState({
        users: []
      })
      this.props.endLoading()
    })
  }

  async getUserOrgs(user: User) {
    try {
      const userOrgs = await getUserTenantOrgs(user.id, this.props.tenantId)
      return userOrgs
    } catch (error) {
      console.error('Error getting userOrgs', error)
      return {}
    }
  }

  async loadUserOrgs(users: User[]) {
    const UnzippedUsersOrgs: any = await Promise.all(
      users.map(async (user) => {
        const userOrgs = await this.getUserOrgs(user)
        return [user.id, userOrgs]
      })
    )
    const usersOrgs: UsersOrgs = UnzippedUsersOrgs.reduce(
      (usersOrgs, zippedUser) => {
        // eslint-disable-next-line prefer-destructuring
        usersOrgs[zippedUser[0]] = zippedUser[1]
        return usersOrgs
      },
      {}
    )

    return usersOrgs
  }

  fetchRecentActivity(users) {
    const recentActivities = {}
    const now = new Date().getTime() / 1000
    const getActiveDates = users.map((user) => {
      return this.analytics
        .getLastActiveDateForUser(user.id)
        .then((activeDate) => {
          const description = this.activityDescriptionFromDate(activeDate, now)
          recentActivities[user.id] = description
        })
    })
    return Promise.all(getActiveDates).then(() => {
      this.setState({
        recentActivity: recentActivities
      })
    })
  }

  activityDescriptionFromDate(lastActiveDate, now) {
    if (lastActiveDate === undefined) {
      return 'Never'
    }
    const timeSinceActive = now - lastActiveDate
    const thirtyDays = 60 * 60 * 24 * 30
    return timeSinceActive > thirtyDays ? 'No' : 'Yes'
  }

  rowSelected(key) {
    const { selectedKeys } = this.state
    const newValue = !selectedKeys[key]

    selectedKeys[key] = newValue

    this.setState({
      selectedKeys
    })
  }

  rowClicked(key) {
    browserHistory.push(`users/${key}`)
  }

  renderRow(user: User) {
    const userId = user.id || user['urn:consumer:id'] || 'not-migrated'
    const key = userId
    const name =
      user.firstName && user.lastName
        ? `${user.firstName} ${user.lastName}`
        : user.fullName || 'Unknown'
    const isSelected = this.state.selectedKeys[key] === true
    const userOrgs = this.state.usersOrgs[user.id]
    const orgsList = Object.keys(userOrgs).filter((orgKey) => userOrgs[orgKey])
    const roles = this.state.usersRoles[user.id]

    const roleString = Object.keys(roles)
      .filter((rolesName) => roles[rolesName])
      .join(', ')
    const recentActivity = this.state.recentActivity[user.id]

    return (
      <OrgUserRow
        key={key}
        name={name}
        uid={userId}
        role={roleString}
        orgs={orgsList}
        recentActivity={recentActivity}
        isSelected={isSelected}
        handleSelection={() => this.rowSelected(key)}
        handleClick={() => this.rowClicked(key)}
      />
    )
  }

  setPage = (page: number) => {
    this.setState({
      page
    })
  }

  increasePage = () => {
    this.setPage(this.state.page + 1)
  }

  decreasePage = () => {
    this.setPage(this.state.page - 1)
  }

  render() {
    const { users = [], items, page, pageSize } = this.state
    const allUsers = users || []
    const pageNumber = Math.ceil(items / pageSize)

    return (
      <div>
        <Table
          striped
          responsive
          className="sh-list-view bordered-box table-middle-align"
        >
          <OrgUsersListHeader />
          <tbody>
            {allUsers.map((user) => {
              return this.renderRow(user)
            })}
          </tbody>
        </Table>
        {pageNumber > 1 && (
          <Pagination
            className="pagination-container"
            prev={page > 1}
            next={page < pageNumber}
            first={page > 1}
            last={page < pageNumber}
            items={pageNumber}
            activePage={page}
            maxButtons={10}
            onSelect={this.setPage}
          />
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProperties,
  mapDispatchToProperties
)(OrgUsersListDumb)
