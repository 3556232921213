import * as React from 'react';
import { Modal } from 'react-bootstrap'
import { Row, Col, DropdownButton, MenuItem } from '@karla/karla-react-components';
import { TenantOrganization } from '../../db/Orgs';

type Props = {
  orgOptions: TenantOrganization[]
  orgSelected: (orgId: string) => void
  dismiss: () => void
}

type State = {
  selectedOrg: TenantOrganization
}

export default class AddUserOrgModal extends React.Component<Props, State> {

  confirmClicked: () => void
  orgSelected: (orgId: string) => void

  constructor(props: Props) {
    super(props)
    this.state = {
      selectedOrg: props.orgOptions[0]
    }
    this.confirmClicked = () => {
      const { selectedOrg } = this.state
      this.props.orgSelected(selectedOrg.id)
    }
    this.orgSelected = (orgId: string) => {
      const org = this.props.orgOptions.filter(org => org.id == orgId)[0]
      this.setState({
        selectedOrg: org
      })
    }
  }

  render() {
    const { orgOptions, dismiss } = this.props
    const { selectedOrg } = this.state
    return (
      <Modal 
        className="sh-modal"
        dialogClassName="modal-20w"
        show={true}
        backdrop={true}
        backdropClassName="modal-shadow-background"
        onHide={this.props.dismiss}>
        <img src="/imgs/icons/x-button.svg" className="close-btn hover-pointer" onClick={this.props.dismiss} style={{cursor: "pointer"}}/>
        <Modal.Body>
          <h1 className="title">Assign New Organization</h1>
          <Row className="org-form-row">
            <Col className="org-form-column org-form-column-centered">
              <DropdownButton id="org-dropdown" className="org-dropdown" title={selectedOrg.name} onSelect={this.orgSelected}>
                {orgOptions.map(org => {
                  return <MenuItem key={org.id} eventKey={org.id}>{org.name}</MenuItem>;
                })}
              </DropdownButton>
            </Col>
          </Row>
          <button className="sh-btn info" onClick={dismiss}>Cancel</button>
          <button className="sh-btn danger" onClick={this.confirmClicked}>Save</button>
        </Modal.Body>
      </Modal>
    )
  }
}
