import * as React from 'react';

import { Grid, Row, Col } from '@karla/karla-react-components';

import UserRepository from '~/db/UserRepository'
import SpecialistRepository from '~/db/SpecialistRepository'

import { URN } from '@karla/karla-core'

import { CreateSessionPanel } from './CreateSessionPanel'
import { UpcomingSessionsPanel } from './UpcomingSessionsPanel'
import { CancelSessionModal } from './CancelSessionModal'

export class SessionContainer extends React.Component {

    static propTypes = {
        specialistId: React.PropTypes.string.isRequired,
        clientId: React.PropTypes.string.isRequired,
        firstName: React.PropTypes.string,
        self: React.PropTypes.bool
    };

    constructor(props) {
        super(props)
        this.userRepo = new UserRepository()
        this.specialistRepo = new SpecialistRepository()
        this.state = {
            sessions: []
        }
    }

    componentDidMount() {
        this.getSessions(this.props.clientId)
    }

    componentWillReceiveProps(props) {
        this.getSessions(props.clientId)
    }

    createSession(startDate, endDate) {
        const specialistId = this.props.specialistId
        const clientId = this.props.clientId
        return this.specialistRepo.scheduleSessionForClient(specialistId, clientId, startDate, endDate)
    }

    getSessions(clientId) {
        this.specialistRepo.bindToClientSessions(clientId, {
            context: this,
            state: 'sessions',
            asArray: true,
            orderBy: 'startDate'
        })
    }

    didClickDeleteSession(session) {
        this.setState({
            sessionToDelete: session
        })
    }

    deleteSession(sessionId) {
        const clientId = this.props.clientId
        this.specialistRepo.deleteSessionForClient(clientId, sessionId)
        this.dismissCancelSessionModal()
    }

    renderCancelSessionModal() {
        if (!this.state.sessionToDelete) {
            return null
        }
        const session = this.state.sessionToDelete
        return (
            <CancelSessionModal clientName={this.props.firstName}
                startDate={session.startDate}
                endDate={session.endDate}
                confirm={this.deleteSession.bind(this, session.id)}
                dismiss={this.dismissCancelSessionModal.bind(this)} />
        )
    }

    dismissCancelSessionModal() {
        this.setState({
            sessionToDelete: null
        })
    }

    render() {
        return (
            <div>
                {this.renderCancelSessionModal()}
                <Row>
                    <UpcomingSessionsPanel sessions={this.state.sessions} deleteSession={this.didClickDeleteSession.bind(this)} />
                </Row>
                <Row>
                    <CreateSessionPanel createSession={this.createSession.bind(this)} />
                </Row>
            </div>
        )
    }

}
