import * as React from 'react';
import { Button } from '@karla/karla-react-components';



export default class extends React.Component {

  render() {
    return (
      <div className="text-center">
        <div id="come-back-soon">
          <div id="come-back-header">
            Thank you for creating a Synchronous Health account.
          </div>
          <div className="onboarding-text">
            If you are a new Synchronous Health Specialist please email admin@synchronoushealth.co to gain access to this portal. 
            <br/>
            <br/>
            This portal is not currently publically accessible for other registrants. 
            Please visit SynchronousHealth.co to learn about our consumer offerings. 
          </div>
          <a href="http://synchronoushealth.co" target="_blank">
            <Button id="sync-health-link" className='sh-btn'>Visit Synchronoushealth.co</Button>
          </a>
        </div>
      </div>
    );
  }
}
