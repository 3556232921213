import firebase from 'firebase/app'
import { getDoc } from './Firestore';
import UserRepository from './UserRepository'

const firestore = firebase.firestore()

export enum Role {
  demo = "demo",
  admin = "admin",
  specialist = "specialist",
  consumer = "consumer"
}

export type Roles = {[key in Role]: boolean}

export type TenantRole = {
  userId: string
  tenantId: string
  tenantName: string
} & Roles

export async function getUserRoles(userId: string, tenantId: string): Promise<Roles> {
  const rolesDoc = await getDoc(`rolesByUser/${userId}/roles/${tenantId}`)
  const data = (rolesDoc && rolesDoc.data || {}) as any
  return {
    admin: data.admin || false,
    specialist: data.specialist || false,
    consumer: data.consumer || false,
    demo: data.demo || false
  }
}

export async function getTenantRoles(tenantId: string): Promise<TenantRole[]> {
  const rolesCollection = await firestore.collection(`rolesByTenant/${tenantId}/roles/`).get()
  const roles: TenantRole[] = []
  rolesCollection.docs.forEach((doc) => {
    const data = doc.data()
    if (data) {
      roles.push(data as TenantRole)
    }
  })
  return roles
}

export async function updateUserRoles(roles: Roles, userId: string, tenantId: string, tenantName: string) {
  const tenantRole: TenantRole = {
    userId,
    tenantId,
    tenantName,
    ...roles
  }
  const setTenantRole = firestore.doc(`rolesByTenant/${tenantId}/roles/${userId}`)
    .set(tenantRole, {
      merge: true
    }) as Promise<void>
  const setUserRole = firestore.doc(`rolesByUser/${userId}/roles/${tenantId}`)
    .set(tenantRole, {
      merge: true
    }) as Promise<void>
  return Promise.all([
    setTenantRole, 
    setUserRole,
    updateUserRolesInFirebase(userId, roles)
  ])
}

async function updateUserRolesInFirebase(userId: string, roles: Roles) {
  const rolesRecord = {
    demo: !!roles.demo,
    admin: !!roles.admin,
    specialist: !!roles.specialist,
    consumer: !!roles.consumer
  }
  const repo = new UserRepository()
  return repo.updateUserRoles(userId, rolesRecord)
}