import * as React from 'react'
import moment from 'moment'
import SurveyResponseModal from './SurveyResponseModal'
import { getQuestions } from '../../../../db/DialogRepository'
import { capitalize } from '../../../../common/lib/string_helpers'

type Props = {
  response: {
    score: string
    scoreDetail: { [prop: string]: number | TagScore }
    answers: { [prop: string]: string }[]
  }
  assignment: {
    name: string
    dialog: string
    scoring: unknown
    viewedTimestamps: number
  }
}

type State = {
  questions: {
    [index: string]: string
  }
  answers: {
    [index: string]: string
  }
}

type TagScore = {
  label: string
  value: number
}

export default class RDXDialogResponseModal extends React.Component<
  Props,
  State
> {
  constructor(properties) {
    super(properties)
    this.state = {
      questions: {},
      answers: {}
    }
  }

  componentWillMount() {
    this.getInitialQuestions()
  }

  private async getInitialQuestions() {
    const { assignment } = this.props
    const questions = await getQuestions(assignment)
    const answers = this.getAnswers()
    this.setState({ questions, answers })
  }

  getAnswers() {
    const { response } = this.props
    const answers = {}
    if (!response) {
      return answers
    }
    response.answers.forEach((answer) => {
      answers[answer.question] = answer.answer
    })
    return answers
  }

  renderScores() {
    const { response } = this.props
    if (!response) {
      return ''
    }
    const bucketNames = response.scoreDetail
      ? Object.keys(response.scoreDetail)
      : []
    return (
      <div className="survey-section">
        <div className="survey-section-header">
          {bucketNames.length > 0 ? 'Score detail' : ''}
        </div>
        <div>
          {bucketNames.map((name) => {
            const score = response.scoreDetail[name]
            let scoreString: string
            if (typeof score === 'number') {
              scoreString = `${score}`
            } else {
              scoreString = `${capitalize(score.label)}. Score: ${score.value}`
            }
            return (
              <div key={name} id={name}>
                {name}: {`${scoreString}`}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderQuestionsAndAnswers() {
    const { response } = this.props
    const { questions } = this.state
    const { answers } = this.state
    let i: number = 0

    if (!this.state.questions) {
      return 'There was a problem trying to load the questions '
    }
    const questionsKeys = this.state.questions
      ? Object.keys(this.state.questions)
      : []

    return (
      <div className="survey-section">
        <div>
          {questionsKeys.map((index) => {
            i++
            return (
              <div key={i.toString()}>
                <div key={`question${i.toString()}`}>
                  {`#${i}. ${questions[index] ? questions[index] : '-'}`}
                </div>
                <div key={`answer${i.toString()}`}>
                  {`${
                    response && response.answers
                      ? answers[index]
                      : 'No answer yet'
                  }`}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderViewedList() {
    const { assignment } = this.props
    const views = assignment.viewedTimestamps
      ? Object.values(assignment.viewedTimestamps)
      : []
    const timesText = views.length === 1 ? 'time' : 'times'
    return (
      <SurveyResponseModal
        name={`Assigned Content: ${assignment.name}`}
        {...this.props}
      >
        <div className="survey-question">{`Viewed ${views.length} ${timesText}`}</div>
        {views.map((timestamp: number) => {
          const date = new Date(timestamp * 1000)
          return (
            <div key={timestamp} className="survey-answer">
              {moment(date).format('dddd, MMMM DD, YYYY - h:mm a')}
            </div>
          )
        })}
      </SurveyResponseModal>
    )
  }

  render() {
    const { response, assignment } = this.props

    if (!assignment.scoring) {
      return this.renderViewedList()
    }
    return (
      <SurveyResponseModal name={assignment.name} {...this.props}>
        <div>{`Score: ${response ? response.score : 'No score yet'}`}</div>
        {this.renderQuestionsAndAnswers()}
        {this.renderScores()}
      </SurveyResponseModal>
    )
  }
}
