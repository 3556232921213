import { LOGIN_SUCCESS,
         LOGIN_REQUEST_KEY,
         LOGIN_KEY_REQUEST_COMPLETE,
         LOGIN_CANCEL_KEY_REQUEST,
         LOGIN_ERROR,
         SMS_LOGIN_ATTEMPT,
         CHANGE_TENANT_ROLE,
         SWITCH_TENTANT,
         LOGOUT } from '../actions/actionTypes'

export default function(state = {}, action) {
  switch (action.type) {
    case SMS_LOGIN_ATTEMPT:
      return Object.assign({}, state, {
        phoneNumber: action.phoneNumber
      })
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        ...action.payload
      })
    case SWITCH_TENTANT:
      return Object.assign({}, state, {
        ...action.payload
      })
    case CHANGE_TENANT_ROLE:
      var roleState = Object.assign({}, state.selectedRole, {
        role: action.role
      })
      return Object.assign({}, state, {
        selectedRole: roleState
      })
    case LOGIN_REQUEST_KEY:
      return Object.assign({}, state, {
        keyRequest: action.payload.keyRequest
      })
    case LOGIN_CANCEL_KEY_REQUEST:
    case LOGIN_KEY_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        keyRequest: null
      })
    case LOGIN_ERROR:
      return Object.assign({}, state, {
        showOnboardingError: true
      })
    case LOGOUT:
      return {}
    default:
      return state
  }
}
