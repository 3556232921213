import * as React from 'react';
import UserEditForm from './UserEditForm'

export default class UserDetailContainer extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {

    const { userId } = this.props.params

    return (
      <div id='body'>
        <UserEditForm userId={userId} />
      </div>
    );
  }
}
