import * as React from 'react'

export default class OrgUserListHeader extends React.Component {
  render() {
    return (
      <thead>
        <tr>
          <th width="100">Name</th>
          <th width="125">UserID</th>
          <th width="75">Roles</th>
          <th width="150">Organizations</th>
          <th width="75">Recent Activity</th>
        </tr>
      </thead>
    )
  }
}
