import * as React from 'react'
import { ROLES } from '@karla/karla-core'
import { Checkbox } from '@karla/karla-react-components'

type UserRoles = {
  userRoles: { [role: string]: boolean }
}
type TenantOrgs = {
  tenantOrgs: {
    name: string
    id: string
    selected: boolean
  }[]
}
type Props = {
  onSelect: (role: string, selected: boolean) => void
}

export default function UserRolesForm(properties: Props & UserRoles) {
  const { userRoles, onSelect } = properties
  return (
    <div style={{ textAlign: 'left' }}>
      {ROLES.allDescending().map((role) => {
        const selected = userRoles[role] === true
        return (
          <Checkbox
            key={role}
            disabled={role === 'consumer'}
            onChange={() => onSelect(role, !selected)}
            checked={selected}
          >
            {role.capitalize()}
          </Checkbox>
        )
      })}
    </div>
  )
}

export function TenantOrgsForm(properties: Props & TenantOrgs) {
  const { tenantOrgs, onSelect } = properties
  return (
    <div style={{ textAlign: 'left' }}>
      {tenantOrgs.map((data) => {
        const isGlobal = data.id === 'global'
        const { name, id, selected = isGlobal } = data
        const checkedProperties = isGlobal
          ? { defaultChecked: true }
          : { checked: selected, onChange: () => onSelect(id, !selected) }
        return (
          <Checkbox key={id} disabled={isGlobal} {...checkedProperties}>
            {/* @ts-ignore */}
            {name.capitalize()}
          </Checkbox>
        )
      })}
    </div>
  )
}
