import * as React from 'react';
import { Link } from 'react-router'

export default class extends React.Component {
  
  render() {
    return (
      <div id='auth-background' data-reactroot=''>
        <div id='auth-container' className='col-md-5 col-centered text-center'>
          <img id='sh-auth-logo' src='imgs/sh_logo.svg' /><br />
          <div id='mission-statement'>
            Achieving the best of what it means to be<br /> human, thus creating a kinder world.
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}
