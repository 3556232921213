/* eslint-disable no-extend-native */
// @ts-nocheck
String.prototype.slugify = function slugify(text) {
  return this.toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

String.prototype.capitalize = function capitalize() {
  if (this.length === 0) {
    return ''
  }
  if (this.length === 1) {
    return this.toUpperCase()
  }
  return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.replaceCharAtIndex = function replaceCharAtIndex(
  replacement,
  index
) {
  if (this.length === 0) {
    return ''
  }
  if (index >= this.length) {
    throw new Error(`index ${index} is out of bounds for ${this}`)
  }
  return `${this.slice(0, Math.max(0, index))}${replacement}${this.slice(
    index + replacement.length
  )}`
}

export function incrementChar(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}
