import * as React from 'react';
import { Modal } from 'react-bootstrap'
import moment from 'moment'

export class CancelSessionModal extends React.Component {

  static propTypes = {
    clientName: React.PropTypes.string.isRequired,
    startDate: React.PropTypes.number.isRequired,
    endDate: React.PropTypes.number.isRequired,
    confirm: React.PropTypes.func.isRequired,
    dismiss: React.PropTypes.func.isRequired
  };

  render() {
    const { clientName, startDate, endDate, confirm, dismiss } = this.props
    const startDateStr = moment.unix(startDate).format('dddd, MMMM DD')
    const startTimeStr = moment.unix(startDate).format('h:mm a')
    const endTimeStr = moment.unix(endDate).format('h:mm a')
    return (
      <Modal show={true} onHide={this.dismiss}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel your session with {clientName} on {startDateStr} from {startTimeStr} to {endTimeStr}?
        </Modal.Body>
        <Modal.Footer>
          <button className="sh-btn confirm" onClick={this.props.confirm}>Cancel Session</button>
          <button className="sh-btn info" onClick={this.props.dismiss}>Close</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
