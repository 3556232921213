import * as React from 'react';
import { Modal } from 'react-bootstrap'
import CloseButton from '~/common/ui/CloseButton'

export default class SurveyResponseModal extends React.Component {
  
  static propTypes = {
    name: React.PropTypes.string.isRequired,
    onHide: React.PropTypes.func.isRequired
  };
  
  render() {
    return (
      <Modal className="survey-modal"
             show={true} 
             backdrop={true} 
             backdropClassName="modal-shadow-background"
             onHide={this.props.onHide}>
        <CloseButton onClick={this.props.onHide} />
        <Modal.Body className="survey-modal">
          <div id="survey-title">{this.props.name}</div>
          {this.props.children}
        </Modal.Body>
      </Modal>
    )
  }
}
