import { CREATE_NOTE, FETCH_NOTES } from './actionTypes/telehealthActions'
import SpecialistRepository from '~/db/SpecialistRepository'

export function createNote(note, clientId) {
  return (dispatch, getState) => {
    const specialistRepo = new SpecialistRepository()
    const userId = getState().auth.uid
    console.log('note', note);
    const data = specialistRepo.logNote(note, userId, clientId)

    dispatch({
      type: CREATE_NOTE,
      payload: data
    });
  }
}

export function fetchNotes(clientId) {
  return (dispatch, getState) => {
    const specialistRepo = new SpecialistRepository()
    const userId = getState().auth.uid
    const noteRef = specialistRepo.fetchNotes(userId, clientId)
    noteRef.on('value', function(snapshot) {
      dispatch({
        type: FETCH_NOTES,
        payload: snapshot.val()
      });
    });

  }
}
