import Datastore from './Datastore'
import { flattenObjectsByIdToArray } from '~/common/lib/object_helpers'

const logsPath = 'logs'

export default class LogRepository extends Datastore {

  getLogs(clientId, context) {
    const ref = this.getLogsRef(clientId).orderByKey().limitToLast(25)

    return this.bindToChildAddedAtRef(ref, (newMessageSnapshot) => {
      const newMessage = newMessageSnapshot.val()
      const list = context.state.items || []
      list.push(newMessage)
      const newState = {
        items: list
      }
      context.setState(newState)
    })
  }

  getLogsRef(clientId) {
    return this.getRef(logsPath).child(clientId)
  }

}
