function isObject(value) {
  if (value === null) {
    return false
  }
  return typeof value === 'object'
}

export function objectWithValueForKeys(value, keys) {
  return keys.reduce((object, key) => {
    object[key] = value
    return object
  }, {})
}

export function objectValues(object) {
  return Object.values(object)
}

export function copyObjectProperties(object, keys) {
  return keys.reduce((previous, key) => {
    if (object[key] !== undefined) {
      previous[key] = object[key]
    }
    return previous
  }, {})
}

export function sortByKey(array, key) {
  // eslint-disable-next-line @getify/proper-arrows/params
  return array.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    if (x < y) {
      return -1
    }
    if (x > y) {
      return 1
    }
    return 0
  })
}

export function objectFromArray(array, keyPath) {
  return array.reduce((previous, item) => {
    if (!item) {
      return previous
    }
    const key = item[keyPath]
    previous[key] = item
    return previous
  }, {})
}

export function flattenObjectsByIdToArray(object) {
  if (!object) {
    return []
  }
  return Object.keys(object).reduce((previous: any, key) => {
    if (!isObject(object[key])) {
      return previous
    }
    const childObject = object[key]
    childObject.id = key
    previous.push(childObject)
    return previous
  }, [])
}

// eslint-disable-next-line func-names
Object.values = function (object) {
  const values: any[] = []
  /* eslint-disable no-restricted-syntax */
  for (const name in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(name)) {
      const value = object[name]
      values.push(value)
    }
  }
  /* eslint-enable no-restricted-syntax */
  return values
}

export function uniqBy(array, predicate) {
  const callback =
    typeof predicate === 'function'
      ? predicate
      : (auxiliar) => auxiliar[predicate]
  const result = array.reduce((map, item) => {
    const key = item === null || item === undefined ? item : callback(item)

    if (!map.has(key)) {
      map.set(key, item)
    }

    return map
  }, new Map())

  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(result.values())
}

export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  /* eslint-disable no-restricted-syntax */
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  /* eslint-enable no-restricted-syntax */

  return true
}
