import * as React from 'react'
import { Grid, Row } from '@karla/karla-react-components'
import { connect } from 'react-redux'
import { Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { getTenantInvites, Invite } from '../../../db/Invites'
import InviteList from './InviteList'

type Props = {
  tenantId: string
}

type State = {
  invites: Invite[]
}

const mapStateToProperties = (state) => {
  return {
    tenantId: state.auth.selectedRole.tenantId
  }
}

const mapDispatchToProperties = () => {
  return {}
}

export class InviteContainerDumb extends React.Component<Props, State> {
  subscription: Subscription | null

  constructor(properties) {
    super(properties)
    this.state = {
      invites: []
    }
  }

  componentDidMount() {
    this.subscription = getTenantInvites(this.props.tenantId)
      .pipe(map(sortInvites))
      .subscribe((invites: Invite[]) => {
        this.setState({
          invites
        })
      })
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  render() {
    return (
      <div id="body">
        <Grid>
          <Row>
            <div className="relative">
              <h1 className="float-left">Invites</h1>
            </div>
          </Row>
          <Row>
            <InviteList
              tenantId={this.props.tenantId}
              invites={this.state.invites}
            />
          </Row>
        </Grid>
      </div>
    )
  }
}

function sortInvites(invites: Invite[]): Invite[] {
  const accepted: Invite[] = []
  const notAccepted: Invite[] = []
  invites.forEach((invite) => {
    if (invite.acceptedDate) {
      accepted.push(invite)
    } else {
      notAccepted.push(invite)
    }
  })
  sortByName(accepted)
  sortByName(notAccepted)
  return notAccepted.concat(accepted)
}

export function sortByName(invites: Invite[]) {
  invites.sort((inviteA, inviteB) => {
    if (inviteA.firstName < inviteB.firstName) {
      return -1
    }
    if (inviteA.firstName > inviteB.firstName) {
      return 1
    }
    // If firstName is the same then sort by lastName
    if (inviteA.lastName < inviteB.lastName) {
      return -1
    }
    if (inviteA.lastName > inviteB.lastName) {
      return 1
    }
    return 0
  })
}

export default connect(
  mapStateToProperties,
  mapDispatchToProperties
)(InviteContainerDumb)
